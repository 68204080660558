import {
  ACTIVE_CAMPAIGNS_ERROR,
  ACTIVE_CAMPAIGNS_REQUEST,
  ACTIVE_CAMPAIGNS_RESET,
  ACTIVE_CAMPAIGNS_SUCCESS,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_RESET,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS,
  CHANGE_CHAMPAIGN_STATUS_ERROR,
  CHANGE_CHAMPAIGN_STATUS_REQUEST,
  CHANGE_CHAMPAIGN_STATUS_RESET,
  CHANGE_CHAMPAIGN_STATUS_SUCCESS,
  COMPLETED_CAMPAIGNS_ERROR,
  COMPLETED_CAMPAIGNS_REQUEST,
  COMPLETED_CAMPAIGNS_RESET,
  COMPLETED_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGNS_ERROR,
  CREATE_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGNS_RESET,
  CREATE_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_RESET,
  CREATE_CAMPAIGN_SUCCESS,
  GET_ALL_CAMPAIGN_BY_ADMIN_ERROR,
  GET_ALL_CAMPAIGN_BY_ADMIN_REQUEST,
  GET_ALL_CAMPAIGN_BY_ADMIN_RESET,
  GET_ALL_CAMPAIGN_BY_ADMIN_SUCCESS,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_RESET,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS,
  GET_ALL_CAMPAIGN_ERROR,
  GET_ALL_CAMPAIGN_REQUEST,
  GET_ALL_CAMPAIGN_RESET,
  GET_ALL_CAMPAIGN_SUCCESS,
  GET_ALL_PLEA_ERROR,
  GET_ALL_PLEA_REQUEST,
  GET_ALL_PLEA_RESET,
  GET_ALL_PLEA_SUCCESS,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_RESET,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS,
  GET_CAMPAIGNS_BY_SCREEN_ID_ERROR,
  GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST,
  GET_CAMPAIGNS_BY_SCREEN_ID_RESET,
  GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS,
  GET_CAMPAIGNS_TABLE_DATA_ERROR,
  GET_CAMPAIGNS_TABLE_DATA_REQUEST,
  GET_CAMPAIGNS_TABLE_DATA_RESET,
  GET_CAMPAIGNS_TABLE_DATA_SUCCESS,
  PAUSE_CAMPAIGNS_ERROR,
  PAUSE_CAMPAIGNS_REQUEST,
  PAUSE_CAMPAIGNS_RESET,
  PAUSE_CAMPAIGNS_SUCCESS,
  PENDING_CAMPAIGNS_ERROR,
  PENDING_CAMPAIGNS_REQUEST,
  PENDING_CAMPAIGNS_RESET,
  PENDING_CAMPAIGNS_SUCCESS,
  SEND_PLEA_ERROR,
  SEND_PLEA_REQUEST,
  SEND_PLEA_RESET,
  SEND_PLEA_RESPONSE_ERROR,
  SEND_PLEA_RESPONSE_REQUEST,
  SEND_PLEA_RESPONSE_RESET,
  SEND_PLEA_RESPONSE_SUCCESS,
  SEND_PLEA_SUCCESS,
  SET_CAMPAIGNS_PRIORITY_ERROR,
  SET_CAMPAIGNS_PRIORITY_REQUEST,
  SET_CAMPAIGNS_PRIORITY_RESET,
  SET_CAMPAIGNS_PRIORITY_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_RESET,
  UPDATE_CAMPAIGN_SUCCESS,
} from "../constants/campaignConstants";

export function campaignCreateReducer(state = [], action) {
  switch (action.type) {
    case CREATE_CAMPAIGNS_REQUEST:
      return { loading: true };
    case CREATE_CAMPAIGNS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case CREATE_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload, success: false };
    case CREATE_CAMPAIGNS_RESET:
      return {};
    default:
      return state;
  }
}

export function campaignsCreateByScreenOwnerReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_CAMPAIGN_REQUEST:
      return { loading: true };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        loading: false,
        uploadedCampaign: action.payload,
        success: true,
      };
    case CREATE_CAMPAIGN_ERROR:
      return { loading: false, error: action.payload };
    case CREATE_CAMPAIGN_RESET:
      return {};
    default:
      return state;
  }
}

export function campaignsByScreenIdReducer(state = {}, action) {
  switch (action.type) {
    case GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST:
      return { loading: true };
    case GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case GET_CAMPAIGNS_BY_SCREEN_ID_ERROR:
      return { loading: false, error: action.payload };
    case GET_CAMPAIGNS_BY_SCREEN_ID_RESET:
      return {};
    default:
      return state;
  }
}

export function allCampaignsByScreenIdReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST:
      return { loading: true };
    case GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR:
      return { loading: false, error: action.payload };
    case GET_ALL_CAMPAIGN_BY_SCREEN_ID_RESET:
      return {};
    default:
      return state;
  }
}
// getAllCampaignsByAdmin
export function getAllCampaignsByAdminReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_CAMPAIGN_BY_ADMIN_REQUEST:
      return { loading: true };
    case GET_ALL_CAMPAIGN_BY_ADMIN_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case GET_ALL_CAMPAIGN_BY_ADMIN_ERROR:
      return { loading: false, error: action.payload };
    case GET_ALL_CAMPAIGN_BY_ADMIN_RESET:
      return {};
    default:
      return state;
  }
}
export function allCampaignsReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_CAMPAIGN_REQUEST:
      return { loading: true };
    case GET_ALL_CAMPAIGN_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case GET_ALL_CAMPAIGN_ERROR:
      return { loading: false, error: action.payload };
    case GET_ALL_CAMPAIGN_RESET:
      return {};
    default:
      return state;
  }
}

export function campaignListByCampaignIdsReducer(state = {}, action) {
  switch (action.type) {
    case GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST:
      return { loading: true };
    case GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR:
      return { loading: false, error: action.payload };
    case GET_CAMPAIGNS_BY_CAMPAIGN_IDS_RESET:
      return {};
    default:
      return state;
  }
}

export function changeCampaignsStatusReducer(state = {}, action) {
  switch (action.type) {
    case CHANGE_CHAMPAIGN_STATUS_REQUEST:
      return { loading: true };
    case CHANGE_CHAMPAIGN_STATUS_SUCCESS:
      return { loading: false, success: true };
    case CHANGE_CHAMPAIGN_STATUS_ERROR:
      return { loading: false, error: action.payload };
    case CHANGE_CHAMPAIGN_STATUS_RESET:
      return {};
    default:
      return state;
  }
}

export function getAllPleasRequestReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_PLEA_REQUEST:
      return { loading: true };
    case GET_ALL_PLEA_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_ALL_PLEA_ERROR:
      return { loading: false, error: action.payload };
    case GET_ALL_PLEA_RESET:
      return {};
    default:
      return state;
  }
}

export function sendPleaRequestReducer(state = {}, action) {
  switch (action.type) {
    case SEND_PLEA_REQUEST:
      return { loading: true };
    case SEND_PLEA_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case SEND_PLEA_ERROR:
      return { loading: false, error: action.payload };
    case SEND_PLEA_RESET:
      return {};
    default:
      return state;
  }
}
export function sendPleaResponseReducer(state = {}, action) {
  switch (action.type) {
    case SEND_PLEA_RESPONSE_REQUEST:
      return { loading: true };
    case SEND_PLEA_RESPONSE_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case SEND_PLEA_RESPONSE_ERROR:
      return { loading: false, error: action.payload };
    case SEND_PLEA_RESPONSE_RESET:
      return {};
    default:
      return state;
  }
}

export function campaignsPrioritySetReducer(state = {}, action) {
  switch (action.type) {
    case SET_CAMPAIGNS_PRIORITY_REQUEST:
      return { loading: true };
    case SET_CAMPAIGNS_PRIORITY_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case SET_CAMPAIGNS_PRIORITY_ERROR:
      return { loading: false, error: action.payload };
    case SET_CAMPAIGNS_PRIORITY_RESET:
      return {};
    default:
      return state;
  }
}

export function campaignUpdateReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_CAMPAIGN_REQUEST:
      return { loading: true };
    case UPDATE_CAMPAIGN_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case UPDATE_CAMPAIGN_ERROR:
      return { loading: false, error: action.payload };
    case UPDATE_CAMPAIGN_RESET:
      return {};
    default:
      return state;
  }
}

export function getCampaignLogReducer(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST:
      return { loading: true };
    case CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR:
      return { loading: false, error: action.payload };
    case CAMPAIGN_LOG_BY_CAMPAIGN_ID_RESET:
      return {};
    default:
      return state;
  }
}
//<getCampaignsTableDataByCampaignIds>

export function getCampaignsTableDataByCampaignIdsReducer(state = [], action) {
  switch (action.type) {
    case GET_CAMPAIGNS_TABLE_DATA_REQUEST:
      return { loading: true };
    case GET_CAMPAIGNS_TABLE_DATA_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_CAMPAIGNS_TABLE_DATA_ERROR:
      return { loading: false, error: action.payload };
    case GET_CAMPAIGNS_TABLE_DATA_RESET:
      return {};
    default:
      return state;
  }
}

export function getActiveCampaignReducer(state = {}, action) {
  switch (action.type) {
    case ACTIVE_CAMPAIGNS_REQUEST:
      return { loading: true };
    case ACTIVE_CAMPAIGNS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case ACTIVE_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload };
    case ACTIVE_CAMPAIGNS_RESET:
      return {};
    default:
      return state;
  }
}

export function getPauseCampaignReducer(state = {}, action) {
  switch (action.type) {
    case PAUSE_CAMPAIGNS_REQUEST:
      return { loading: true };
    case PAUSE_CAMPAIGNS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case PAUSE_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload };
    case PAUSE_CAMPAIGNS_RESET:
      return {};
    default:
      return state;
  }
}

export function getPendingCampaignReducer(state = {}, action) {
  switch (action.type) {
    case PENDING_CAMPAIGNS_REQUEST:
      return { loading: true };
    case PENDING_CAMPAIGNS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case PENDING_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload };
    case PENDING_CAMPAIGNS_RESET:
      return {};
    default:
      return state;
  }
}

export function getCompletedCampaignReducer(state = {}, action) {
  switch (action.type) {
    case COMPLETED_CAMPAIGNS_REQUEST:
      return { loading: true };
    case COMPLETED_CAMPAIGNS_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case COMPLETED_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload };
    case COMPLETED_CAMPAIGNS_RESET:
      return {};
    default:
      return state;
  }
}
