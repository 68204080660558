import axios from "axios";
import {
  ACTIVE_CAMPAIGNS_ERROR,
  ACTIVE_CAMPAIGNS_REQUEST,
  ACTIVE_CAMPAIGNS_SUCCESS,
  ADD_CAMPAIGN_DATA_TO_STORAGE_ERROR,
  ADD_CAMPAIGN_DATA_TO_STORAGE_REQUEST,
  ADD_CAMPAIGN_DATA_TO_STORAGE_SUCCESS,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST,
  CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS,
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_COMPLETED,
  CAMPAIGN_STATUS_PAUSE,
  CAMPAIGN_STATUS_PENDING,
  CHANGE_CHAMPAIGN_STATUS_ERROR,
  CHANGE_CHAMPAIGN_STATUS_REQUEST,
  CHANGE_CHAMPAIGN_STATUS_SUCCESS,
  COMPLETED_CAMPAIGNS_ERROR,
  COMPLETED_CAMPAIGNS_REQUEST,
  COMPLETED_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGNS_ERROR,
  CREATE_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  GET_ALL_CAMPAIGN_BY_ADMIN_ERROR,
  GET_ALL_CAMPAIGN_BY_ADMIN_REQUEST,
  GET_ALL_CAMPAIGN_BY_ADMIN_SUCCESS,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST,
  GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS,
  GET_ALL_CAMPAIGN_ERROR,
  GET_ALL_CAMPAIGN_REQUEST,
  GET_ALL_CAMPAIGN_SUCCESS,
  GET_ALL_PLEA_ERROR,
  GET_ALL_PLEA_REQUEST,
  GET_ALL_PLEA_SUCCESS,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST,
  GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS,
  GET_CAMPAIGNS_BY_SCREEN_ID_ERROR,
  GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST,
  GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS,
  GET_CAMPAIGNS_TABLE_DATA_ERROR,
  GET_CAMPAIGNS_TABLE_DATA_REQUEST,
  GET_CAMPAIGNS_TABLE_DATA_SUCCESS,
  PAUSE_CAMPAIGNS_ERROR,
  PAUSE_CAMPAIGNS_REQUEST,
  PAUSE_CAMPAIGNS_SUCCESS,
  PENDING_CAMPAIGNS_ERROR,
  PENDING_CAMPAIGNS_REQUEST,
  PENDING_CAMPAIGNS_SUCCESS,
  SEND_PLEA_ERROR,
  SEND_PLEA_REQUEST,
  SEND_PLEA_RESPONSE_ERROR,
  SEND_PLEA_RESPONSE_REQUEST,
  SEND_PLEA_RESPONSE_SUCCESS,
  SEND_PLEA_SUCCESS,
  SET_CAMPAIGNS_PRIORITY_ERROR,
  SET_CAMPAIGNS_PRIORITY_REQUEST,
  SET_CAMPAIGNS_PRIORITY_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
} from "../constants/campaignConstants";

const backendURL = `${process.env.REACT_APP_PROOH_SERVER}/api/v1/campaigns`;

export const getAllScreens = (data) => async (dispatch, getState) => {
  dispatch({
    type: ADD_CAMPAIGN_DATA_TO_STORAGE_REQUEST,
  });
  try {
    const { data } = await axios.get(`${backendURL}/all`);
    dispatch({
      type: ADD_CAMPAIGN_DATA_TO_STORAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_CAMPAIGN_DATA_TO_STORAGE_ERROR,
      payload: error,
    });
  }
};

// multiple campaign
export const createCampaigns = (requestBody) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_CAMPAIGNS_REQUEST,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();
    const { data } = await axios.post(`${backendURL}/create`, requestBody, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({
      type: CREATE_CAMPAIGNS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CAMPAIGNS_ERROR,
      payload: error,
    });
  }
};

// create campaign by screenOwner
export const campaignsCreateByScreenOwner =
  (requestBody) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_CAMPAIGN_REQUEST,
      payload: requestBody,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();
      // console.log("requestBody : ", JSON.stringify(requestBody));
      const { data } = await axios.post(
        `${backendURL}/createCampaignByScreenOwner`,
        requestBody,
        { headers: { Authorization: `Bearer ${userInfo.token}` } }
      );
      dispatch({
        type: CREATE_CAMPAIGN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: CREATE_CAMPAIGN_ERROR,
        payload: message,
      });
    }
  };

export const getCampaignDetails = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CAMPAIGN_DETAILS_REQUEST,
    payload: campaignId,
  });
};

export const getCampaignsByScreenId =
  (screenId, status) => async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/campaignsByScreenId?screenId=${screenId}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: GET_CAMPAIGNS_BY_SCREEN_ID_ERROR,
        payload: message,
      });
    }
  };

export const getActiveCampaignsByScreenId =
  (screenId, status = CAMPAIGN_STATUS_ACTIVE) =>
  async (dispatch, getState) => {
    dispatch({
      type: ACTIVE_CAMPAIGNS_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();
      if (!screenId) return;

      const { data } = await axios.get(
        `${backendURL}/campaignsByScreenId?screenId=${screenId}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: ACTIVE_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ACTIVE_CAMPAIGNS_ERROR,
        payload: message,
      });
    }
  };

export const getPauseCampaignsByScreenId =
  (screenId, status = CAMPAIGN_STATUS_PAUSE) =>
  async (dispatch, getState) => {
    dispatch({
      type: PAUSE_CAMPAIGNS_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/campaignsByScreenId?screenId=${screenId}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: PAUSE_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PAUSE_CAMPAIGNS_ERROR,
        payload: message,
      });
    }
  };

export const getPendingCampaignsByScreenId =
  (screenId, status = CAMPAIGN_STATUS_PENDING) =>
  async (dispatch, getState) => {
    dispatch({
      type: PENDING_CAMPAIGNS_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/campaignsByScreenId?screenId=${screenId}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: PENDING_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PENDING_CAMPAIGNS_ERROR,
        payload: message,
      });
    }
  };

export const getCompletedCampaignsByScreenId =
  (screenId, status = CAMPAIGN_STATUS_COMPLETED) =>
  async (dispatch, getState) => {
    dispatch({
      type: COMPLETED_CAMPAIGNS_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/campaignsByScreenId?screenId=${screenId}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: COMPLETED_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: COMPLETED_CAMPAIGNS_ERROR,
        payload: message,
      });
    }
  };

export const getAllCampaignsByScreenId =
  (screenId) => async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST,
      payload: screenId,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/allCampaignsByScreenId?screenId=${screenId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR,
        payload: message,
      });
    }
  };

export const getAllCampaigns = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_CAMPAIGN_REQUEST,
    payload: [],
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.get(`${backendURL}/allCampaigns`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({
      type: GET_ALL_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: GET_ALL_CAMPAIGN_ERROR,
      payload: message,
    });
  }
};

export const getAllCampaignsByAdmin = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_CAMPAIGN_REQUEST,
    payload: [],
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.get(`${backendURL}/all`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });

    dispatch({
      type: GET_ALL_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: GET_ALL_CAMPAIGN_ERROR,
      payload: message,
    });
  }
};

export const getCampaignListByCampaignIds =
  (campaignsIds) => async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST,
      payload: campaignsIds,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/campaignListByCampaignIds`,
        {
          params: { campaignsIds },
          paramsSerializer: {
            indexes: true, // use brackets with indexes
          },
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR,
        payload: message,
      });
    }
  };

export const getCampaignsTableDataByCampaignIds =
  (campaignIds) => async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGNS_TABLE_DATA_REQUEST,
      payload: campaignIds,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(`${backendURL}/getSlotInfo`, {
        params: { campaignIds },
        paramsSerializer: {
          indexes: true, // use brackets with indexes
        },
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({
        type: GET_CAMPAIGNS_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: GET_CAMPAIGNS_TABLE_DATA_ERROR,
        payload: message,
      });
    }
  };

export const changeCampaignStatus =
  (requestBody) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_CHAMPAIGN_STATUS_REQUEST,
      payload: requestBody,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.put(
        `${backendURL}/changeStatus`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: CHANGE_CHAMPAIGN_STATUS_SUCCESS,
        payload: data,
      });
      // console.log({ data });
    } catch (error) {
      dispatch({
        type: CHANGE_CHAMPAIGN_STATUS_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllPleaRequest =
  ({ email, userRole }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_PLEA_REQUEST,
      payload: { email, userRole },
    });
    try {
      const {
        auth: { userInfo },
      } = getState();

      const { data } = await axios.get(
        `${backendURL}/getPleaRequests?email=${email}&type=${userRole}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: GET_ALL_PLEA_SUCCESS,
        payload: data,
      });
      // console.log({ data });
    } catch (error) {
      dispatch({
        type: GET_ALL_PLEA_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const sendPleaRequest = (requestBody) => async (dispatch, getState) => {
  dispatch({
    type: SEND_PLEA_REQUEST,
    payload: requestBody,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.post(
      `${backendURL}/sendPleaRequest`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: SEND_PLEA_SUCCESS,
      payload: data,
    });
    // console.log({ data });
  } catch (error) {
    dispatch({
      type: SEND_PLEA_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const sendPleaResponse = (reqbody) => async (dispatch, getState) => {
  dispatch({
    type: SEND_PLEA_RESPONSE_REQUEST,
    payload: reqbody,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.post(
      `${backendURL}/pleaRequestResponse`,
      reqbody,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: SEND_PLEA_RESPONSE_SUCCESS,
      payload: data,
    });
    // console.log({ data });
  } catch (error) {
    dispatch({
      type: SEND_PLEA_RESPONSE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setCampaignPriority =
  ({ campaignWithAtIndex }) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_CAMPAIGNS_PRIORITY_REQUEST,
      payload: campaignWithAtIndex,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();
      var startTime = performance.now();

      const { data } = await axios.put(
        `${process.env.REACT_APP_PROOH_SERVER}/api/v1/campaigns/setCampaignsPriority`,
        campaignWithAtIndex,
        { headers: { Authorization: `Bearer ${userInfo.token}` } }
      );
      dispatch({
        type: SET_CAMPAIGNS_PRIORITY_SUCCESS,
        payload: data,
      });
      var endTime = performance.now();

      console.log(
        `Call to setCampaignPriority took ${endTime - startTime} milliseconds`
      );
      // console.log({ data });
    } catch (error) {
      dispatch({
        type: SET_CAMPAIGNS_PRIORITY_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateCampaign = (requestBody) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_CAMPAIGN_REQUEST,
    payload: requestBody,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.put(
      `${backendURL}/editMyCampaign`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: data,
    });
    // console.log({ data });
  } catch (error) {
    dispatch({
      type: UPDATE_CAMPAIGN_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCampaignLog = (campaignId) => async (dispatch, getState) => {
  dispatch({
    type: CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST,
    payload: campaignId,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.get(
      `${backendURL}/campaignLog?campaignId=${campaignId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({
      type: CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
