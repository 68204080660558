import React, { useCallback, useEffect, useState } from "react";
import { CampaignCreateTable, Loading, Message } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getFinalSummaryTableData,
  getPricingDetailsTableData,
  getScreenDataByAudiences,
  getScreensDateFilterByScreenAudiences,
} from "../../actions/screenDataAction";
import { FiveLevelFilter } from "./FiveLevelFilter";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_TYPE_REGULAR } from "../../constants/campaignConstants";
import { EnterCampaignDetails } from "./EnterCampaignDetails";
import {
  AUDIENCES_FILTER_OPTIONS,
  CAMPAIGN_DETAILS,
  FILTERED_SCREENS,
  PRICING_DATA,
} from "../../constants/localStorageConstants";
import { NewCohortPlanningTable } from "../../components/common/tables/NewCohortPlanningTable";
import { format } from "date-fns";
import {
  getEndDateFromStartDateANdDuration,
  getNumberOfDaysBetweenTwoDates,
} from "../../utils/dateAndTimeUtils";
import { message } from "antd";
import {
  getDataFromLocalStorage,
  saveDataOnLocalStorage,
} from "../../utils/localStorageUtils";
import { convertNumberToIndianRs } from "../../utils/valueValidate";

export const MiddleArea: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  // campaign details
  const [campaignName, setCampaignName] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [duration, setDuration] = useState<number>();
  const [campaignType, setCampaignType] = useState<string>(
    CAMPAIGN_TYPE_REGULAR
  );
  // using to shop UI
  const [showAudienceTable, setShowAudienceTable] = useState<any>(false);
  const [showFilterLevels, setShowFilterLevels] = useState<any>(false);
  const [showPricingTable, setShowPricingTable] = useState<any>(false);

  // filterFive = screen filter in FilterFive components and all screen = getting screen list after selection audience targeting
  const [filterFive, setFilterFive] = useState<any>([]);
  const [allScreens, setAllScreens] = useState<any>([]);
  // no idea about
  const [filteredResultByCity, setFilteredResultByCity] = useState<any>({});
  const [totalChosenScreens, setTotalChosenScreens] = useState<any>(0);

  const [allScreensPricing, setAllScreensPricing] = useState<any>([]);
  const [filteredResultByCityPricing, setFilteredResultByCityPricing] =
    useState<any>({});
  const [totalChosenScreensPricing, setTotalChosenScreensPricing] =
    useState<any>(0);

  const allScreenDataByAudiencesGet = useSelector(
    (state: any) => state.allScreenDataByAudiencesGet
  );
  const { loading, error, data: audienceData } = allScreenDataByAudiencesGet;

  // for this selector using getScreensDateFilterByScreenAudiences action
  const screensDateFilterByScreenAudiences = useSelector(
    (state: any) => state.screensDateFilterByScreenAudiences
  );
  const {
    loading: loadingFiltered,
    error: errorFiltered,
    data: filteredData,
  } = screensDateFilterByScreenAudiences;

  const pricingDetailsDataGet = useSelector(
    (state: any) => state.pricingDetailsDataGet
  );
  const {
    loading: loadingPricing,
    error: errorPricing,
    data: pricingData,
  } = pricingDetailsDataGet;

  const handleSetEndDate = () => {
    if (startDate && duration) {
      const endDate1 = getEndDateFromStartDateANdDuration(startDate, duration);
      setEndDate(format(endDate1, "yyyy-MM-dd"));
    }
  };

  const handleSetNewDuration = () => {
    if (startDate && endDate)
      setDuration(getNumberOfDaysBetweenTwoDates(startDate, endDate));
    else message.error("Please enter first start , end Date");
  };

  // useEffect(() => {
  //   handleSetEndDate();
  // }, [startDate, duration]);

  const saveCampaignDetailsOnLocalStorage = useCallback(() => {
    saveDataOnLocalStorage(CAMPAIGN_DETAILS, {
      campaignName,
      brandName,
      startDate: startDate ? new Date(startDate).toISOString() : startDate,
      endDate: endDate ? new Date(endDate).toISOString() : endDate,
      campaignType,
    });
  }, [brandName, campaignName, campaignType, endDate, startDate]);

  useEffect(() => {
    saveCampaignDetailsOnLocalStorage();
  }, [campaignName, brandName, startDate, endDate, campaignType]);

  const calculateTotalScreens = (data: any) => {
    let ts: any = 0;
    let tsr: any = [];
    let tso: any = {};
    for (const cnt in data) {
      for (const st in data[cnt]) {
        for (const ct in data[cnt][st]) {
          tso[ct] = [];
          for (const scr in data[cnt][st][ct]) {
            if (data[cnt][st][ct][scr]) {
              tso[ct].push(data[cnt][st][ct][scr]);
              tsr.push(data[cnt][st][ct][scr]);
              ts = ts + 1;
            }
          }
        }
      }
    }
    setTotalChosenScreens(ts);
    setAllScreens(tsr);
    setFilteredResultByCity(tso);
  };

  const calculateTotalScreensPricing = (data: any) => {
    let ts: any = 0;
    const tsr: any = [];
    const tso: any = {};
    for (const cnt in data) {
      for (const st in data[cnt]) {
        for (const ct in data[cnt][st]) {
          tso[ct] = [];
          for (const scr in data[cnt][st][ct]) {
            if (data[cnt][st][ct][scr]) {
              tso[ct].push(data[cnt][st][ct][scr]);
              tsr.push(data[cnt][st][ct][scr]);
              ts = ts + 1;
            }
          }
        }
      }
    }
    setTotalChosenScreensPricing(ts);
    setAllScreensPricing(tsr);
    setFilteredResultByCityPricing(tso);
  };

  const validateForm = () => {
    if (campaignName.length === 0) {
      alert("Please enter campaign name");
      return false;
    } else if (brandName.length === 0) {
      alert("Please enter brand name");
      return false;
    } else if (startDate === undefined || startDate === null) {
      alert("Please enter start data ");
      return false;
    } else if (endDate === undefined || endDate === null) {
      alert("Please enter endData ");
      return false;
    } else {
      return true;
    }
  };

  const finalSummaryTableData = () => {
    const tp: any = [];
    const filterOption =
      getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || [];
    filterOption?.map((f: any) => {
      for (const t of f.selectedTouchPoints) {
        tp.push({
          selectedCountry: f.selectedCountry,
          selectedState: f.selectedState,
          selectedCity: f.selectedCity,
          selectedTouchPoint: t,
        });
      }
    });

    const filteredScreenList = filterFive?.length > 0 ? filterFive : allScreens;
    dispatch(
      getFinalSummaryTableData({
        selectedScreens: tp,
        screenIds: filteredScreenList?.map((screen: any) => screen.screenId),
      })
    );
  };

  const handleTotalCost = (allScreens: any[]) => {
    const result = allScreens?.reduce((accum: number, current: any) => {
      let x = accum + current?.rateForOneDay;
      return x;
    }, 0);
    return result?.toFixed(0);
  };

  const handleSaveAndContinue = () => {
    if (validateForm()) {
      finalSummaryTableData();
      const filteredData = filterFive?.length > 0 ? filterFive : allScreens;
      saveDataOnLocalStorage(FILTERED_SCREENS, filteredData);
      saveDataOnLocalStorage(PRICING_DATA, pricingData);
      navigate("/create-campaign/final-summary", {
        state: {
          campaignDetails: {
            campaignName,
            brandName,
            startDate,
            endDate,
            campaignType,
          },
          allScreens,
          pricingData,
          filterOptions:
            getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || [],
        },
      });
    }
  };

  useEffect(() => {
    let ids =
      filterFive?.length > 0
        ? filterFive?.map((s: any) => s?.screenId)
        : allScreens?.map((s: any) => s?.screenId);
    if (ids?.length > 0)
      dispatch(
        getPricingDetailsTableData({
          screenIds: ids,
          selectedList: getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || {},
        })
      );
  }, [dispatch, filterFive]);

  useEffect(() => {
    if (getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) !== null) {
      dispatch(
        getScreensDateFilterByScreenAudiences(
          getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || {}
        )
      );
    }
    dispatch(getScreenDataByAudiences());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }

    if (userInfo?.isBrand === false) {
      alert("You have no access this page");
      navigate("/screens");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (filteredData) {
      calculateTotalScreens(filteredData);
    }
    if (showPricingTable && pricingData) {
      calculateTotalScreensPricing(pricingData);
    }
  }, [filteredData, navigate, showPricingTable, pricingData]);

  return (
    <div className="mt-6 w-full h-full pb-5">
      <div className="pt-10">
        <h1 className="text-3xl font-bold">Campaign Details</h1>
        <p className="text-sm text-gray-500">
          This shall help you decide cohort spacio-temporal of human mobility
          and preferences/urban geo-social connectivity
        </p>
        <div className="py-3">
          <EnterCampaignDetails
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            brandName={brandName}
            setBrandName={setBrandName}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            duration={duration}
            handleSetDuration={setDuration}
            handleSetNewDuration={handleSetNewDuration}
          />
          {showAudienceTable ? (
            <div className="py-2">
              {loading && loadingFiltered ? (
                <Loading />
              ) : error ? (
                <Message message={"ERROR MESSAGE"} content={error} />
              ) : (
                <div className="py-2">
                  <CampaignCreateTable
                    data={audienceData}
                    showFilterLevels={showFilterLevels}
                    loadingFiltered={loadingFiltered}
                    filteredResultByCity={filteredResultByCity}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full flex justify-end py-2">
              <button
                type="button"
                title="button"
                className="flex justify-end items-center text-blue-500"
                // className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
                onClick={() => {
                  if (validateForm()) {
                    handleSetNewDuration();
                    setShowAudienceTable(true);
                  }
                }}
              >
                Click here to select your audience and continue your planning{" "}
                {`>`}
              </button>
            </div>
          )}

          {showAudienceTable && showFilterLevels ? (
            <div>
              <div className="my-5 border rounded-md border-GreenBgLight p-5 bg-GreenBgLight">
                {loadingFiltered ? (
                  <Loading height={60} width={200} />
                ) : (
                  <div
                    onClick={() => {
                      if (validateForm()) {
                        setShowAudienceTable(true);
                      }
                    }}
                  >
                    <p className="text-base text-center text-GreenBgLightText font-bold">
                      As per your selections, we have identified{" "}
                      {totalChosenScreens} screens for you across{" "}
                      {Object?.keys(filteredResultByCity)?.length || 0} cities
                      of your choice and showing it in the map below. Rs.{" "}
                      {convertNumberToIndianRs(handleTotalCost(allScreens))}{" "}
                      cost for 1 day and your campaign duration is {duration}{" "}
                      Days, so Total cost be Rs.{" "}
                      {convertNumberToIndianRs(
                        handleTotalCost(allScreens) * (duration || 1)
                      )}
                    </p>
                  </div>
                )}
              </div>
              {!loadingFiltered && allScreens?.length > 0 && (
                <div className="py-3">
                  <FiveLevelFilter
                    filteredOptions={filteredData}
                    allScreens={allScreens}
                    setFilteredScreens={setFilterFive}
                  />
                </div>
              )}
            </div>
          ) : showAudienceTable && !showFilterLevels ? (
            <div>
              <button
                type="button"
                title="button"
                className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
                onClick={() => {
                  dispatch(
                    getScreensDateFilterByScreenAudiences(
                      JSON.parse(
                        localStorage?.getItem(AUDIENCES_FILTER_OPTIONS) || "{}"
                      )
                    )
                  );
                  setShowFilterLevels(true);
                }}
              >
                Next
              </button>
            </div>
          ) : null}

          {showAudienceTable && showFilterLevels && showPricingTable ? (
            <div className="">
              {loadingPricing ? (
                <div className="my-5 border rounded-md border-GreenBgLight p-5 bg-GreenBgLight">
                  <Loading height={60} width={200} />
                </div>
              ) : (
                <div>
                  <div className="my-5 border rounded-md border-GreenBgLight p-5 bg-GreenBgLight">
                    <p className="text-base text-center text-GreenBgLightText font-bold">
                      As per your filter selections, we have identified{" "}
                      {totalChosenScreensPricing} screens for you across{" "}
                      {Object?.keys(filteredResultByCityPricing)?.length || 0}{" "}
                      cities of your choice and showing it in the map below.
                    </p>
                  </div>
                  <NewCohortPlanningTable
                    data={pricingData}
                    setCampaignType={setCampaignType}
                    endData={endDate}
                    startDate={startDate}
                  />
                </div>
              )}
            </div>
          ) : showAudienceTable &&
            showFilterLevels &&
            !showPricingTable &&
            !loadingFiltered ? (
            <div>
              <button
                type="button"
                title="button"
                className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
                onClick={() => {
                  setShowPricingTable(true);
                }}
              >
                Next
              </button>
            </div>
          ) : null}

          {showAudienceTable && showFilterLevels && showPricingTable && (
            <div className="py-3">
              <button
                type="button"
                title="button"
                className="my-2 w-40 h-10 bg-proohYellow focus:outline-none hover:bg-gray-300 rounded-md"
                onClick={handleSaveAndContinue}
              >
                <h1 className="text-sm text-black font-bold antialiased">
                  Save & Continue
                </h1>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
