import { Loading } from "../../components";
import React, { useState, useRef } from "react";

export const Testing: React.FC = () => {

  return (
    <div
      style={{
        marginTop: "30px",
        padding: "20px",
      }}
    >
      <Loading />
    </div>
  );
}

