import React, { useEffect, useState } from "react";

interface Data {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: number;
        };
      };
    };
  };
}

export const HomeTable: React.FC<Data> = ({ data = {} }: Data) => {
  const [defCnt, setDefCnt] = useState<any>([]);
  const [defSt, setDefSt] = useState<any>([]);
  const [defCt, setDefCt] = useState<any>("");

  const [countryStates, setCountryStates] = useState<any>({});
  const [stateCities, setStateCities] = useState<any>({});
  const [cityTouchpoints, setCityTouchpoints] = useState<any>({});
  const [touchpointsCities, setTouchpointsCities] = useState<any>({});

  const getTotalCountryCount = () => {
    return Object.keys(data)?.length || 0;
  };

  const getTotalStatesCounts = () => {
    let ans = 0;
    if (defCnt?.length === 0) {
      for (let cnt in data) {
        let statesCount = Object.keys(data[cnt])?.length || 0;
        ans += statesCount;
      }
    } else {
      for (let cnt of defCnt) {
        let statesCount = Object.keys(data[cnt])?.length || 0;
        ans += statesCount;
      }
    }
    return ans;
  };

  const getTotalTouchPointsCount = () => {
    return Object.keys(touchpointsCities)?.length || 0;
  };

  const getTotalCityCount = () => {
    return Object.keys(cityTouchpoints)?.length || 0;
  };

  const getTotalScreensCountCityWise = (city: string) => {
    let ans = 0;
    for (let tp of Object.keys(touchpointsCities)) {
      if (cityTouchpoints[city][tp] != undefined) {
        let x = cityTouchpoints[city][tp];
        ans += x;
      }
    }
    return ans;
  };

  const getTotalScreensCountTouchpointWise = (tp: string) => {
    let ans = 0;
    for (let city of Object.keys(cityTouchpoints)) {
      if (cityTouchpoints[city][tp] != undefined) {
        let x = cityTouchpoints[city][tp];
        ans += x;
      }
    }
    return ans;
  };

  const getTotalScreensCount = () => {
    let ans = 0;
    for (let tp of Object.keys(touchpointsCities)) {
      for (let city of Object.keys(cityTouchpoints)) {
        if (cityTouchpoints[city][tp] != undefined) {
          let x = cityTouchpoints[city][tp];
          ans += x;
        }
      }
    }
    return ans;
  };

  const fillCntData = (myData: any) => {
    const cs: any = {};
    // Extracting data for cs
    for (const country in myData) {
      cs[country] = cs[country] || {};
      for (const state in myData[country]) {
        cs[country][state] = Object.keys(myData[country][state]).length;
      }
    }
    return cs;
  };

  const fillStateData = (myData: any) => {
    const sc: any = {};
    // Extracting data for sc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          sc[state] = sc[state] || {};
          sc[state][city] = Object.keys(myData[country][state][city]).length;
        }
      }
    }
    return sc;
  };

  const fillCityData = (myData: any) => {
    const ct: any = {};
    // Extracting data for ct
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          ct[city] = ct[city] || {};
          for (const attribute in myData[country][state][city]) {
            ct[city][attribute] = myData[country][state][city][attribute];
          }
        }
      }
    }
    return ct;
  };

  const fillTpData = (myData: any) => {
    const tc: any = {};
    // Extracting data for tc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const attribute in myData[country][state][city]) {
            tc[attribute] = tc[attribute] || {};
            tc[attribute][city] = myData[country][state][city][attribute];
          }
        }
      }
    }
    const sortedArray = Object.entries(tc).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
    const sortedObject = Object.fromEntries(sortedArray);
    return sortedObject;
  };

  const handleCntClick = (country: any) => {
    const dfc = Array.from(new Set([...defCnt, country]));
    const dataToShow: any = {};
    dfc.map((d: any) => {
      dataToShow[d] = data[d];
    });
    setStateCities(fillStateData(dataToShow));
    setCityTouchpoints(fillCityData(dataToShow));
    setTouchpointsCities(fillTpData(dataToShow));
  };

  const handleStClick = (state: any) => {
    const dfs = Array.from(new Set([...defSt, state]));
    const dataToUse: any = {};
    dfs.map((d: any) => {
      dataToUse[d] = stateCities[d];
    });
    const dataToShow: any = {};

    for (const state in dataToUse) {
      for (const city in dataToUse[state]) {
        dataToShow[city] = fillCityData(data)[city];
      }
    }

    setCityTouchpoints(dataToShow);
  };

  useEffect(() => {
    if (data !== undefined) {
      setCountryStates(fillCntData(data));
      setStateCities(fillStateData(data));
      setCityTouchpoints(fillCityData(data));
      setTouchpointsCities(fillTpData(data));
    }
  }, [data]);

  return (
    <div className="">
      <div className="grid grid-cols-12 border-y border border-proohGray bg-[#FFD600]">
        <div className="border-r border-proohGray col-span-3 flex justify-between px-5">
          <h1 className="text-black font-bold text-center px-4 py-2">Countries ({getTotalCountryCount()})</h1>
        </div>
        <div className="col-span-8 flex flex-nowrap overflow-scroll no-scrollbar">
          {Object.keys(data)?.map((c: any, i: any) => (
            <div
              className={
                defCnt.includes(c)
                  ? "border-r border-proohGray bg-proohYellow flex-none w-64 flex justify-center cursor-pointer items-center"
                  : "border-r border-proohGray bg-transparent flex-none w-64 flex justify-center cursor-pointer items-center"
              }
              key={i}
              onClick={() => {
                if (!defCnt.includes(c)) {
                  setDefCnt([...defCnt, c]);
                }
                handleCntClick(c);
              }}
            >
              <h1 className="text-black font-bold align-center truncate">
                {c}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-12 border border-proohGray bg-[#FFF09F]">
        <div className="border-r border-proohGray col-span-3 flex justify-between px-5 py-2">
          <h1 className="text-black font-bold text-center px-4">States ({getTotalStatesCounts()})</h1>
        </div>
        <div className="col-span-8 flex flex-nowrap overflow-scroll no-scrollbar">
          {Object.keys(stateCities).map((s: any, i: any) => (
            <div
              className={
                defSt.includes(s)
                  ? "border-r border-proohGray bg-proohYellow flex-none w-64 flex justify-center cursor-pointer items-center"
                  : "border-r border-proohGray bg-transparent flex-none w-64 flex justify-center cursor-pointer items-center"
              }
              key={i}
            >
              <h1
                className="text-black font-bold align-center truncate"
                onClick={() => {
                  if (!defCnt.includes(s)) {
                    setDefSt([...defSt, s]);
                  }
                  handleStClick(s);
                }}
              >
                {s}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <div className="border-y border-l border-proohGray flex justify-between px-5 items-center py-2 bg-[#FFFADF]">
            <h1 className="text-black font-bold text-center px-4">
              City ({getTotalCityCount()})
            </h1>
          </div>
          {Object.keys(touchpointsCities).map((tp: any, i: any) => (
            <div
              className="flex flex-row justify-between px-5 border-b border-l border-proohGray py-2"
              key={i}
            >
              <h1 className="px-1 font-semibold truncate px-4">{tp} ({getTotalScreensCountTouchpointWise(tp)})</h1>
            </div>
          ))}
          <div className="border border-[#FFD600] bg-[#FFD600] flex flex-row justify-between px-5 py-2">
            <div className="px-4 font-semibold">Total ({getTotalScreensCount()})</div>
          </div>
        </div>
        <div className="col-start-4 col-span-12 border border-proohGray">
          <div className="flex flex-nowrap overflow-scroll no-scrollbar ">
            {Object.keys(cityTouchpoints).map((c: any, j: any) => (
              <div className="flex-none w-64" key={j}>
                <div
                  className={
                    defCt.includes(c)
                      ? "border-r border-proohGray bg-proohYellow flex justify-center cursor-pointer py-2 bg-[#FFFADF]"
                      : "border-r border-b border-proohGray bg-transparent flex justify-center cursor-pointer py-2 bg-[#FFFADF]"
                  }
                >
                  {c}
                </div>
                {Object.keys(touchpointsCities).map((t: any, k: any) => (
                  <div
                    className="border-b border-r border-proohGray flex justify-center py-2"
                    key={k}
                  >
                    {touchpointsCities[t][c] || 0}
                  </div>
                ))}
                <div className="flex flex-row border border-proohGray justify-center py-2">
                  <div className="">{getTotalScreensCountCityWise(c)} </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
