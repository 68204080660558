import React, { useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  CAMPAIGN_TYPE_REGULAR,
  CAMPAIGN_TYPE_SPECIAL,
} from "../../../constants/campaignConstants";
import { CAMPAIGN_DETAILS } from "../../../constants/localStorageConstants";
import { getNumberOfDaysBetweenTwoDates } from "../../../utils/dateAndTimeUtils";
import { convertNumberToIndianRs } from "../../../utils/valueValidate";

export const NewFinalResultTable: React.FC<any> = ({
  data,
  campaignType,
  setTotalCost,
  setSumData,
  startDate,
  endDate,
  isStateShow,
}) => {
  const [totalData, setTotalData] = useState<any>();
  const [campaignDetails, setCampaignDetails] = useState<any>({});

  useEffect(() => {
    setCampaignDetails(
      JSON.parse(window.localStorage.getItem(CAMPAIGN_DETAILS) || "{}")
    );
  }, [startDate, endDate]);

  const uptoTwoDecimal = (value: number) => {
    if (value != undefined || value != null) return value.toFixed(2);
  };
  const getNoOFDays = (campaignDetails: any) => {
    console.log(campaignDetails);
    if (
      campaignDetails?.startDate != undefined &&
      campaignDetails?.startDate != "" &&
      campaignDetails?.endDate != "" &&
      campaignDetails?.endDate != undefined
    ) {
      return getNumberOfDaysBetweenTwoDates(
        campaignDetails?.startDate,
        campaignDetails?.endDate
      );
    } else {
      return 1;
    }
  };

  const getStateList = () => {
    let res: any = [];
    if (data != undefined) {
      for (let cnt in data) {
        res = [...res, ...Object.keys(data[cnt])];
      }
    }
    return res;
  };

  const getCityList = () => {
    let res: any = [];
    if (data != undefined) {
      for (let cnt in data) {
        for (let state in data[cnt]) {
          res = [...res, ...Object.keys(data[cnt][state])];
        }
      }
    }
    return res;
  };

  const getTotalCost = () => {
    return {
      totalCost: getNoOFDays(campaignDetails) * totalData?.priceForDay,
      totalCostForCohort:
        getNoOFDays(campaignDetails) * totalData?.priceForDayCohort,
    };
  };

  const calculateTotalStats = (myData: any) => {
    let sum = {
      cpm: 0,
      cpmCohort: 0,
      impressionPerDay: 0,
      impressionPerDayCohort: 0,
      priceForDay: 0,
      priceForDayCohort: 0,
      slotPerDay: 0,
      slotPerDayCohort: 0,
      sov: 0,
      sovCohort: 0,
      totalScreens: 0,
    };
    let numCities = 0;
    Object.values(myData).forEach((cnt: any) => {
      Object.values(cnt).forEach((st: any) => {
        Object.values(st).forEach((ct: any) => {
          Object.values(ct).forEach((c: any) => {
            sum.totalScreens++;
            sum.slotPerDay += c.slotPerDay;
            sum.slotPerDayCohort += c.slotPerDayCohort;
            sum.sov += c.sov;
            sum.sovCohort += c.sovCohort;
            sum.impressionPerDay += c.impressionPerDay;
            sum.impressionPerDayCohort += c.impressionPerDayCohort;
            sum.priceForDay += c.priceForDay;
            sum.priceForDayCohort += c.priceForDayCohort;
            sum.cpm += c.cpm;
            sum.cpmCohort += c.cpmCohort;
            numCities++;
          });
        });
      });
    });
    sum.cpm /= numCities;
    sum.cpmCohort /= numCities;
    sum.sov /= numCities;
    sum.sovCohort /= numCities;
    sum.priceForDay /= numCities;
    sum.priceForDayCohort /= numCities;

    return sum;
  };

  const fillCountryData = (myData: any) => {
    let sumData = calculateTotalStats(myData);
    setTotalData(calculateTotalStats(myData));
    setSumData(sumData);
    setTotalCost(
      campaignType === CAMPAIGN_TYPE_REGULAR
        ? calculateTotalStats(myData).priceForDay.toFixed(0)
        : calculateTotalStats(myData).priceForDayCohort.toFixed(0)
    );
  };

  useEffect(() => {
    // console.log("dddddddddd data", data);
    if (data != undefined) {
      fillCountryData(data);
      setTotalData(calculateTotalStats(data));
    }
  }, [data]);

  return (
    <div>
      {isStateShow && (
        <div>
          <h1 className="text-xl font-bold py-2">Media Plan Summary-</h1>
          <h1 className="text-md font-semibold">State</h1>
          <div className="flex gap-4">
            {getStateList()?.map((state: string) => (
              <div
                key={state}
                className="flex text-sm  font-semibold justify-center items-center px-4 py-1 border border-1 rounded-full bg-yellow-400"
              >
                {state}
              </div>
            ))}
          </div>
          <h1 className="text-md font-semibold pt-2">City</h1>
          <div className="flex gap-4">
            {getCityList()?.map((state: string) => (
              <div
                key={state}
                className="flex text-sm font-semibold justify-center items-center px-4 py-1 border border-1 rounded-full bg-yellow-400"
              >
                {state}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col mt-4">
        {/* Headers */}
        <div className="flex flex-row ">
          <div className="border border-1 w-96 h-8 flex justify-center items-center bg-gray-300">
            Final Result
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center bg-green-300">
              Regular slots buying
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center bg-blue-300">
              Cohort slots buying
            </div>
          )}
        </div>
        {/* body */}
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Total Screens
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {totalData?.totalScreens}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {totalData?.totalScreens}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Total Impression Per Day
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {convertNumberToIndianRs(totalData?.impressionPerDay.toFixed(0))}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(
                totalData?.impressionPerDayCohort.toFixed(0)
              )}
            </div>
          )}
        </div>
        {/* <div className="flex flex-row">
          <div className="border border-1 w-56 h-8 flex justify-center items-center">
            TG %{" "}
          </div>
          <div className="border border-1 w-56 h-8  flex justify-center items-center">
            N/A
          </div>
          <div className="border border-1 w-56 h-8 flex justify-center items-center">
            N/A
          </div>
        </div> */}
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            CPM
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {convertNumberToIndianRs(uptoTwoDecimal(totalData?.cpm))}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(uptoTwoDecimal(totalData?.cpmCohort))}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            SOV
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {convertNumberToIndianRs(uptoTwoDecimal(totalData?.sov))}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(uptoTwoDecimal(totalData?.sovCohort))}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Total Slot Per Day
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {convertNumberToIndianRs(totalData?.slotPerDay?.toFixed(0))}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(totalData?.slotPerDayCohort?.toFixed(0))}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Price Per Slot
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              {convertNumberToIndianRs(
                uptoTwoDecimal(
                  totalData?.priceForDay / totalData?.slotPerDay?.toFixed(0)
                )
              )}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(
                uptoTwoDecimal(
                  totalData?.priceForDayCohort /
                    totalData?.slotPerDayCohort?.toFixed(0)
                )
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Price Per Day
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(uptoTwoDecimal(totalData?.priceForDay))}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {convertNumberToIndianRs(
                uptoTwoDecimal(totalData?.priceForDayCohort)
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Campaign Duration
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {getNoOFDays(campaignDetails)} Days
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              {getNoOFDays(campaignDetails)} Days
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Total Cost Of The Campaign of Each screen
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              Rs.{" "}
              {convertNumberToIndianRs(
                uptoTwoDecimal(getTotalCost()?.totalCost)
              )}
            </div>
          )}
          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              Rs.{" "}
              {convertNumberToIndianRs(
                uptoTwoDecimal(getTotalCost()?.totalCostForCohort)
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row">
          <div className="border border-1 w-96 h-8 flex justify-center items-center">
            Net Total Cost Of The Campaign
          </div>
          {campaignType === "Regular" && (
            <div className="border border-1 w-56 h-8  flex justify-center items-center">
              Rs.{" "}
              {convertNumberToIndianRs(
                Number(uptoTwoDecimal(getTotalCost()?.totalCost)) *
                  totalData?.totalScreens
              )}
            </div>
          )}

          {campaignType === "Cohort" && (
            <div className="border border-1 w-56 h-8 flex justify-center items-center">
              Rs.{" "}
              {convertNumberToIndianRs(
                Number(uptoTwoDecimal(getTotalCost()?.totalCostForCohort)) *
                  totalData?.totalScreens
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
