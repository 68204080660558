import React, { useRef, ChangeEvent, useEffect, useState } from "react";

interface FileUploaderProps {
  handleFile: (file: File) => void;
}

export const FileUploader: React.FC<FileUploaderProps> = ({ handleFile }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>();

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    setFile(fileUploaded);
  };

  useEffect(() => {
    if (file) {
      handleFile(file);
    }
  }, [file]);

  return (
    <div className="flex flex-row justify-center items-center border border-solid p-2 w-64">
      <button className="" onClick={handleClick}>
        Upload a file
      </button>
      <input
        title="select media"
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
        multiple={false}
      />
    </div>
  );
};
