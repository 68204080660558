import React, { useEffect, useState } from "react";
import { SingleScreenDataModal } from "../popup/SingleScreenDataModal";
import { MdDeselect } from "react-icons/md";

interface Data {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: number;
        };
      };
    };
  };
}

export const FinalSummaryTable: React.FC<any> = ({
  data = {},
  allScreen,
  handleSetFilteredScreens,
  isScreenSelected,
  handleMultipleFilteredScreen,
}) => {
  // console.log("FinalSummaryTable", data);
  const [defCnt, setDefCnt] = useState<any>([]);
  const [defSt, setDefSt] = useState<any>([]);

  const [countryStates, setCountryStates] = useState<any>({});
  const [stateCities, setStateCities] = useState<any>({});
  const [cityTouchPoints, setCityTouchPoints] = useState<any>({});
  const [touchPointsCities, setTouchPointsCities] = useState<any>({});
  const [selectedScreen, setSelectedScreen] = useState<any>(null);

  const [isPopupOpen, setIsPopupOpen] = useState<Boolean>(false);
  const [modalData, setModalData] = useState<any>(null);

  const handleOpenScreenDetailModel = (screenName: string) => {
    console.log("handleOpenScreenDetailModel : ", screenName);
    const screenData = allScreen?.find(
      (screen: any) => screen?.screenName == screenName
    );
    console.log("screenData : ", screenData);
    setSelectedScreen(screenData);
    setTimeout(() => {
      setIsPopupOpen(true);
    }, 0);
  };

  const fillCntData = (myData: any) => {
    const cs: any = {};
    // Extracting data for cs
    for (const country in myData) {
      cs[country] = cs[country] || {};
      for (const state in myData[country]) {
        cs[country][state] = Object.keys(myData[country][state]).length;
      }
    }
    return cs;
  };

  const fillStateData = (myData: any) => {
    const sc: any = {};
    // Extracting data for sc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          sc[state] = sc[state] || {};
          sc[state][city] = Object.keys(myData[country][state][city]).length;
        }
      }
    }
    return sc;
  };

  const fillCityData = (myData: any) => {
    const ct: any = {};
    // Extracting data for ct
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          ct[city] = ct[city] || {};
          for (const attribute in myData[country][state][city]) {
            ct[city][attribute] = myData[country][state][city][attribute];
          }
        }
      }
    }
    return ct;
  };

  const fillTpData = (myData: any) => {
    const tc: any = {};
    // Extracting data for tc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const attribute in myData[country][state][city]) {
            tc[attribute] = tc[attribute] || {};
            for (const highlight in myData[country][state][city][attribute]) {
              tc[attribute][highlight] = tc[attribute][highlight] || {};

              tc[attribute][highlight][city] =
                myData[country][state][city][attribute][highlight];
            }
            // tc[attribute] = myData[country][state][city][attribute];
          }
        }
      }
    }
    const sortedArray = Object.entries(tc).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
    const sortedObject = Object.fromEntries(sortedArray);
    // console.log(sortedObject);
    return sortedObject;
  };

  const handleCntClick = (country: any) => {
    const dfc = Array.from(new Set([...defCnt, country]));
    const dataToShow: any = {};
    dfc.map((d: any) => {
      dataToShow[d] = data[d];
    });
    setStateCities(fillStateData(dataToShow));
    setCityTouchPoints(fillCityData(dataToShow));
    setTouchPointsCities(fillTpData(dataToShow));
  };

  const handleStClick = (state: any) => {
    const dfs = Array.from(new Set([...defSt, state]));
    const dataToUse: any = {};
    dfs.map((d: any) => {
      dataToUse[d] = stateCities[d];
    });
    const dataToShow: any = {};

    for (const state in dataToUse) {
      for (const city in dataToUse[state]) {
        dataToShow[city] = fillCityData(data)[city];
      }
    }

    setCityTouchPoints(dataToShow);
  };

  useEffect(() => {
    if (data !== undefined) {
      setCountryStates(fillCntData(data));
      setStateCities(fillStateData(data));
      setCityTouchPoints(fillCityData(data));
      setTouchPointsCities(fillTpData(data));
    }
  }, [data]);
  // console.log(cityTouchPoints["Gurgaon"]["Malls"])
  // console.log(touchPointsCities);

  const closePopup = () => setIsPopupOpen(false);

  return (
    <div>
      <SingleScreenDataModal
        isOpen={isPopupOpen}
        onClose={closePopup}
        props={modalData}
        screen={selectedScreen}
      />
      <div className="grid grid-cols-12">
        <div className="col-span-12 flex flex-nowrap border-t border-r border-proohGray overflow-scroll no-scrollbar">
          {Object.keys(data)?.map((c: any, i: any) => (
            <div
              className={
                defCnt.includes(c)
                  ? "p-1 border-x border-proohGray bg-proohYellow flex-none w-32 flex justify-center cursor-pointer"
                  : "p-1 border-x border-proohGray bg-transparent flex-none w-32 flex justify-center cursor-pointer"
              }
              key={i}
              onClick={() => {
                if (!defCnt.includes(c)) {
                  setDefCnt([...defCnt, c]);
                } else {
                  setDefCnt(defCnt.filter((f: any) => f !== c));
                }
                handleCntClick(c);
              }}
            >
              <h1 className="text-black font-bold align-center truncate">
                {c}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 flex flex-nowrap border-y border-r border-proohGray overflow-scroll no-scrollbar">
          {Object.keys(stateCities).map((s: any, i: any) => (
            <div
              className={
                defSt.includes(s)
                  ? "p-1 border-x border-proohGray bg-proohYellow flex-none w-32 flex justify-center cursor-pointer"
                  : "p-1 border-x border-proohGray bg-transparent flex-none w-32 flex justify-center cursor-pointer"
              }
              key={i}
            >
              <h1
                className="text-black font-bold text-center truncate"
                onClick={() => {
                  if (!defCnt.includes(s)) {
                    setDefSt([...defSt, s]);
                  } else {
                    setDefSt(defSt.filter((f: any) => f !== s));
                  }
                  handleStClick(s);
                }}
              >
                {s}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-12 border-y border-proohGray">
          <div className="col-span-12 grid grid-cols-12">
            <div className="col-span-12 grid grid-cols-12">
              <div className="col-span-2 p-1 border-x border-proohGray">
                <h1 className="text-base font-bold text-center">Touchpoints</h1>
              </div>
              <div className="col-span-10">
                <div className="grid grid-cols-10">
                  <div className="col-span-3 p-1 border-x border-black-300">
                    <h1 className="text-base font-semibold text-center truncate">
                      Screen Type
                    </h1>
                  </div>
                  <div className="col-span-7 flex overflow-scroll no-scrollbar border-r">
                    {Object.keys(cityTouchPoints).map((ct: any, i: any) => (
                      <div
                        className="p-1 w-64 border-x border-proohGray"
                        key={i}
                      >
                        <h1 className="text-base font-semibold text-center truncate">
                          {ct}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 grid grid-cols-12 border-r">
            {Object.keys(touchPointsCities).map((tp: any, j: any) => (
              <div className="col-span-12 grid grid-cols-12" key={j}>
                <div className="col-span-2 p-1 border border-proohGray">
                  <h1 className="text-base font-bold text-center truncate">
                    {tp}
                  </h1>
                </div>
                <div className="col-span-10">
                  {Object.keys(touchPointsCities[tp]).map((hl: any, j: any) => (
                    <div className="grid grid-cols-10" key={j}>
                      <div
                        className="col-span-3 p-1 border border-black-300"
                        onClick={() => {
                          for (const s of Object.keys(touchPointsCities[tp][hl])) {
                            console.log(Object.values(touchPointsCities[tp][hl][s]));
                            // for (const c of Object.keys(touchPointsCities[tp][hl][s])) {
                            // console.log(touchPointsCities[tp][hl][s][c])
                            handleMultipleFilteredScreen(Object.values(touchPointsCities[tp][hl][s]));
                            // } 
                          }
                          // console.log(handleMultipleFilteredScreen)

                          // Object.keys(touchPointsCities[tp][hl]).map((c: any) => touchPointsCities[tp][hl][c])?.map((g: any) => {
                          //     Object.values(g)?.map((s: any) => {
                          //     console.log(s);
                          //     handleSetFilteredScreens(s);
                          //     })
                          //   });
                        }}  
                      >
                        <h1 className="text-base font-semibold text-center truncate">
                          {hl}
                        </h1>
                      </div>
                      <div className="col-span-7">
                        {Object.keys(touchPointsCities[tp][hl]).map(
                          (c: any, k: any) => (
                            <div
                              className="flex flex-row w-full h-auto"
                              key={k}
                            >
                              {Object.keys(cityTouchPoints).map(
                                (ct: any, k: any) => (
                                  <div
                                    className="cursor-pointer w-full"
                                    key={k}
                                  >
                                    {Object.keys(
                                      touchPointsCities[tp][hl][c]
                                    )?.map((g: any, z: any) => (
                                      <div
                                        key={z}
                                        // className={
                                        //   ct === c
                                        //     ? "border w-64 h-auto p-1 flex flex-row justify-start items-center pl-4 gap-4"
                                        //     : "bg-red-300"
                                        // }
                                        className={
                                          isScreenSelected(
                                            ct === c
                                              ? touchPointsCities[tp][hl][c][g]
                                              : "w-full"
                                          )
                                            ? "bg-yellow-300 border w-full h-auto p-1 flex flex-row justify-start items-center pl-4 gap-4 truncate"
                                            : ct === c
                                            ? "border w-full h-auto p-1 flex flex-row justify-start items-center pl-4 gap-4 truncate"
                                            : "bg-red-300 truncate"
                                        }
                                      >
                                        {ct === c && (
                                          <input
                                            type="checkbox"
                                            onChange={() => {
                                              // console.log(touchPointsCities[tp][hl][
                                              //   c
                                              // ][g])
                                              handleSetFilteredScreens(
                                                ct === c
                                                  ? touchPointsCities[tp][hl][
                                                      c
                                                    ][g]
                                                  : ""
                                              );
                                            }}
                                            checked={isScreenSelected(
                                              ct === c
                                                ? touchPointsCities[tp][hl][c][
                                                    g
                                                  ]
                                                : ""
                                            )}
                                          />
                                        )}

                                        <h1
                                          title="CLick to view detail truncate"
                                          onClick={() => {
                                            handleOpenScreenDetailModel(
                                              ct === c
                                                ? touchPointsCities[tp][hl][c][
                                                    g
                                                  ]
                                                : ""
                                            );
                                          }}
                                        >
                                          {ct === c
                                            ? touchPointsCities[tp][hl][c][g]
                                            : ""}
                                        </h1>
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
