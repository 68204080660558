import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCampaigns } from "../../actions/campaignAction";
import { getAllScreens } from "../../actions/screenDataAction";
import { useNavigate } from "react-router-dom";
import { CAMPAIGNS_LIST, SIGNIN } from "../../routes/routes";
import {
  convertDataTimeToLocale,
  getNumberOfDaysBetweenTwoDates,
} from "../../utils/dateAndTimeUtils";
import { Loading, ReloadButton } from "../../components/index";
import { IoReload } from "react-icons/io5";

export const Campaigns = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [tooltipContent, setTooltipContent] = useState<any>([]);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const allCampaigns = useSelector((state: any) => state.allCampaigns);
  const { loading, error, data: campaigns } = allCampaigns;

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const allScreensData = useSelector((state: any) => state.allScreensData);
  const {
    loading: loadingAllScreens,
    error: errorAllScreens,
    data: allScreens,
  } = allScreensData;

  useEffect(() => {
    if (!userInfo) {
      navigate(SIGNIN);
    }
    if (!campaigns) dispatch(getAllCampaigns());
    if (!allScreens) dispatch(getAllScreens());
  }, [navigate, dispatch, userInfo]);

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLTableRowElement>,
    campaigns: any
  ) => {
    const screens: any = [];
    allScreens?.forEach((s: any) => {
      s.campaigns.forEach((c: any) => {
        if (campaigns.includes(c.campaignId)) {
          screens.push(s.screenName);
        }
      });
    });

    setTooltipContent(screens);

    const row = event.currentTarget;
    // console.log(row.getBoundingClientRect());
    const { top, left, width } = row.getBoundingClientRect();

    // Adjust tooltip position to appear above the row
    const tooltipTop = top; // Position the tooltip above the row with some margin
    const tooltipLeft = left + width / 2; // Center tooltip horizontally relative to the row

    setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
  };

  const handleMouseLeave = () => {
    setTooltipContent([]);
    setTooltipPosition(null);
  };

  const handleSelectCampaign = (campaign: any) => {
    navigate(CAMPAIGNS_LIST, {
      state: {
        campaign,
      },
    });
  };

  const reLoad = () => {
    dispatch(getAllCampaigns());
  };

  return (
    <div>
      <div className="flex flex-col justify-center gap-2 px-12">
        <div className="mt-10 flex gap-8 items-center">
          <h1 className="font-bold">
            Campaign List ({campaigns?.length || 0})
          </h1>
          <ReloadButton onClick={reLoad} />
        </div>
        <div className="h-[50rem] w-full overflow-scroll">
          {loading ? (
            <Loading />
          ) : campaigns?.length > 0 ? (
            <table className="overflow-scroll w-full">
              <thead className="w-full">
                <tr className="flex flex-row w-full bg-gray-200 py-1">
                  <th className="w-40 flex border-r border-gray-400 justify-center items-center">
                    Campaign Name
                  </th>
                  <th className="w-40 flex border-x border-gray-400 justify-center items-center">
                    Campaign Type
                  </th>
                  <th className="w-44 flex border-x border-gray-400 justify-center items-center">
                    Start Date
                  </th>
                  <th className="w-44 flex border-x border-gray-400 justify-center items-center">
                    End Date
                  </th>
                  <th className="w-32 flex border-x border-gray-400 justify-center items-center">
                    Duration
                  </th>
                  <th className="w-32 flex border-x border-gray-400 justify-center items-center">
                    Screens
                  </th>
                  <th className="w-32 flex border-l border-gray-400 justify-center items-center">
                    Action
                  </th>
                </tr>
              </thead>
              {/* <div className="auto w-full overflow-scroll"> */}

              <tbody className="w-full overflow-scroll">
                {campaigns.map((c: any) => (
                  <tr
                    onClick={() => handleSelectCampaign(c)}
                    className="w-full flex flex-row border cursor-pointer p-1"
                    key={c._id}
                    onMouseEnter={(event) => {
                      if (allScreens) {
                        handleMouseEnter(event, c.campaigns);
                      }
                    }}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="w-40 flex justify-start items-center text-sm">
                      {c.name.split("_")[0]}
                    </td>
                    <td className="w-40 flex justify-center items-center text-sm">
                      {c.campaignType}
                    </td>
                    <td className="w-44 flex justify-center items-center text-sm">
                      {convertDataTimeToLocale(c.startDate) !== "N/A"
                        ? convertDataTimeToLocale(c.startDate)
                        : "default"}
                    </td>
                    <td className="w-44 flex justify-center items-center text-sm">
                      {convertDataTimeToLocale(c.endDate) !== "N/A"
                        ? convertDataTimeToLocale(c.endDate)
                        : "default"}
                    </td>
                    <td className="w-32 flex justify-center items-center text-sm">
                      {convertDataTimeToLocale(c.startDate) === "N/A"
                        ? "default"
                        : getNumberOfDaysBetweenTwoDates(
                            c.startDate,
                            c.endDate
                          )}
                    </td>
                    <td className="w-32 flex justify-center items-center text-sm">
                      {c.campaigns.length}
                    </td>
                    <td className="w-32 flex justify-center items-center text-sm">
                      <div className="border rounded-2xl bg-blue-300 px-3 py-1 text-sm font-bold text-white">
                        Details
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* </div> */}
            </table>
          ) : null}
        </div>

        {tooltipContent.length > 0 && tooltipPosition && (
          <div
            className="absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
            style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
          >
            {tooltipContent.map((c: any, i: any) => (
              <p key={i}>{c}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
