export const ADD_CAMPAIGN_DATA_TO_STORAGE_ERROR =
  "ADD_CAMPAIGN_DATA_TO_STORAGE_ERROR";
export const ADD_CAMPAIGN_DATA_TO_STORAGE_REQUEST =
  "ADD_CAMPAIGN_DATA_TO_STORAGE_REQUEST";
export const ADD_CAMPAIGN_DATA_TO_STORAGE_SUCCESS =
  "ADD_CAMPAIGN_DATA_TO_STORAGE_SUCCESS";

export const CREATE_CAMPAIGNS_REQUEST = "CREATE_CAMPAIGNS_REQUEST";
export const CREATE_CAMPAIGNS_SUCCESS = "CREATE_CAMPAIGNS_SUCCESS";
export const CREATE_CAMPAIGNS_ERROR = "CREATE_CAMPAIGNS_ERROR";
export const CREATE_CAMPAIGNS_RESET = "CREATE_CAMPAIGNS_RESET";

export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";
export const CREATE_CAMPAIGN_RESET = "CREATE_CAMPAIGN_RESET";

export const CAMPAIGN_TYPE_SPECIAL = "Cohort";
export const CAMPAIGN_TYPE_REGULAR = "Regular";

export const GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST =
  "GET_CAMPAIGNS_BY_SCREEN_ID_REQUEST";
export const GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS =
  "GET_CAMPAIGNS_BY_SCREEN_ID_SUCCESS";
export const GET_CAMPAIGNS_BY_SCREEN_ID_ERROR =
  "GET_CAMPAIGNS_BY_SCREEN_ID_ERROR";
export const GET_CAMPAIGNS_BY_SCREEN_ID_RESET =
  "GET_CAMPAIGNS_BY_SCREEN_ID_RESET";

export const GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST =
  "GET_ALL_CAMPAIGN_BY_SCREEN_ID_REQUEST";
export const GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS =
  "GET_ALL_CAMPAIGN_BY_SCREEN_ID_SUCCESS";
export const GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR =
  "GET_ALL_CAMPAIGN_BY_SCREEN_ID_ERROR";
export const GET_ALL_CAMPAIGN_BY_SCREEN_ID_RESET =
  "GET_ALL_CAMPAIGN_BY_SCREEN_ID_RESET";

export const GET_ALL_CAMPAIGN_REQUEST = "GET_ALL_CAMPAIGN_REQUEST";
export const GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS";
export const GET_ALL_CAMPAIGN_ERROR = "GET_ALL_CAMPAIGN_ERROR";
export const GET_ALL_CAMPAIGN_RESET = "GET_ALL_CAMPAIGN_RESET";

export const GET_ALL_CAMPAIGN_BY_ADMIN_REQUEST =
  "GET_ALL_CAMPAIGN_BY_ADMIN_REQUEST";
export const GET_ALL_CAMPAIGN_BY_ADMIN_SUCCESS =
  "GET_ALL_CAMPAIGN_BY_ADMIN_SUCCESS";
export const GET_ALL_CAMPAIGN_BY_ADMIN_ERROR =
  "GET_ALL_CAMPAIGN_BY_ADMIN_ERROR";
export const GET_ALL_CAMPAIGN_BY_ADMIN_RESET =
  "GET_ALL_CAMPAIGN_BY_ADMIN_RESET";

export const GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST =
  "GET_CAMPAIGNS_BY_CAMPAIGN_IDS_REQUEST";
export const GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS =
  "GET_CAMPAIGNS_BY_CAMPAIGN_IDS_SUCCESS";
export const GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR =
  "GET_CAMPAIGNS_BY_CAMPAIGN_IDS_ERROR";
export const GET_CAMPAIGNS_BY_CAMPAIGN_IDS_RESET =
  "GET_CAMPAIGNS_BY_CAMPAIGN_IDS_RESET";

export const CHANGE_CHAMPAIGN_STATUS_REQUEST =
  "CHANGE_CHAMPAIGN_STATUS_REQUEST";
export const CHANGE_CHAMPAIGN_STATUS_SUCCESS =
  "CHANGE_CHAMPAIGN_STATUS_SUCCESS";
export const CHANGE_CHAMPAIGN_STATUS_ERROR = "CHANGE_CHAMPAIGN_STATUS_ERROR";
export const CHANGE_CHAMPAIGN_STATUS_RESET = "CHANGE_CHAMPAIGN_STATUS_RESET";

export const CAMPAIGN_STATUS_PENDING = "Pending"; // plea not accepted
export const CAMPAIGN_STATUS_ACTIVE = "Active"; // plea accepted || direct created campaign by screen owner || after getting access from screen owner
export const CAMPAIGN_STATUS_DELETED = "Deleted"; // deleetd by Ally or brand || enddate >= current date
export const CAMPAIGN_STATUS_PAUSE = "Pause"; // pause by screen owner
export const CAMPAIGN_STATUS_REJECTED = "Rejected"; // pause by screen owner
export const CAMPAIGN_STATUS_COMPLETED = "Completed"; // when campaign.remainingSlots  = 0
export const CAMPAIGN_STATUS_HOLD = "Hold";

export const GET_ALL_PLEA_REQUEST = "GET_ALL_PLEA_REQUEST";
export const GET_ALL_PLEA_SUCCESS = "GET_ALL_PLEA_SUCCESS";
export const GET_ALL_PLEA_ERROR = "GET_ALL_PLEA_ERROR";
export const GET_ALL_PLEA_RESET = "GET_ALL_PLEA_RESET";

export const SEND_PLEA_REQUEST = "SEND_PLEA_REQUEST";
export const SEND_PLEA_SUCCESS = "SEND_PLEA_SUCCESS";
export const SEND_PLEA_ERROR = "SEND_PLEA_ERROR";
export const SEND_PLEA_RESET = "SEND_PLEA_RESET";

export const SEND_PLEA_RESPONSE_REQUEST = "SEND_PLEA_RESPONSE_REQUEST";
export const SEND_PLEA_RESPONSE_SUCCESS = "SEND_PLEA_RESPONSE_SUCCESS";
export const SEND_PLEA_RESPONSE_ERROR = "SEND_PLEA_RESPONSE_ERROR";
export const SEND_PLEA_RESPONSE_RESET = "SEND_PLEA_RESPONSE_RESET";

export const PENDING = "pending";
export const ACCEPTED = "accepted";
export const REJECTED = "rejected";
export const BUDGET = "budget";
export const FINAL = "final";
export const SCREEN = "screen";
export const PRIMARY_CAMPAIGN_MANAGER = "PrimaryCampaignManager";
export const SECONDARY_CAMPAIGN_MANAGER = "SecondaryCampaignManager";
export const PRIMARY_SCREEN_OWNER = "PrimaryScreenOwner";
export const CAMPAIGN_STATUS_PLEA_REQUEST_BUDGET_SENT = "PleaRequestBudgetSent";
export const CAMPAIGN_STATUS_PLEA_REQUEST_BUDGET_ACCEPTED =
  "PleaRequestBudgetAccepted";
export const CAMPAIGN_STATUS_PLEA_REQUEST_BUDGET_REJECTED =
  "PleaRequestBudgetRejected";
export const CAMPAIGN_STATUS_PLEA_REQUEST_SCREEN_APPROVAL_SENT =
  "PleaRequestScreenApprovalSent";
export const CAMPAIGN_STATUS_PLEA_REQUEST_SCREEN_APPROVAL_ACCEPTED =
  "PleaRequestScreenApprovalAccepted";
export const CAMPAIGN_STATUS_PLEA_REQUEST_SCREEN_APPROVAL_REJECTED =
  "PleaRequestScreenApprovalRejected";
export const CAMPAIGN_STATUS_PLEA_REQUEST_FINAL_APPROVAL_SENT =
  "PleaRequestFinalApprovalSent";
export const CAMPAIGN_STATUS_PLEA_REQUEST_FINAL_APPROVAL_ACCEPTED =
  "PleaRequestFinalApprovalAccepted";
export const CAMPAIGN_STATUS_PLEA_REQUEST_FINAL_APPROVAL_REJECTED =
  "PleaRequestFinalApprovalRejected";

export const BUDGET_PLEA = "BudgetPlea";
export const SCREEN_PLEA = "ScreenPlea";
export const FINAL_PLEA = "FinalPlea";

export const SET_CAMPAIGNS_PRIORITY_REQUEST = "SET_CAMPAIGNS_PRIORITY_REQUEST";
export const SET_CAMPAIGNS_PRIORITY_ERROR = "SET_CAMPAIGNS_PRIORITY_ERROR";
export const SET_CAMPAIGNS_PRIORITY_SUCCESS = "SET_CAMPAIGNS_PRIORITY_SUCCESS";
export const SET_CAMPAIGNS_PRIORITY_RESET = "SET_CAMPAIGNS_PRIORITY_RESET";

export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_ERROR = "UPDATE_CAMPAIGN_ERROR";
export const UPDATE_CAMPAIGN_RESET = "UPDATE_CAMPAIGN_RESET";

export const CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST =
  "CAMPAIGN_LOG_BY_CAMPAIGN_ID_REQUEST";
export const CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS =
  "CAMPAIGN_LOG_BY_CAMPAIGN_ID_SUCCESS";
export const CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR =
  "CAMPAIGN_LOG_BY_CAMPAIGN_ID_ERROR";
export const CAMPAIGN_LOG_BY_CAMPAIGN_ID_RESET =
  "CAMPAIGN_LOG_BY_CAMPAIGN_ID_RESET";

export const GET_CAMPAIGNS_TABLE_DATA_REQUEST =
  "GET_CAMPAIGNS_TABLE_DATA_REQUEST";
export const GET_CAMPAIGNS_TABLE_DATA_SUCCESS =
  "GET_CAMPAIGNS_TABLE_DATA_SUCCESS";
export const GET_CAMPAIGNS_TABLE_DATA_ERROR = "GET_CAMPAIGNS_TABLE_DATA_ERROR";
export const GET_CAMPAIGNS_TABLE_DATA_RESET = "GET_CAMPAIGNS_TABLE_DATA_RESET";

export const ACTIVE_CAMPAIGNS_REQUEST = "ACTIVE_CAMPAIGNS_REQUEST";
export const ACTIVE_CAMPAIGNS_SUCCESS = "ACTIVE_CAMPAIGNS_SUCCESS";
export const ACTIVE_CAMPAIGNS_ERROR = "ACTIVE_CAMPAIGNS_ERROR";
export const ACTIVE_CAMPAIGNS_RESET = "ACTIVE_CAMPAIGNS_RESET";

export const PAUSE_CAMPAIGNS_REQUEST = "PAUSE_CAMPAIGNS_REQUEST";
export const PAUSE_CAMPAIGNS_SUCCESS = "PAUSE_CAMPAIGNS_SUCCESS";
export const PAUSE_CAMPAIGNS_ERROR = "PAUSE_CAMPAIGNS_ERROR";
export const PAUSE_CAMPAIGNS_RESET = "PAUSE_CAMPAIGNS_RESET";

export const PENDING_CAMPAIGNS_REQUEST = "PENDING_CAMPAIGNS_REQUEST";
export const PENDING_CAMPAIGNS_SUCCESS = "PENDING_CAMPAIGNS_SUCCESS";
export const PENDING_CAMPAIGNS_ERROR = "PENDING_CAMPAIGNS_ERROR";
export const PENDING_CAMPAIGNS_RESET = "PENDING_CAMPAIGNS_RESET";

export const COMPLETED_CAMPAIGNS_REQUEST = "COMPLETED_CAMPAIGNS_REQUEST";
export const COMPLETED_CAMPAIGNS_SUCCESS = "COMPLETED_CAMPAIGNS_SUCCESS";
export const COMPLETED_CAMPAIGNS_ERROR = "COMPLETED_CAMPAIGNS_ERROR";
export const COMPLETED_CAMPAIGNS_RESET = "COMPLETED_CAMPAIGNS_RESET";
