import { Loading } from "../../components/index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MediaContainer } from "../../components/common/MediaContainer";
import { IoSearch } from "react-icons/io5";

function SingleCreative({ creative }: any) {
  const playVideo = (url: string) => {
    window.open(url);
  };

  return (
    <div className="flex flex-col  gap-2 p-2 hover:bg-sky-300 cursor-pointer border-2 rounded-md">
      <MediaContainer
        className="rounded object-fill h-32 w-48"
        url={creative?.videoURL}
        mediaType={creative.creativeType}
      />
      <h1 className="text-black-700">{creative.creativeName}</h1>
    </div>
  );
}

// ------------------------------------------------------------

export function MiddleArea(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [searchResult, setSearchResult] = useState<any>([]);

  const [option, setOption] = useState<boolean>(true);

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const creativesList = useSelector((state: any) => state.creativesList);
  const { loading, error, data: creativeList } = creativesList;

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  useEffect(() => {
    if (error) {
      openErrorToast(error);
    }
  }, [error]);

  const findFromImage = (searchText: string) => {
    return creativeList?.images?.filter((creative: any) =>
      creative?.creativeName?.includes(searchText)
    );
  };

  const findFromVideo = (searchText: string) => {
    return creativeList?.videos?.filter((creative: any) =>
      creative?.creativeName?.includes(searchText)
    );
  };

  const handleSearch = (e: any) => {
    let searchValue = e.target.value;
    if (searchValue?.length > 0) {
      if (option) {
        setSearchResult(findFromImage(searchValue));
      } else {
        setSearchResult(findFromVideo(searchValue));
      }
    } else {
      setSearchResult([]);
    }
  };

  return (
    <div className="mt-12 w-full h-full pb-5">
      <ToastContainer />
      <h1 className="text-center text-xl font-bold">My Creatives</h1>
      {loading ? (
        <Loading />
      ) : (
        <div className="">
          <div className="flex flex-row gap-8">
            <button
              onClick={() => {
                setOption((pre) => !pre);
                setSearchResult([]);
              }}
              className={
                option
                  ? "text-blue-600 border-b-2 border-indigo-500 font-bold"
                  : ""
              }
            >
              Images
            </button>
            <button
              onClick={() => {
                setOption((pre) => !pre);
                setSearchResult([]);
              }}
              className={
                !option
                  ? "text-blue-600 border-b-2 border-indigo-500 font-bold"
                  : ""
              }
            >
              Videos
            </button>
          </div>
          <div className="flex flex-row gap-2 items-center px-2 border-2 rounded-lg w-96 h-12 mt-4">
            <IoSearch />
            <input
              type="text"
              placeholder="Enter creative name to search"
              className="w-full h-full px-2 rounded-lg"
              onChange={handleSearch}
            ></input>
          </div>
          <div className="mt-4 gap-4 flex flex-wrap">
            {option
              ? (searchResult.length > 0
                  ? searchResult
                  : creativeList?.images
                )?.map((creative: any, index: number) => (
                  <SingleCreative creative={creative} key={index + 1} />
                ))
              : (searchResult.length > 0
                  ? searchResult
                  : creativeList?.videos
                )?.map((creative: any, index: number) => (
                  <SingleCreative creative={creative} key={index + 1} />
                ))}
          </div>
        </div>
      )}
    </div>
  );
}
