export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_RESET = "USER_SIGNUP_RESET";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNIN_RESET = "USER_SIGNIN_RESET";

export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";
export const USER_UPDATE_PASSWORD_RESET = "USER_UPDATE_PASSWORD_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const ALLY_USER_ROLE = "ally";
export const MASTER_USER_ROLE = "master";

export const USER_ROLE_PRIMARY = "primary";
export const USER_ROLE_SECONDARY = "secondary";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_EMAIL_VERIFICATION_REQUEST =
  "USER_EMAIL_VERIFICATION_REQUEST";
export const USER_EMAIL_VERIFICATION_SUCCESS =
  "USER_EMAIL_VERIFICATION_SUCCESS";
export const USER_EMAIL_VERIFICATION_ERROR = "USER_EMAIL_VERIFICATION_ERROR";
export const USER_EMAIL_VERIFICATION_RESET = "USER_UPDATE_PROFILE_RESET";

export const SEND_EMAIL_TO_RESET_PASSWORD_REQUEST =
  "SEND_EMAIL_TO_RESET_PASSWORD_REQUEST";
export const SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS =
  "SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS";
export const SEND_EMAIL_TO_RESET_PASSWORD_ERROR =
  "SEND_EMAIL_TO_RESET_PASSWORD_ERROR";
export const SEND_EMAIL_TO_RESET_PASSWORD_RESET = "USER_UPDATE_PROFILE_RESET";
