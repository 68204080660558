import { convertIntoDateAndTime } from "../../utils/dateAndTimeUtils";

export const CampaignInfo = ({ campaign }: any) => {
  return (
    <div className="flex gap-8">
      <div className="font-bold">
        <h1>Campaign Name</h1>
        <h1>Start Date</h1>
        <h1>End Date</h1>
        <h1>Total Campaigns</h1>
        <h1>Campaign Type</h1>
        <h1>Campaign Booked for</h1>
      </div>
      <div>
        <h1>{campaign?.name}</h1>
        <h1>{convertIntoDateAndTime(campaign?.startDate) || "Default"}</h1>
        <h1>{convertIntoDateAndTime(campaign?.endDate) || "Default"}</h1>
        <h1>{campaign?.campaigns?.length}</h1>
        <h1>{campaign?.campaignType}</h1>
        <h1>{campaign?.campaignBookedForDays || "Infinite"} Days</h1>
      </div>
    </div>
  );
};
