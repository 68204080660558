import { removeAllKeyFromLocalStorage } from "../../../utils/localStorageUtils";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname.split("/")[1];

  const handleGoToPlanCampaign = () => {
    removeAllKeyFromLocalStorage();
    navigate("/create-campaign");
  };
  return (
    <div className="w-full bg-proohCyanFooter flex flex-row items-center justify-between z-1 px-40 py-10">
      <div className="flex flex-col">
        <h1 className="text-3xl text-white">
          WE BRING ADVANCED <strong>AUDIENCE</strong>
        </h1>
        <h1 className="text-3xl text-white">
          <strong>MEASUREMENT</strong> THROUGH MEASURED DATA
        </h1>
        <h1 className="py-5 text-sm text-white">
          personal identifiers and uses modelling of probablistic, historical
          spatial telco data for identification of locations that are relevant
          to target audience personas.
        </h1>
        <button
          type="button"
          title="button"
          className="w-40 h-10 bg-proohYellow focus:outline-none hover:bg-gray-300 rounded-md"
          onClick={handleGoToPlanCampaign}
        >
          <h1 className="text-sm text-black font-bold antialiased">
            Plan Campaign
          </h1>
        </button>
      </div>
    </div>
  );
};
