import { useEffect, useState } from "react";
import { getAWSUrlToUploadFile, saveFileOnAWS } from "../../../utils/awsUtils";
import { useNavigate } from "react-router-dom";
import { getVideoDurationFromVideoURL } from "../../../utils/fileUtils";
import { CREATIVES_DATA } from "../../../constants/localStorageConstants";

interface FileObject {
  url: string;
  fileType: string;
  fileSize: number;
  videoDuration: number;
  awsURL: string;
}
interface CreativityFile {
  screens: string[];
  duration: number;
  resolution: string;
  orientation: string;
  size: string;
  creativity: FileObject[];
}
type MapType = {
  [key: string]: {};
};

export function UploadMediasForScreens(props: any) {
  const navigate = useNavigate();
  const [creativity, setCreativity] = useState<any>([null]);
  const [groupData, setGroupData] = useState<any>({});
  // console.log("groupData updated : ", JSON.stringify(groupData));
  // console.log("creativity updated :", creativity);

  const handleSaveDataOnLocalStorage = (data: any) => {
    window.localStorage.setItem(CREATIVES_DATA, JSON.stringify(data));
    props.handleCreateCampaign(data);
  };

  const getScreenByKey = (key: string) => {
    return groupData[key];
  };

  useEffect(() => {
    let cr = JSON.parse(window.localStorage.getItem(CREATIVES_DATA) || "{}");
    setGroupData(cr);
  }, [navigate]);

  useEffect(() => {
    let obj: MapType = {};

    if (Object.keys(props.groupData)?.length > 0) {
      for (let key in props.groupData) {
        obj[key] = {
          screens: props.groupData[key]?.map((screen: any) => screen?.screenId),
          duration: props.groupData[key][0]?.slotLengthSeconds || 20,
          resolution: props.groupData[key][0]?.screenResolution,
          orientation: "Vertical",
          size: `${props.groupData[key][0]?.screenLength}'(W) X ${props.groupData[key][0]?.screenWidth}'(H)`,
          creativity: [
            {
              url: "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/668ce21cf9652f514386346d.mp4",
              fileType: "video.mp4",
              fileSize: 23232,
              videoDuration: 10,
              awsURL:
                "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/668ce21cf9652f514386346d.mp4",
            },
          ],
        };
      }
      setGroupData(obj);
    }
  }, [props]);

  const handleFileChange = async (file: File, key: number) => {
    let x = groupData;

    if (file) {
      const fileURL = URL.createObjectURL(file);
      let duration;
      if (file.type.split("/")[0] == "image") {
        duration = x[key]?.duration || 15;
      } else {
        duration = await getVideoDurationFromVideoURL(fileURL);
      }

      x[key].creativity.push({
        file: file,
        url: fileURL,
        fileType: file.type,
        fileSize: file.size,
        videoDuration: duration,
        awsURL: "",
      });
      // console.log("going to add file in X : ", key, x);
      setGroupData(x);
      // let xx = creativity;
      // xx[0] = file;
      setCreativity([file]);
      // setCreativity(x[key].creativity);
    }
  };

  const deleteSelectedFile = (key: number) => {
    let x = groupData;
    x[key].creativity.pop();
    setGroupData(x);
    // let xx = creativity?.map((value: any) => null);
    setCreativity([key]);
  };

  const playVideo = (url: string) => {
    window.open(url);
  };

  const validate = () => {
    for (let key in groupData) {
      if (groupData[key]?.creativity?.length === 0) {
        alert("Select media for each screens");
        return false;
      }
    }
    return true;
  };

  const handleMediasUploadOnAWS = async () => {
    if (validate()) {
      props.setLoading(true);
      let records = groupData;
      // for (let d in records) {
      //   for (let c of records[d].creativity) {
      //     try {
      //       const aws = await getAWSUrlToUploadFile(c?.file?.type);
      //       const successAWSUploadFile = await saveFileOnAWS(aws?.url, c.file);
      //       console.log("successfully uploaded on aws", successAWSUploadFile);
      //       c.awsURL = aws?.awsURL;
      //     } catch (error) {
      //       console.log("Error in uploading file aws : ", error);
      //     }
      //   }
      // }
      handleSaveDataOnLocalStorage(records);
    }
  };

  return (
    <div className="py-5">
      {/* <div className="flex flex-row justify-between border border-solid"> 
        <div className="w-full basis-3/4 flex flex-row justify-center items-center bg-blue-100 border border-solid">
          <h1 className="text-sm font-bold text-violet-500">Media files</h1>
        </div>
        <div
          className="basis-1/4 w-full border border-solid p-2 bg-indigo-500 flex flex-row items-center justify-center text-white cursor-pointer"
          // onClick={() => {
          //   setCreativity([...creativity, null]);
          // }}
        >
          +Add More Creative
        </div> 
      </div>*/}
      <div className="grid grid-cols-12 overflow-auto no-scrollbar">
        <table className="table-auto">
          <thead className="">
            <tr>
              <th>
                <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                  Total Screens
                </div>
              </th>
              <th>
                <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                  Duration
                </div>
              </th>
              <th>
                <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                  Resolution
                </div>
              </th>
              <th>
                <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                  Orientation
                </div>
              </th>
              <th>
                <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                  Size
                </div>
              </th>
              {/* <th className="flex flex-row">
                {creativity.map((_: any, i: number) => (
                  <div
                    className="w-64 flex flex-row justify-evenly items-center border border-solid p-2"
                    key={i}
                  >
                    <p>Upload creative - {i + 1}</p>
                    {creativity?.length > 1 && i !== 0 && (
                      <RiDeleteBin5Fill
                        color="red"
                        onClick={() => {
                          let x = creativity;
                          x = x.filter((_: any, index: number) => index !== i);
                          setCreativity(x);
                        }}
                      />
                    )}
                  </div>
                ))}
              </th> */}
            </tr>
          </thead>
          <tbody className="overflow-auto no-scrollbar">
            {Object.keys(groupData)?.map((sd: any, i: number) => (
              <tr key={i}>
                <td>
                  <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                    {getScreenByKey(sd)?.screens?.length}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                    {getScreenByKey(sd)?.duration}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                    {getScreenByKey(sd)?.resolution}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                    {getScreenByKey(sd)?.orientation}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row justify-center items-center border border-solid p-2 w-40">
                    {getScreenByKey(sd)?.size}
                  </div>
                </td>
                {/* <td className="flex flex-row">
                  {creativity.map((_: any, index: number) => (
                    <div key={index}>
                      {getScreenByKey(sd)?.creativity[index] == undefined ? (
                        <FileUploader
                          key={index}
                          handleFile={(file) => {
                            handleFileChange(file, sd);
                          }}
                        />
                      ) : (
                        <div
                          key={index}
                          className="flex flex-row justify-evenly items-center border border-solid py-2 px-5 w-64"
                        >
                          <p className="text-[green] font-semibold">Uploaded</p>
                          <FaPlayCircle
                            color="green"
                            onClick={() =>
                              playVideo(
                                getScreenByKey(sd)?.creativity[index].url
                              )
                            }
                          />
                          <RiDeleteBin5Fill
                            color="red"
                            onClick={() => {
                              let x = creativity;
                              x = x.filter(
                                (_: any, index: number) => index !== i
                              );
                              deleteSelectedFile(sd);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex py-5">
        <button
          type="button"
          id="mySubmit"
          // disabled={props?.loading}
          title="button"
          className="px-5 w-52 py-5 bg-proohYellow focus:outline-none hover:bg-gray-300 rounded-md"
          onClick={() => {
            // navigate("/create-campaign/upload-creatives")
            handleMediasUploadOnAWS();
          }}
        >
          <h1 className="text-sm text-black font-bold">Save And Continue</h1>
        </button>
      </div>
    </div>
  );
}
