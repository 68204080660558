import { formatNumber } from "../../../utils";

export function SingleScreen({ screen }: any) {
  // console.log(screen);
  return (
    <div className="flex flex-col border rounded">
      <img
        src={screen?.images[0]}
        alt="image"
        className="h-full w-full object-cover rounded"
      />
      <div className="p-1">
        <div className="text-base  font-bold">{screen?.screenName}</div>
        <div className="text-sm">{formatNumber(screen?.totalImpression)} impressions</div>
      </div>
    </div>
  );
}
