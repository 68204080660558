import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UploadMediasForScreens } from "../../components";
import { createCampaigns } from "../../actions/campaignAction";
import { CREATE_CAMPAIGNS_RESET } from "../../constants/campaignConstants";
import { getNumberOfDaysBetweenTwoDates } from "../../utils/dateAndTimeUtils";
import {
  AUDIENCES_FILTER_OPTIONS,
  CAMPAIGN_DETAILS,
  CREATIVES_DATA,
  CRICKET_TRIGGERS,
  FILTERED_SCREENS,
  WEATHER_TRIGGERS,
} from "../../constants/localStorageConstants";
import { getDataFromLocalStorage } from "../../utils/localStorageUtils";

type MapType = {
  [key: string]: string[];
};

export const MiddleArea: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [groupData, setGroupData] = useState<MapType>({});
  const [data, setData] = useState<any>([]); // filter screens list

  useEffect(() => {
    let screens = JSON.parse(
      window.localStorage.getItem(FILTERED_SCREENS) || "[]"
    );
    setData(screens);
  }, [navigate]);

  const campaignCreate = useSelector((state: any) => state.campaignCreate);
  const {
    loading: loadingCreateCampaign,
    error: errorCreteCampaign,
    success,
    data: message,
  } = campaignCreate;

  const resetLocalStorage = () => {
    console.log("resetLocalStorage called!");
    window.localStorage.removeItem(WEATHER_TRIGGERS);
    window.localStorage.removeItem(CRICKET_TRIGGERS);
    window.localStorage.removeItem(CAMPAIGN_DETAILS);
    window.localStorage.removeItem(AUDIENCES_FILTER_OPTIONS);
    window.localStorage.removeItem(FILTERED_SCREENS);
    window.localStorage.removeItem(CREATIVES_DATA);
  };

  useEffect(() => {
    if (success) {
      alert("Successfully created campaign");
      dispatch({ type: CREATE_CAMPAIGNS_RESET });
      setLoading(false);
      resetLocalStorage();
      navigate("/");
    } else if (errorCreteCampaign) {
      alert(`Error in campaign create! ${errorCreteCampaign}`);
      setLoading(false);
      dispatch({ type: CREATE_CAMPAIGNS_RESET });
    }
  }, [success, message, errorCreteCampaign]);

  const handleCreateCampaign = (data: any) => {
    console.log("handleCreateCampaign called! ");

    const campaignDetails = getDataFromLocalStorage(CAMPAIGN_DETAILS) || {};

    if (Object.keys(campaignDetails)?.length === 0) {
      alert("GO back and campaign details first");
      navigate("/create-campaign");
      return;
    }
    console.log("handleCreateCampaign campaignDetails: ", campaignDetails);

    const weatherTriggers = getDataFromLocalStorage(WEATHER_TRIGGERS) || [];

    // console.log("handleCreateCampaign weatherTriggers: ", weatherTriggers);
    const cricketTriggers = getDataFromLocalStorage(CRICKET_TRIGGERS) || [];

    // console.log("handleCreateCampaign cricketTriggers: ", cricketTriggers);
    let audiancesData = getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || [];

    audiancesData = audiancesData[0].selectedAudiences;
    const creativities = getDataFromLocalStorage(CREATIVES_DATA) || {};

    console.log("handleCreateCampaign creativities: ", creativities);

    dispatch(
      createCampaigns({
        ...campaignDetails,
        campaignBookedForDays: getNumberOfDaysBetweenTwoDates(
          campaignDetails.startDate,
          campaignDetails.endDate
        ),
        isDefaultCampaign: false,
        audiancesData: audiancesData,
        weatherTriggers,
        cricketTriggers,
        creativities,
      })
    );
  };

  const groupScreensByResolution = (screens: any[]) => {
    let obj: MapType = {};
    for (let screen of screens) {
      let resolution: string =
        `${screen?.screenResolution} ${screen?.slotLengthSeconds || 20}` || "";
      if (obj[resolution]) {
        obj[resolution].push(screen);
      } else {
        obj[resolution] = [screen];
      }
    }
    setGroupData(obj);
  };

  useEffect(() => {
    if (data?.length > 0) groupScreensByResolution(data);
  }, [data]);

  return (
    <div className="mt-6 w-full h-full py-5">
      <h1 className="text-3xl font-bold">Upload Bulk Creatives</h1>
      <div className="flex flex-row items-center gap-10">
        {(loading || loadingCreateCampaign) && (
          <h1 className="border border-1 bg-yellow-600 text-white text-lg px-8 py-2">
            Campaign is being created, please wait for some time
          </h1>
        )}
      </div>
      <div className="py-5">
        <div className="py-5 flex flex-row gap-4">
          <h1 className="text-xl font-semibold">
            Video or image, with or without audio{" "}
          </h1>
          <h2 className="text-xl font-semibold text-red-600">
            {data?.length} Screens Found
          </h2>
        </div>
        <div className="flex flex-col gap-4">
          <UploadMediasForScreens
            groupData={groupData}
            handleCreateCampaign={handleCreateCampaign}
            setLoading={setLoading}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
