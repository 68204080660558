import { Tabs } from "antd";
import { CampaignsTabs } from "./CampaignsTabs";
import { SingleScreenDetails } from "../SingleScreenDetails";

export const ScreenDetailsTabs = (props: any) => {
  const {
    setSelectedCampaigns,
    handleChangeStatus,
    loading,
    selectedCampaigns,
    screen,
    handleSetPriorityByOneClick,
  } = props;
  const items = [
    {
      label: `Campaigns`,
      key: "1",
      children: (
        <CampaignsTabs
          setSelectedCampaigns={setSelectedCampaigns}
          selectedCampaigns={selectedCampaigns}
          handleChangeStatus={handleChangeStatus}
          loading={loading}
          screenName={screen?.screenName}
          screen={screen}
          handleSetPriorityByOneClick={handleSetPriorityByOneClick}
        />
      ),
    },
    {
      label: `Screen Details`,
      key: "2",
      children: <SingleScreenDetails screen={screen} />,
    },
  ];

  return <Tabs defaultActiveKey="1" type="card" size={"large"} items={items} />;
};
