import { convertDataTimeToLocale } from "../../../utils/dateAndTimeUtils";
import * as FileSaver from "file-saver";
import { object } from "prop-types";
import XLSX from "sheetjs-style";

export function FileExporter({ excelData, fileName, wscols = [] }: any) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    try {
      const wscols1 = [
        {
          wch: 10,
        },
        {
          wch: 20,
        },
        {
          wch: 15,
        },
        {
          wch: 30,
        },
        {
          wch: 10,
        },
        {
          wch: 20,
        },
      ];

      var ws = XLSX.utils.aoa_to_sheet([
        [`For : ${fileName}`],
        [`Report generated at : ${convertDataTimeToLocale(new Date())}`],
      ]);
      ws["!cols"] = wscols1;
      ws["!merges"] = [XLSX.utils.decode_range("A1:D1")];

      XLSX.utils.sheet_add_json(ws, excelData, {
        skipHeader: false,
        origin: "A4", //ok -1
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      alert(`Error in exportToExcel function, ${error}`);
    }
  };

  return (
    <button
      onClick={exportToExcel}
      className="border border-1 px-4 py-1 hover:bg-sky-500 hover:text-white rounded-md"
    >
      Export file
    </button>
  );
}
