import {
  ADD_SCREEN_CODE_ERROR,
  ADD_SCREEN_CODE_REQUEST,
  ADD_SCREEN_CODE_RESET,
  ADD_SCREEN_CODE_SUCCESS,
  GET_ALL_SCREENS_BY_USERID_ERROR,
  GET_ALL_SCREENS_BY_USERID_REQUEST,
  GET_ALL_SCREENS_BY_USERID_RESET,
  GET_ALL_SCREENS_BY_USERID_SUCCESS,
  GET_ALL_SCREENS_DATA_ERROR,
  GET_ALL_SCREENS_DATA_REQUEST,
  GET_ALL_SCREENS_DATA_SUCCESS,
  GET_AUDIENCE_DATA_BY_COUNTRY_ERROR,
  GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST,
  GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS,
  GET_CRICKET_MATCHES_ERROR,
  GET_CRICKET_MATCHES_REQUEST,
  GET_CRICKET_MATCHES_SUCCESS,
  GET_FINAL_SUMMARY_DATA_ERROR,
  GET_FINAL_SUMMARY_DATA_REQUEST,
  GET_FINAL_SUMMARY_DATA_SUCCESS,
  GET_HOME_PAGE_COUNTS_ERROR,
  GET_HOME_PAGE_COUNTS_REQUEST,
  GET_HOME_PAGE_COUNTS_SUCCESS,
  GET_PRICING_DETAILS_DATA_ERROR,
  GET_PRICING_DETAILS_DATA_REQUEST,
  GET_PRICING_DETAILS_DATA_SUCCESS,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_RESET,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS,
  GET_SCREEN_BY_ID_ERROR,
  GET_SCREEN_BY_ID_REQUEST,
  GET_SCREEN_BY_ID_SUCCESS,
  GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR,
  GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST,
  GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS,
  GET_SCREEN_DATA_BY_AUDIENCES_ERROR,
  GET_SCREEN_DATA_BY_AUDIENCES_REQUEST,
  GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS,
  GET_SCREEN_DATA_BY_COUNTRY_ERROR,
  GET_SCREEN_DATA_BY_COUNTRY_REQUEST,
  GET_SCREEN_DATA_BY_COUNTRY_SUCCESS,
  GET_SCREEN_IDS_BY_COUNTRY_ERROR,
  GET_SCREEN_IDS_BY_COUNTRY_REQUEST,
  GET_SCREEN_IDS_BY_COUNTRY_SUCCESS,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS,
} from "../constants/screenDataConstant";

export function homePageCountsGetReducer(state = {}, action) {
  switch (action.type) {
    case GET_HOME_PAGE_COUNTS_REQUEST:
      return { loading: true };
    case GET_HOME_PAGE_COUNTS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_HOME_PAGE_COUNTS_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function allScreensDataByCountryGetReducer(state = {}, action) {
  switch (action.type) {
    case GET_SCREEN_DATA_BY_COUNTRY_REQUEST:
      return { loading: true };
    case GET_SCREEN_DATA_BY_COUNTRY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SCREEN_DATA_BY_COUNTRY_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function allScreenDataByAudiencesGetReducer(state = [], action) {
  switch (action.type) {
    case GET_SCREEN_DATA_BY_AUDIENCES_REQUEST:
      return { loading: true };
    case GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SCREEN_DATA_BY_AUDIENCES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function pricingDetailsDataGetReducer(state = [], action) {
  switch (action.type) {
    case GET_PRICING_DETAILS_DATA_REQUEST:
      return { loading: true };
    case GET_PRICING_DETAILS_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_PRICING_DETAILS_DATA_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function finalSummaryDataGetReducer(state = [], action) {
  switch (action.type) {
    case GET_FINAL_SUMMARY_DATA_REQUEST:
      return { loading: true };
    case GET_FINAL_SUMMARY_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_FINAL_SUMMARY_DATA_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function allAudienceDataByCountryGetReducer(state = [], action) {
  switch (action.type) {
    case GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST:
      return { loading: true };
    case GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_AUDIENCE_DATA_BY_COUNTRY_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function allScreensIdsByCountryGetReducer(state = [], action) {
  switch (action.type) {
    case GET_SCREEN_IDS_BY_COUNTRY_REQUEST:
      return { loading: true };
    case GET_SCREEN_IDS_BY_COUNTRY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SCREEN_IDS_BY_COUNTRY_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function allScreensDataByUserIdReducer(state = [], action) {
  switch (action.type) {
    case GET_ALL_SCREENS_BY_USERID_REQUEST:
      return { loading: true };
    case GET_ALL_SCREENS_BY_USERID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_ALL_SCREENS_BY_USERID_ERROR:
      return { loading: false, error: action.payload };
    case GET_ALL_SCREENS_BY_USERID_RESET:
      return {};
    default:
      return state;
  }
}

export function allScreensDataReducer(state = [], action) {
  switch (action.type) {
    case GET_ALL_SCREENS_DATA_REQUEST:
      return { loading: true };
    case GET_ALL_SCREENS_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_ALL_SCREENS_DATA_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function screenDetailReducer(state = {}, action) {
  switch (action.type) {
    case GET_SCREEN_BY_ID_REQUEST:
      return { loading: true };
    case GET_SCREEN_BY_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SCREEN_BY_ID_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function cricketMatchesListGetReducer(state = [], action) {
  switch (action.type) {
    case GET_CRICKET_MATCHES_REQUEST:
      return { loading: true };
    case GET_CRICKET_MATCHES_SUCCESS:
      return { loading: false, matches: action.payload };
    case GET_CRICKET_MATCHES_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
// getScreensDateFilterByScreenAudiences

export function screensDateFilterByScreenAudiencesReducer(state = {}, action) {
  switch (action.type) {
    case GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST:
      return { loading: true };
    case GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR:
      return { loading: false, error: action.payload };
    case GET_SCREENS_DATA_FILTER_BY_AUDIENCES_RESET:
      return {};
    default:
      return state;
  }
}

export function screenCampaignsByCodeGetReducer(state = {}, action) {
  switch (action.type) {
    case GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST:
      return { loading: true };
    case GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function screenLogsWithCampaignsGetReducer(state = {}, action) {
  switch (action.type) {
    case GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST:
      return { loading: true };
    case GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function addOrModifyScreenCodeReducer(state = {}, action) {
  switch (action.type) {
    case ADD_SCREEN_CODE_REQUEST:
      return { loading: true };
    case ADD_SCREEN_CODE_SUCCESS:
      return { loading: false, data: action.payload };
    case ADD_SCREEN_CODE_ERROR:
      return { loading: false, success: true, error: action.payload };
    case ADD_SCREEN_CODE_RESET:
      return {};
    default:
      return state;
  }
}
