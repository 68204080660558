import { FaPause, FaPlay } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { GrStatusPlaceholderSmall } from "react-icons/gr";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_HOLD,
  CAMPAIGN_STATUS_PAUSE,
} from "../../../constants/campaignConstants";
import { MediaContainer } from "../MediaContainer";
import { useSelector } from "react-redux";
import { USER_ROLE_PRIMARY } from "../../../constants/userConstants";

export const CampaignList = (props: any) => {
  const { campaign, handleChangeStatus } = props;
  // console.log("campaign : ", campaign);
  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;
  return (
    <div>
      <div className="flex flex-row- gap-4 py-2 text-xl font-bold">
        <h1 className="">Active campaigns :</h1>
        <h1>{campaign?.activeCampaigns?.length}</h1>
      </div>
      <div className="flex flex-col gap-2">
        {campaign?.activeCampaigns?.map((camp: any) => (
          <div
            key={camp?._id}
            className="border rounded flex flex-row justify-between"
          >
            <div className=" flex flex-col p-1">
              <MediaContainer
                className="rounded object-fill h-20 w-40"
                url={camp.videoURL}
                mediaType={camp.fileType?.split("/")[0]}
                // height="120px"
                // width="240px"
              />
              <h1 className="text font-semibold">{camp.name}</h1>
              <h1 className="text-sm font-semibold">
                Duration : {camp.duration} Second
              </h1>{" "}
              <h1 className="text-sm font-semibold">Brand: {camp.brandName}</h1>
              <div className="flex flex-row gap-2 text-sm font-semibold">
                <h1 className="text-sm">Loop:</h1>
                <h1 className="text-sm text-blue-800">
                  {camp.atIndex?.join(" , ")}
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-2 p-2">
              {userInfo?.userRole === USER_ROLE_PRIMARY && (
                <RiDeleteBin5Fill
                  title="delete campaign"
                  color="red"
                  onClick={() =>
                    handleChangeStatus(camp?._id, CAMPAIGN_STATUS_DELETED)
                  }
                />
              )}
              <FaPause
                title="pause campaign"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_PAUSE)
                }
              />
              {/* <GrStatusPlaceholderSmall
                title="hold campaign"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_HOLD)
                }
              />
              <FaPlay
                title="active campaign"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_ACTIVE)
                }
              /> */}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row- gap-4 py-2 text-xl font-bold">
        <h1>Pause campaigns :</h1>
        <h1>{campaign?.pauseCampaigns?.length}</h1>
      </div>
      <div>
        {campaign?.pauseCampaigns?.map((camp: any) => (
          <div
            key={camp?._id}
            className="border rounded flex flex-row justify-between"
          >
            <div className=" flex flex-col p-1">
              <MediaContainer
                className="rounded object-fill h-20 w-40"
                url={camp.videoURL}
                mediaType={camp.fileType?.split("/")[0]}
                // height="120px"
                // width="240px"
              />

              <h1 className="text font-semibold">{camp.name}</h1>
              <h1 className="text-sm font-semibold">
                Duration : {camp.duration} Second
              </h1>
              <h1 className="text-sm font-semibold">Brand: {camp.brandName}</h1>
              <div className="flex flex-row gap-2 text-sm font-semibold">
                <h1 className="text-sm">Loop:</h1>
                <h1 className="text-sm text-blue-800">
                  {camp.atIndex?.join(" , ")}
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-2 p-1">
              <RiDeleteBin5Fill
                title="delete campaign"
                color="red"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_DELETED)
                }
              />
              <FaPlay
                title="active campaign"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_ACTIVE)
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row- gap-4 py-2 text-xl font-bold">
        <h1>Hold campaigns:</h1>
        <h1>{campaign?.holdCampaigns?.length}</h1>
      </div>
      <div>
        {campaign?.holdCampaigns?.map((camp: any) => (
          <div
            key={camp?._id}
            className="border rounded flex flex-row justify-between"
          >
            <div className=" flex flex-col p-1">
              <MediaContainer
                className="rounded object-fill h-20 w-40"
                url={camp.videoURL}
                mediaType={camp.fileType?.split("/")[0]}
                // height="120px"
                // width="240px"
              />
              <h1 className="text font-semibold">{camp.name}</h1>
              <h1 className="text-sm font-semibold">
                Duration : {camp.duration} Second
              </h1>{" "}
              <h1 className="text-sm font-semibold">Brand: {camp.brandName}</h1>
              <div className="flex flex-row gap-2 text-sm font-semibold">
                <h1 className="text-sm">Loop:</h1>
                <h1 className="text-sm text-blue-800">
                  {camp.atIndex?.join(" , ")}
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-2 p-1">
              <RiDeleteBin5Fill
                title="delete campaign"
                color="red"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_DELETED)
                }
              />
              <FaPlay
                title="active campaign"
                onClick={() =>
                  handleChangeStatus(camp?._id, CAMPAIGN_STATUS_ACTIVE)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
