import { Skeleton } from "antd";
import { format } from "date-fns";

export const MyList = (props: any) => {
  const { campaignsTableData, loading } = props;

  const getData = () => {
    return Object.keys(campaignsTableData || {}) || [];
  };

  const getDate = (date: string) => {
    const myDate = new Date(date);
    return format(myDate, "dd/MM/yyyy");
  };

  return (
    <div className="py-4">
      {loading ? (
        <Skeleton />
      ) : getData()?.length > 0 ? (
        <div className="flex">
          <div className="flex flex-col">
            <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1 font-bold">
              Date
            </div>
            <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1 font-bold">
              Slots Booked
            </div>

            <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1 font-bold">
              Slot played
            </div>

            <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1 font-bold">
              Remaining slots
            </div>
          </div>
          <div className="flex overflow-scroll no-scrollbar">
            {getData()?.map((date: string, index: number) => (
              <div className="flex flex-col" key={index}>
                <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1 font-bold">
                  {getDate(date)}
                </div>
                <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1">
                  {campaignsTableData[date]?.totalSlotBooked || "N/A"}
                </div>{" "}
                <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1">
                  {campaignsTableData[date]?.totalSlotsPlayed}
                </div>{" "}
                <div className="flex items-center justify-center px-4 py-2 w-48 h-8 color-#00000 border border-1">
                  {campaignsTableData[date]?.totalSlotBooked -
                    campaignsTableData[date]?.totalSlotsPlayed >
                  0
                    ? campaignsTableData[date]?.totalSlotBooked -
                      campaignsTableData[date]?.totalSlotsPlayed
                    : "N/A"}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1 className="border border-1 p-4 bg-yellow-300">
          No logs data found!
        </h1>
      )}
    </div>
  );
};
