import axios from "axios";
import {
  ADD_SCREEN_CODE_ERROR,
  ADD_SCREEN_CODE_REQUEST,
  ADD_SCREEN_CODE_SUCCESS,
  GET_ALL_SCREENS_BY_USERID_ERROR,
  GET_ALL_SCREENS_BY_USERID_REQUEST,
  GET_ALL_SCREENS_BY_USERID_SUCCESS,
  GET_ALL_SCREENS_DATA_ERROR,
  GET_ALL_SCREENS_DATA_REQUEST,
  GET_ALL_SCREENS_DATA_SUCCESS,
  GET_AUDIENCE_DATA_BY_COUNTRY_ERROR,
  GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST,
  GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS,
  GET_CRICKET_MATCHES_ERROR,
  GET_CRICKET_MATCHES_REQUEST,
  GET_CRICKET_MATCHES_SUCCESS,
  GET_FINAL_SUMMARY_DATA_ERROR,
  GET_FINAL_SUMMARY_DATA_REQUEST,
  GET_FINAL_SUMMARY_DATA_SUCCESS,
  GET_HOME_PAGE_COUNTS_ERROR,
  GET_HOME_PAGE_COUNTS_REQUEST,
  GET_HOME_PAGE_COUNTS_SUCCESS,
  GET_PRICING_DETAILS_DATA_ERROR,
  GET_PRICING_DETAILS_DATA_REQUEST,
  GET_PRICING_DETAILS_DATA_SUCCESS,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST,
  GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS,
  GET_SCREEN_BY_ID_ERROR,
  GET_SCREEN_BY_ID_REQUEST,
  GET_SCREEN_BY_ID_SUCCESS,
  GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR,
  GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST,
  GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS,
  GET_SCREEN_DATA_BY_AUDIENCES_ERROR,
  GET_SCREEN_DATA_BY_AUDIENCES_REQUEST,
  GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS,
  GET_SCREEN_DATA_BY_COUNTRY_ERROR,
  GET_SCREEN_DATA_BY_COUNTRY_REQUEST,
  GET_SCREEN_DATA_BY_COUNTRY_SUCCESS,
  GET_SCREEN_IDS_BY_COUNTRY_ERROR,
  GET_SCREEN_IDS_BY_COUNTRY_REQUEST,
  GET_SCREEN_IDS_BY_COUNTRY_SUCCESS,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST,
  GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS,
} from "../constants/screenDataConstant";

const url = `${process.env.REACT_APP_PROOH_SERVER}/api/v1/screens`;

export const getHomePageCounts = () => async (dispatch, getState) => {
  dispatch({
    type: GET_HOME_PAGE_COUNTS_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/homePageCounts`);
    dispatch({
      type: GET_HOME_PAGE_COUNTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOME_PAGE_COUNTS_ERROR,
      payload: error,
    });
  }
};

export const getAllScreens = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_SCREENS_DATA_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/all`);
    dispatch({
      type: GET_ALL_SCREENS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SCREENS_DATA_ERROR,
      payload: error,
    });
  }
};

export const getAllScreensByUserId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_SCREENS_BY_USERID_REQUEST,
  });
  try {
    const {
      auth: { userInfo },
    } = getState();

    const { data } = await axios.get(`${url}/all/user`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({
      type: GET_ALL_SCREENS_BY_USERID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SCREENS_BY_USERID_ERROR,
      payload: error,
    });
  }
};

export const getAudienceDataByCountry = () => async (dispatch, getState) => {
  dispatch({
    type: GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/audianceData`);
    dispatch({
      type: GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AUDIENCE_DATA_BY_COUNTRY_ERROR,
      payload: error,
    });
  }
};

export const getScreenDataByCountry = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SCREEN_DATA_BY_COUNTRY_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/homeTableData`);
    dispatch({
      type: GET_SCREEN_DATA_BY_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCREEN_DATA_BY_COUNTRY_ERROR,
      payload: error,
    });
  }
};

export const getScreenDataByAudiences = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SCREEN_DATA_BY_AUDIENCES_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/audienceTableData`);
    dispatch({
      type: GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCREEN_DATA_BY_AUDIENCES_ERROR,
      payload: error,
    });
  }
};

export const getPricingDetailsTableData =
  (body) => async (dispatch, getState) => {
    dispatch({
      type: GET_PRICING_DETAILS_DATA_REQUEST,
      payload: body,
    });
    try {
      const { data } = await axios.post(`${url}/pricingDetailTable`, body);
      dispatch({
        type: GET_PRICING_DETAILS_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRICING_DETAILS_DATA_ERROR,
        payload: error,
      });
    }
  };

export const getFinalSummaryTableData =
  (body) => async (dispatch, getState) => {
    dispatch({
      type: GET_FINAL_SUMMARY_DATA_REQUEST,
      payload: body,
    });
    try {
      const { data } = await axios.post(`${url}/finalSummaryTable`, body);
      dispatch({
        type: GET_FINAL_SUMMARY_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FINAL_SUMMARY_DATA_ERROR,
        payload: error,
      });
    }
  };

export const getScreenIdsGroupByCountry = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SCREEN_IDS_BY_COUNTRY_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/getScreenIdsGroupByCountries`);
    dispatch({
      type: GET_SCREEN_IDS_BY_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCREEN_IDS_BY_COUNTRY_ERROR,
      payload: error,
    });
  }
};

export const getCricketMatchesLists = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CRICKET_MATCHES_REQUEST,
  });

  try {
    const { data } = await axios.get(`${url}/cricketMatchesList`);
    dispatch({
      type: GET_CRICKET_MATCHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CRICKET_MATCHES_ERROR,
      payload: error,
    });
  }
};

export const getScreensDateFilterByScreenAudiences =
  (requestBody) => async (dispatch, getState) => {
    dispatch({
      type: GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST,
    });
    try {
      const { data } = await axios.post(`${url}/filterMapData`, requestBody);
      dispatch({
        type: GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR,
        payload: error,
      });
    }
  };

export const getScreenDetailById = (screenId) => async (dispatch, getState) => {
  dispatch({
    type: GET_SCREEN_BY_ID_REQUEST,
  });
  try {
    const { data } = await axios.get(`${url}/getScreen?screenId=${screenId}`);
    dispatch({
      type: GET_SCREEN_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCREEN_BY_ID_ERROR,
      payload: error,
    });
  }
};

export const getScreenCampaignsByCode =
  (code) => async (dispatch, getState) => {
    dispatch({
      type: GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST,
      payload: code,
    });
    try {
      const { data } = await axios.get(`${url}/syncScreenCode`, {
        params: {
          syncCode: code,
        },
      });
      dispatch({
        type: GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR,
        payload: error,
      });
    }
  };

export const addOrModifyScreenCode =
  (requestBody) => async (dispatch, getState) => {
    dispatch({
      type: ADD_SCREEN_CODE_REQUEST,
      payload: requestBody,
    });
    try {
      const {
        auth: { userInfo },
      } = getState();
      const { data } = await axios.post(
        `${url}/addOrModifyScreenCode`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: ADD_SCREEN_CODE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_SCREEN_CODE_ERROR,
        payload: error,
      });
    }
  };
export const getScreenLogsWithCampaigns =
  ({ screenId, start, limit }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST,
      payload: screenId,
    });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PROOH_SERVER}/api/v1/analytics/getScreenlogs?screenId=${screenId}&start=${start}&limit=${limit}`,
        {
          params: { screenId },
        }
      );
      dispatch({
        type: GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR,
        payload: error,
      });
    }
  };
