import { useNavigate } from "react-router-dom";
import { ADD_NEW_USER, SIGNIN } from "../../routes/routes";
import {
  MASTER_USER_ROLE,
  USER_DELETE_RESET,
  USER_ROLE_PRIMARY,
  USER_ROLE_SECONDARY,
} from "../../constants/userConstants";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { userInfo } from "os";
import { useDispatch } from "react-redux";
import { deleteUser, getUserList } from "../../actions/userActions";
import { Loading, Message } from "../../components";

export const MyUsers = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const userList = useSelector((state: any) => state.userList);
  const { loading, error, data: users } = userList;

  const userDelete = useSelector((state: any) => state.userDelete);
  const {
    loading: loadingUserDelete,
    error: errorUserDelete,
    success,
    data: userDeleteMessage,
  } = userDelete;

  useEffect(() => {
    if (success) {
      alert(userDeleteMessage);
      dispatch({ type: USER_DELETE_RESET });
      dispatch(getUserList());
    }
    if (errorUserDelete) {
      alert(errorUserDelete);
      dispatch({ type: USER_DELETE_RESET });
    }
  }, [errorUserDelete, errorUserDelete]);

  const handleDeleteUser = (userId: string) => {
    dispatch(deleteUser(userId));
  };

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  // console.log("user role : ", userInfo);

  useEffect(() => {
    if (!userInfo) {
      navigate(SIGNIN);
    } else {
      if (userInfo?.userRole !== USER_ROLE_PRIMARY) {
        alert("You have no access to this page");
        navigate(-1);
      } else {
        dispatch(getUserList());
      }
    }
  }, [userInfo]);

  return (
    <div className="p-8">
      <div>
        <button
          title="Create new user"
          className="w-36 h-10 border border-solid px-4 py-2 bg-sky-500/75 rounded-md text-white"
          onClick={() => navigate(ADD_NEW_USER)}
        >
          <div className="flex flex-row justify-center gap-2">
            <h1>+</h1>
            <h1>New User</h1>
          </div>
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message />
      ) : (
        <div className="p-8">
          <table className="auto ">
            <thead>
              <tr>
                <th className="border W-16">Sl. No</th>
                <th className="border w-32">Name</th>
                <th className="border w-56">Email</th>
                <th className="border w-32">User Role</th>
                <th className="border w-32">Actions</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {users?.map((user: any, i: any) => (
                <tr className="" key={i}>
                  <td className="border W-12">{i + 1}</td>
                  <td className="border W-32">{user?.name}</td>
                  <td className="border W-56">{user.email}</td>
                  <td className="border W-32">{user?.userRole}</td>
                  <td className="border W-32">
                    <div className="flex flex-row">
                      {user.userRole === USER_ROLE_PRIMARY ? null : (
                        <RiDeleteBin5Fill
                          title="delete user"
                          color="red"
                          onClick={() => handleDeleteUser(user?._id)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
