
import { MdCheck, MdClose } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";

export const CombButtons = (props: any) => {
  const {userInfo, detailsView, campaigns, status } = props;
  console.log(props);
//   console.log(campaigns.filter((c: any) => c.campaignWithMultipleMediasWithMultipleScreens === detailsView && c.status
//  === "PleaRequestBudgetSent")) ;

//  console.log(campaigns?.filter(
//   (c: any) => c.status === "PleaRequestBudgetAccepted" &&
//     c.secondaryCampaignManager === userInfo._id &&
//     c.campaignWithMultipleMediasWithMultipleScreens === detailsView
//   ));

  const handleAll = (response: any, plea: any) => {
    // budget
    if (plea === "budget") {
      const approveForCampaigns = campaigns?.filter(
        (c: any) => c.status === "PleaRequestBudgetSent" &&
        c.primaryCampaignManger === userInfo._id &&
        c.campaignWithMultipleMediasWithMultipleScreens === detailsView
      );
      approveForCampaigns?.map((c: any) => {
        props?.handleSendPleaResponse({
          type: "BudgetPlea",
          response: response,
          campaignId: c._id,
          senderEmail: c.primaryCampaignMangerEmail,
          receiverEmail: c.secondaryCampaignManagerEmail
        })

      });
    }
    // screen
    if (plea === "screen") {
      // request
      if (response === "request") {
        const approveForCampaigns = campaigns?.filter(
          (c: any) => c.status === "PleaRequestBudgetAccepted" &&
          c.secondaryCampaignManager === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        );
        console.log(approveForCampaigns);
        approveForCampaigns?.map((c: any) => {
          props?.handleSendPleaRequest({
            type: "ScreenPlea",
            campaignIds: [c._id],
            senderEmail: c.secondaryCampaignManagerEmail,
            receiverEmail: c.primaryScreenOwnerEmail
          })
        });

      } else {
        // response
        const approveForCampaigns = campaigns?.filter(
          (c: any) => c.status === "PleaRequestScreenApprovalSent" &&
          c.primaryScreenOwner === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        );
        console.log(approveForCampaigns);

        approveForCampaigns?.map((c: any) => {
          props?.handleSendPleaResponse({
            type: "ScreenPlea",
            response: response,
            campaignId: c._id,
            senderEmail: c.primaryScreenOwnerEmail,
            receiverEmail: c.secondaryCampaignManagerEmail
          })
        });
      }

    }

    // final
    if (plea === "final") {
      // request
      if (response === "request") {
        const approveForCampaigns = campaigns?.filter(
          (c: any) => c.status === "PleaRequestScreenApprovalAccepted" &&
          c.secondaryCampaignManager === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView &&
          c.historyForMediaChange.length !== 0
        );
        console.log(approveForCampaigns);
        approveForCampaigns?.map((c: any) => {
          props?.handleSendPleaRequest({
            type: "FinalPlea",
            campaignIds: [c._id],
            senderEmail: c.secondaryCampaignManagerEmail,
            receiverEmail: c.primaryScreenOwnerEmail
          })
        });
        
      } else {
        // response
        const approveForCampaigns = campaigns?.filter(
          (c: any) => c.status === "PleaRequestApprovalSent" &&
          c.primaryScreenOwner === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView &&
          c.historyForMediaChange.length !== 0
        );
        console.log(approveForCampaigns);
        
        approveForCampaigns?.map((c: any) => {
          props?.handleSendPleaResponse({
            type: "FinalPlea",
            response: response,
            campaignId: c._id,
            senderEmail: c.primaryScreenOwnerEmail,
            receiverEmail: c.secondaryCampaignManagerEmail
          })
        });
      }
    }
  }

  return (
    <div className="">
      {/* Budget Approval Response*/}
      {campaigns?.filter(
        (c: any) => c.status === "PleaRequestBudgetSent" &&
          c.primaryCampaignManger === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        ).length > 0 &&
        userInfo?.isBrand &&
        userInfo?.userRole === "primary" &&
      (
        <div className="flex justify-end gap-2 py-5">
          <button
            className="border border-green-700 text-green-700 flex justify-around items-center rounded p-2"
            type="button"
            title="accept"
            onClick={() => {
              console.log("Accept");
              handleAll("accepted", "budget");
            }}
          >
            <MdCheck color="green" size="20px" />
            <h1 className="px-2">
              Approve Budget For All
            </h1>
          </button>
          <button
            className="border border-red-600 text-red-600 flex justify-around items-center rounded p-2"
            title="reject"
            type="button"
            onClick={() => {
              console.log("Reject");
              handleAll("rejected", "budget");
            }}
          >
            <MdClose color="red" size="20px" />
            <h1 className="px-2">
              Reject Budget For All
            </h1>
          </button>
        </div>
      )}
      {/* Screens Approval Request */}
      {campaigns?.filter(
        (c: any) => c.status === "PleaRequestBudgetAccepted" &&
          // c.historyForMediaChange.length > 0 &&
          c.secondaryCampaignManager === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        ).length > 0 &&
        userInfo?.isBrand &&
        userInfo?.userRole === "secondary" &&
      (
        <div className="flex justify-end gap-2 py-5">
          <button
            className="border border-blue-700 text-blue-700 flex justify-around items-center rounded p-2"
            type="button"
            title="accept"
            onClick={() => {
              console.log("request for approval");
              handleAll("request", "screen");
            }}
          >
            <BiMailSend color="blue" size="20px" /> 
            <h1 className="px-2">
              Send For All Screens Approval             
            </h1>
          </button>
        </div>
      )}
      {/* Screens Approval Response */}
      {campaigns?.filter(
        (c: any) => c.status === "PleaRequestScreenApprovalSent" &&
          c.primaryScreenOwner === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        ).length > 0 &&
        userInfo?.isMaster &&
        userInfo?.userRole === "primary" &&
      (
        <div className="flex justify-end gap-2 py-5">
          <button
            className="border border-green-700 text-green-700 flex justify-around items-center rounded p-2"
            type="button"
            title="accept"
            onClick={() => {
              console.log("Accept");
              handleAll("accepted", "screen");
            }}
          >
            <MdCheck color="green" size="20px" />
            <h1 className="px-2">
              Approve Screen For All
            </h1>
          </button>
          <button
            className="border border-red-600 text-red-600 flex justify-around items-center rounded p-2"
            title="reject"
            type="button"
            onClick={() => {
              console.log("Reject");
              handleAll("rejected", "screen");
            }}
          >
            <MdClose color="red" size="20px" />
            <h1 className="px-2">
              Reject Screen For All
            </h1>
          </button>
        </div>
      )}
      {/* Final Approval Request */}
      {campaigns?.filter(
        (c: any) => c.status === "PleaRequestScreenApprovalAccepted" &&
          c.historyForMediaChange.length > 0 &&
          c.secondaryCampaignManager === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        ).length > 0 &&
        userInfo?.isBrand &&
        userInfo?.userRole === "secondary" &&
      (
        <div className="flex justify-end gap-2 py-5">
          <button
            className="border border-blue-700 text-blue-700 flex justify-around items-center rounded p-2"
            type="button"
            title="accept"
            onClick={() => {
              console.log("request for approval");
              handleAll("request", "final");
            }}
          >
            <BiMailSend color="blue" size="20px" /> 
            <h1 className="px-2">
              Send All For Campaign Approval              
            </h1>
          </button>
        </div>
      )}
      {/* Final Approval Response */}
      {campaigns?.filter(
        (c: any) => c.status === "PleaRequestFinalApprovalSent" &&
          c.historyForMediaChange.length > 0 &&
          c.primaryScreenOwner === userInfo._id &&
          c.campaignWithMultipleMediasWithMultipleScreens === detailsView
        ).length > 0 &&

        userInfo?.isMaster &&
        userInfo?.userRole === "primary" &&
      (
        <div className="flex justify-end gap-2 py-5">
          <button
            className="border border-green-700 text-green-700 flex justify-around items-center rounded p-2"
            type="button"
            title="accept"
            onClick={() => {
              console.log("Accept");
              handleAll("accepted", "final");
            }}
          >
            <MdCheck color="green" size="20px" />
            <h1 className="px-2">
              Approve Campaigns For All
            </h1>
          </button>
          <button
            className="border border-red-600 text-red-600 flex justify-around items-center rounded p-2"
            title="reject"
            type="button"
            onClick={() => {
              console.log("Reject");
              handleAll("rejected", "final");
            }}
          >
            <MdClose color="red" size="20px" />
            <h1 className="px-2">
              Reject Campaigns For All
            </h1>
          </button>
        </div>
      )}
    </div>
  )
}