import React from 'react';

interface IProps {
  children?: React.ReactNode;
}

export const HomeText: React.FC<IProps> = (props) => {
  const { children } = props;
  return (
    <div className="p-1">
      <div className="py-10">
        <h3 className="text-md font-semibold">IMPRESSIONS</h3>
        <h1 className="text-4xl font-semibold py-2">WE SELL DOOH ON <strong className='text-proohCyanFooter font-bold'>IMPRESSIONS</strong> ONLY.<br/>NO FIXED RENT</h1>
        <p className="text-sm">
          PROOH’s Media Planning Tool uses Geospatial Data, POI Data, Govt. Traffic Data amongst other Data sets to determine<br/>
          Total Count of people at specific locations and further classifies the Total count into Custom Audience impressions<br/>
          enabling brands to select the Locations with Highest Target Audience Penetration. Each such location has<br/>
          representation of  DOOH options, LTS scoring, site level historical data and Supplier–Direct Pricing. The creatives are<br/>
          deployed on DOOH screens through programmatic SSP for DOOH. The actual payment for the adopted Media Site is based on<br/>
          measurement of prediction vs. actual impressions for actual campaign days. The entire flow of the campaign performance<br/>
          from planning, deployment & validation of impressions is displayed live! on a Dashboard accessible by Clients & media owners.
        </p>
      </div>
      <div className="py-10">
        <h3 className="text-md font-semibold">TARGETING COHORTS</h3>
        <h1 className="text-4xl font-semibold py-2">WE MINIMIZE AD-SPEND WASTAGE BY<br/> <strong className='text-proohCyanFooter font-bold'>TARGETTING COHORTS</strong> BY LOCATION AND<br/>TIME OF THE DAY</h1>
        <p className="text-sm">
          On-the-Go audiences tend to follow daily routines faithfully. The collection, processing and analysis of SDK data enables<br/>
          attribution of a mobile device ID on POI’s and Locations (Road stretches and indoor ecosystems) along with the time of visit. This<br/>
          helps formation of various audience segments and COHORTS based on their Spatio-temporal dynamics of audience mobility and<br/>
          preferences which enables targeted ad-content planning determining where, when and how an ad will have the most impact.<br/>
          Making cohorts of Audience segments with certain interests and targeting their frequented locations at their predicted time of<br/>
          visit is the PROOH’s promise of delivery to marketers. In contrast to one-to-one advertising, PROOH tracks consumer behaviours<br/>
          on a large scale without resting on any personal identifiers and uses modelling of probabilistic, historical spatial telco data for<br/>
          identification of locations that are relevant to target audience personas.
        </p>
      </div>
      <div className="py-10">
        <h3 className="text-md font-semibold">AUDIENCE MEASUREMENT</h3>
        <h1 className="text-4xl font-semibold py-2">WE BRING ADVANCED <strong className='text-proohCyanFooter font-bold'>AUDIENCE<br/>MEASUREMENT</strong> THROUGH MOBILE SDK<br/> GEOSPATIAL DATA</h1>
        <p className="text-sm">
          Prooh’s Advanced Audience Measurement Platform is composed of Computer Vision and uses a HD IP Camera at the site<br/>
          level for recording live scene analysis pointing atthe intended direction capturing data within a defined “audience Contact<br/>
          Zone” or “field of view” to calculate real time impressions. Camera Observation at site level helps supervise local instances<br/>
          and collects audience data for real-time  measurement of precise audience impressions from vehicle and pedestrians with<br/>
          processing of Dwell time for vehicles & bodies and attention time for watchers. processing of Dwell time for vehicles &<br/>
          bodies and attention time for watchers.  Conversion of vehicles into impressions are based on their type (car, SUV/truck, van,<br/>
          bus) and adjustable passenger per vehicle stats with gender and absolute age classification for pedestrians. Vehicles are<br/>
          further analysed by type and color, and  watchers by demographics and face contact (attention time). Results are<br/>
          automatically aggregated and brought forward on our campaign reporting dashboard for campaign impression validation.<br/>
          Through Audience measurement, ooh and DOOH sites have proven metrics and implementation architecture for targeting<br/>
          much like the other digital medias.
        </p>
      </div>
    </div>
  );
};
