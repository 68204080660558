export const defaultPoi = [
  {
    name: "Airport",
    value: "airport",
  },
  {
    name: "ATM",
    value: "atm",
  },
  {
    name: "Auto Parts Store",
    value: "auto_parts_store"
  },
  {
    name: "Bakery",
    value: "bakery"
  },
  {
    name:"Bank",
    value: "bank"
  },
  {
    name: "Banquet",
    value: "banquet_hall"
  },
  {
    name: "Bar",
    value: "bar"
  },
  {
    name: "Barbeque Restaurant",
    value: "barbeque_restaurant"
  },
  {
    name: "Barber",
    value: "barber_shop"
  },
  {
    name: "Salon",
    value: "beauty_salon"

  },
  {
    name: "Bicycle Store",
    value: "bicycle_store"
  },
  {
    name: "Book Store",
    value: "book_store"
  },
  {
    name: "Bus Station",
    value: "bus_station"
  },
  {
    name: "Bus Stand",
    value: "bus_stop"
  },
  {
    name: "Cafe",
    value: "cafe"
  },
  {
    name: "Car Dealer",
    value: "car_dealer"

  },
  {
    name: "Car Repair",
    value: "car_repair"
  },
  {
    name: "Car Wash",
    value: "car_wash"
  },
  {
    name: "Mobile Store",
    value: "cell_phone_store"
  },
  {
    name: "Cemetery",
    value: "cemetery" 
  },
  {
    name: "Chinese Restaurant",
    value: "chinese_restaurant"
  },
  {
    name: "Church",
    value: "church"
  },
  {
    name: "City Hall",
    value: "city_hall"
  },
  {
    name: "Clohing Store",
    value: "clothing_store"
  },
  {
    name: "Coffee Shop",
    value: "coffee_shop"
  },
  {
    name: "Community Center",
    value: "community_center"
  },
  {
    name: "Consultant",
    value: "consultant"
  },
  {
    name: "Convenience Store",
    value: "convenience_store"
  },
  {
    name: "Convention Center",
    value: "convention_center"
  },
  {
    name: "Courier Service",
    value: "courier_service"
  },
  {
    name: "Court",
    value: "courthouse"
  },
  {
    name: "Dental Clinic",
    value: "dental_clinic"
  },
  {
    name: "Dentist",
    value: "dentist"
  },
  {
    name: "Department Store",
    value: "department_store"
  },
  {
    name: "Discount Store",
    value: "discount_store"
  },
  {
    name: "Doctor",
    value: "doctor"
  },
  {
    name: "Medical Store",
    value: "drugstore"
  },
  {
    name: "EV Charging Station",
    value: "electric_vehicle_charging_station"
  },
  {
    name: "Electrician",
    value: "electrician"
  },
  {
    name: "Electronic Store",
    value: "electronics_store"
  },
  {
    name: "Embassy",
    value: "embassy"
  },
  {
    name: "Event Venue",
    value: "event_venue"
  },
  {
    name: "Fast Food Restaurant",
    value: "fast_food_restaurant"
  },
  {
    name: "Fitness Center",
    value: "fitness_center"
  },
  {
    name: "Flower Shop",
    value: "florists"
  },
  {
    name: "Furniture Store",
    value: "furniture_store"
  },
  {
    name: "Gas Station",
    value: "gas_station"
  },
  {
    name: "Gift Shop",
    value: "gift_shop"
  },
  {
    name: "Golf Course",
    value: "golf_course"
  },
  {
    name: "Grocery Store",
    value: "grocery_store"
  },
  {
    name: "Guest House",
    value: "guest_house"
  },
  {
    name: "Gym",
    value: "gym"
  },
  {
    name: "Hair Care",
    value: "hair_care"
  },
  {
    name: "Beauty Parlour",
    value: "hair_salon"
  },
  {
    name: "Hardware Store",
    value: "hardware_store"
  },
  {
    name: "Temple",
    value: "hindu_temple"
  },
  {
    name: "Historical Landmark",
    value: "historical_landmark"
  },
  {
    name: "Home Goods Store",
    value: "home_goods_store"
  },
  {
    name: "Home Improvement Store",
    value: "home_improvement_store"
  },
  {
    name: "Hospital",
    value: "hospital"
  },
  {
    name: "Hostel",
    value: "hostel"
  },
  {
    name: "Hotel",
    value: "hotel"
  },
  {
    name: "Ice Cream Shop",
    value: "ice_cream_shop"
  },
  {
    name: "Indian Restaurant",
    value: "indian_restaurant"
  },
  {
    name: "Insurance Agency",
    value: "insurance_agency"
  },
  {
    name: "Jewelry Store",
    value: "jewelry_store"
  },
  {
    name: "Korean Restaurant",
    value: "korean_restaurant"
  },
  {
    name: "Laundry",
    value: "laundry"
  },
  {
    name: "Lawyer",
    value: "lawyer"
  },
  {
    name: "Library",
    value: "library"
  },
  {
    name: "Liquor Store",
    value: "liquor_store"
  },
  {
    name: "Government Office",
    value: "local_government_office"
  },
  {
    name: "Locality",
    value: "locality"
  },
  {
    name: "Lodging",
    value: "lodging"
  },
  {
    name: "Market",
    value: "market"
  },
  {
    name: "Pathology La",
    value: "medical_lab"
  },
  {
    name: "Mosque",
    value: "mosque"
  },
  {
    name: "Motel",
    value: "motel"
  },
  {
    name: "Movie Theater",
    value: "movie_theater"
  },
  {
    name: "Night Club",
    value: "night_club"
  },
  {
    name: "Park",
    value: "park"
  },
  {
    name: "Parking",
    value: "parking"
  },
  {
    name: "Pet Store",
    value: "pet_store"
  },
  {
    name: "Phamacy",
    value: "pharmacy"
  },
  {
    name: "Physiotherapist",
    value: "physiotherapist"
  },
  {
    name: "Pizza Restaurant",
    value: "pizza_restaurant"
  },
  {
    name: "Playground",
    value: "playground"
  },
  {
    name: "Police Station",
    value: "police"
  },
  {
    name: "Post Office",
    value: "post_office"
  },
  {
    name: "Postal Code",
    value: "postal_code"
  },
  {
    name: "PreSchool",
    value: "Preschool"
  },
  {
    name: "Primary School",
    value: "primary_school"
  },
  {
    name: "Private Guest Room",
    value: "private_guest_room"
  },
  {
    name: "Real Estate Agency",
    value: "real_estate_agency"
  },
  {
    name: "Resort Hotel",
    value: "resort_hotel"
  },
  {
    name: "Rest Stop",
    value: "rest_stop"
  },
  {
    name: "Restaurant",
    value: "restaurant"
  },
  {
    name: "School",
    value: "school"
  },
  {
    name: "School District",
    value: "school_district"
  },
  {
    name: "Secondary School",
    value: "secondary_School"
  },
  {
    name: "Shoppoing Mall",
    value: "shopping_mall"
  },
  {
    name: "Shoe Store",
    value: "shoe_store"
  },
  {
    name: "Spa",
    value: "spa"
  },
  {
    name: "Sporting Goods Store",
    value: "sporting_goods_store"
  },
  {
    name: "Sports Club",
    value: "sports_club"
  },
  {
    name: "Sports Complex",
    value: "sports_complex"
  },
  {
    name: "Stadium",
    value: "stadium"
  },
  {
    name: "Store",
    value: "store"
  },
  {
    name: "Subway Station",
    value: "subway_station"
  },
  {
    name: "Super Market",
    value: "supermarket"
  },
  {
    name: "Swimming Pool",
    value: "swimming_pool"
  },
  {
    name: "Tailor",
    value: "tailor"
  },
  {
    name: "Taxi Stand",
    value: "taxi_stand"
  },
  {
    name: "Telecom Service Provider",
    value: "telecommunications_service_provider"
  },
  {
    name: "Tourist Place",
    value: "tourist_attraction"
  },
  {
    name: "Railway Station",
    value: "train_station"
  },
  {
    name: "Transit Depot",
    value: "transit_depot"
  },
  {
    name: "Transit Station",
    value: "transit_station"
  },
  {
    name: "Travel Agency",
    value: "travel_agency"
  },
  {
    name: "University",
    value: "university"
  },
  {
    name: "Vegetarian Restaurant",
    value: "vegetarian_restaurant"
  },
  {
    name: "Veterinary Care",
    value: "veterinary_care"
  },
  {
    name: "Wedding Venue",
    value: "wedding_venue"
  },
  {
    name: "Wholesale Market",
    value: "wholesale"
  },
];