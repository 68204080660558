
import { convertDataTimeToLocale, getNumberOfDaysBetweenTwoDates } from "../../utils/dateAndTimeUtils";

export const CampaignPleaTable = (props: any) => {
  // console.log(props);
  const { campaigns, setDetailsView } = props;
  return (
    <div className="w-full border">
      <table className="table-fixed w-full">
        <thead className="w-full">
          <tr className="flex flex-row w-full bg-gray-200 py-1">
            <th className="w-full flex border-r border-gray-400 justify-center items-center">
              Campaign Name
            </th>
            <th className="w-full flex border-x border-gray-400 justify-center items-center">
              Brand Name
            </th>
            <th className="w-full flex border-x border-gray-400 justify-center items-center">
              Start Date
            </th>
            <th className="w-full flex border-x border-gray-400 justify-center items-center">
              End Date
            </th>
            <th className="w-full flex border-x border-gray-400 justify-center items-center">
              Duration
            </th>
            <th className="w-full flex border-x border-gray-400 justify-center items-center">
              Budget
            </th>
            <th className="w-full flex border-l border-gray-400 justify-center items-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="w-full">
          {campaigns?.map((c: any, i: any) => (
            <tr
              className="w-full flex flex-row border cursor-pointer p-1"
              key={i}
              onClick={() => {
                console.log("props");
                setDetailsView(c.campaignWithMultipleMediasWithMultipleScreens);
              }}   
            >
              <td className="w-full flex justify-start items-center text-sm">
                {c.name.split("_").splice(0)[0]}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                {c.brandName}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                {convertDataTimeToLocale(c.startDate)}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                {convertDataTimeToLocale(c.endDate)}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                {getNumberOfDaysBetweenTwoDates(c.startDate, c.endDate)}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                INR {c.totalAmount.toFixed(0)}
              </td>
              <td className="w-full flex justify-center items-center text-sm">
                <div className="border rounded-2xl bg-blue-300 px-3 py-1 text-sm font-bold text-white">
                  Details
                </div>
              </td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  );
};

