import React, { useRef, useState } from "react";
import ReactMapGL, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";

function CircleAroundCoordinates(props: any) {
  const mapRef = useRef<any>(null);
  // 25.5540252,84.6702799
  // 25.6155065,85.0216876
  const [viewState, setViewState] = useState<any>({
    longitude: props?.geometry?.coordinates[1] || 84.6702799,
    latitude: props?.geometry?.coordinates[0] || 25.5540252,
    zoom: props?.zoom || 10,
  });

  const coordinates: [number, number][] = [
    [25.5540252, 84.6702799], // Replace with your array of coordinates
    [25.6155065, 85.0216876],
    [25.7155065, 86.0216876],
  ];

  const createGeoJSONCircle = (
    center: [number, number],
    radiusInKm: number,
    points: number = 64
  ): FeatureCollection<Geometry, GeoJsonProperties> => {
    const coords = {
      latitude: center[0],
      longitude: center[1],
    };

    const km = radiusInKm;

    const ret: GeoJSON.Position[] = [];
    const distanceX =
      km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    for (let i = 0; i < points; i++) {
      const theta = (i / points) * (2 * Math.PI);
      const x = distanceX * Math.cos(theta);
      const y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [ret],
          },
          properties: {},
        },
      ],
    };
  };

  const circlesData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: "FeatureCollection",
    features: coordinates.map((coord) => ({
      type: "Feature",
      geometry: createGeoJSONCircle(coord, 5).features[0].geometry,
      properties: {},
    })),
  };

  return (
    <div className="p-10 h-full">
      <div className="flex h-96 w-full border">
        <ReactMapGL
          ref={mapRef}
          initialViewState={viewState}
          mapStyle="mapbox://styles/sachinraina/clweyy5y900i601qs9joxdk4e"
          mapboxAccessToken={
            process.env.REACT_APP_MAPBOX ||
            "pk.eyJ1Ijoic2FjaGlucmFpbmEiLCJhIjoiY2x3N242M2thMDB0MDJsczR2eGF4dXJsZSJ9.ocBaZJ9rPSUhmS4zGRi7vQ"
          }
          onMove={(e: any) => setViewState(e.viewState)}
        >
          <Source id="circle-data" type="geojson" data={circlesData}>
            <Layer
              id="circle-layer"
              type="fill"
              paint={{
                "fill-color": "red",
                "fill-opacity": 0.5,
              }}
            />
          </Source>
        </ReactMapGL>
      </div>
    </div>
  );
}

export default CircleAroundCoordinates;
