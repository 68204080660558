import React, { useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { getScreensDateFilterByScreenAudiences } from "../../../actions/screenDataAction";
import { useDispatch } from "react-redux";
import { AUDIENCES_FILTER_OPTIONS } from "../../../constants/localStorageConstants";

interface Audience {
  audienceCategory: string;
  subAudienceCategory: string;
}
interface FilterOptionInterface {
  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  selectedTouchPoints: string[];
  selectedAudiences: Audience[];
}

export const CampaignCreateTable: React.FC<any> = ({
  data,
  showFilterLevels,
  loadingFiltered,
}) => {
  const dispatch = useDispatch<any>();
  const [countries, setCountries] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedTP, setSelectedTP] = useState<string[]>([]);
  const [selectedGen, setSelectedGen] = useState<any>([]);

  const [genetics, setGenetics] = useState<any>({});
  const [countryGenetics, setCountryGenetics] = useState<any>({});
  const [stateGenetics, setStateGenetics] = useState<any>({});
  const [cityGenetics, setCityGenetics] = useState<any>({});
  const [tpGenetics, setTpGenetics] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<FilterOptionInterface[]>(
    []
  );
  const [audiences, setAudiencesData] = useState<any>([]);
  const [toggle, setToggle] = useState<any>(true);

  const addFilterValueInLocalStorage = (data: FilterOptionInterface[]) => {
    console.log("addFilterValueInLocalStorage : ", data);
    setFilterOptions(data);
    window.localStorage.setItem(AUDIENCES_FILTER_OPTIONS, JSON.stringify(data));
  };

  const fillGeneticsData = (myData: any) => {
    const genes: any = {};
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const touchPoints in myData[country][state][city]) {
            const geneticTouchPoints: any =
              myData[country][state][city][touchPoints];
            for (const gender in geneticTouchPoints) {
              for (const profession in geneticTouchPoints[gender]) {
                if (genes[gender]) {
                  if (!genes[gender].includes(profession)) {
                    genes[gender].push(profession);
                  }
                } else {
                  genes[gender] = [profession];
                }
              }
            }
          }
        }
      }
    }
    return genes;
  };

  const getDefaultAudienceData = (data: any) => {
    const da = fillGeneticsData(data);
    let audienceData: Audience[] = [];

    for (let key in da) {
      for (let value of da[key]) {
        audienceData.push({
          audienceCategory: key,
          subAudienceCategory: value,
        });
      }
    }
    return audienceData;
  };

  const setInitialFilterOptions = (myData: any) => {
    let arr: FilterOptionInterface[] = [];

    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          const touchPoints = Object.keys(myData[country][state][city]);
          arr.push({
            selectedCountry: country,
            selectedState: state,
            selectedCity: city,
            selectedTouchPoints: Array.from(new Set(touchPoints)),
            selectedAudiences: getDefaultAudienceData(myData),
          });
        }
      }
    }
    addFilterValueInLocalStorage(arr);
  };

  const isCountrySelectedInFilterOptions = (country: string) => {
    let res = filterOptions.some(
      (data: any) => data?.selectedCountry === country
    );
    return res; // true/false
  };

  const isStateSelectedInFilterOptions = (state: string) => {
    let res = filterOptions.some((data: any) => data?.selectedState === state);
    return res; // true/false
  };

  const isCitySelectedInFilterOptions = (city: string) => {
    let res = filterOptions.some((data: any) => data?.selectedCity === city);
    return res; // true/false
  };

  const isTouchPointSelectedInFilterOptions = (
    country: string,
    state: string,
    city: string,
    tp: string
  ) => {
    let res = filterOptions.find(
      (data: any) =>
        data?.selectedCountry === country &&
        data?.selectedState === state &&
        data?.selectedCity === city
    );
    if (res === undefined) return false;
    return res?.selectedTouchPoints?.includes(tp);
  };

  const isSelectedAudience = (
    audienceCategory: string,
    subAudienceCategory: string
  ) => {
    if (filterOptions.length > 0) {
      let res = audiences.some(
        (data1: any) =>
          data1.audienceCategory === audienceCategory &&
          data1.subAudienceCategory === subAudienceCategory
      );
      return res;
    }
    return false;
  };

  useEffect(() => {
    if (filterOptions.length > 0) {
      let arr = filterOptions;
      for (let data of arr) {
        data.selectedAudiences = audiences;
      }
      setFilterOptions(arr);
      addFilterValueInLocalStorage(arr);
    }
  }, [audiences]);

  const handleSelectAudience = (
    audienceCategory: string,
    subAudienceCategory: string
  ) => {
    if (isSelectedAudience(audienceCategory, subAudienceCategory)) {
      let x: Audience[] = [];
      audiences.forEach((data: Audience) => {
        if (
          !(
            data.audienceCategory === audienceCategory &&
            data.subAudienceCategory === subAudienceCategory
          )
        ) {
          x.push({
            audienceCategory: data.audienceCategory,
            subAudienceCategory: data.subAudienceCategory,
          });
        }
      });
      setAudiencesData(x);
    } else {
      setAudiencesData((pre: Audience[]) => [
        ...pre,
        {
          audienceCategory,
          subAudienceCategory,
        },
      ]);
    }
  };

  const handelSelectCountryForFilterOptions = (
    country: string,
    option: boolean
  ) => {
    if (option) {
      let arr: FilterOptionInterface[] = [];
      for (const state in data[country]) {
        for (const city in data[country][state]) {
          const touchPoints = Object.keys(data[country][state][city]);
          arr.push({
            selectedCountry: country,
            selectedState: state,
            selectedCity: city,
            selectedTouchPoints: touchPoints,
            selectedAudiences: audiences,
          });
        }
      }
      addFilterValueInLocalStorage([...filterOptions, ...arr]);
    } else {
      let arr = filterOptions.filter(
        (data: any) => data?.selectedCountry !== country
      );
      addFilterValueInLocalStorage(arr);
    }
  };

  const handelSelectStateForFilterOptions = (
    country: string,
    state: string,
    option: boolean
  ) => {
    if (option) {
      let arr: FilterOptionInterface[] = [];
      for (const city in data[country][state]) {
        const touchPoints = Object.keys(data[country][state][city]);
        arr.push({
          selectedCountry: country,
          selectedState: state,
          selectedCity: city,
          selectedTouchPoints: touchPoints,
          selectedAudiences: audiences,
        });
      }

      addFilterValueInLocalStorage([...filterOptions, ...arr]);
    } else {
      let arr = filterOptions.filter(
        (data: any) => data?.selectedState !== state
      );
      addFilterValueInLocalStorage(arr);
    }
  };

  const handelSelectCityForFilterOptions = (
    country: string,
    state: string,
    city: string,
    option: boolean
  ) => {
    if (option) {
      let arr: FilterOptionInterface[] = [];

      const touchPoints = Object.keys(data[country][state][city]);
      arr.push({
        selectedCountry: country,
        selectedState: state,
        selectedCity: city,
        selectedTouchPoints: touchPoints,
        selectedAudiences: audiences,
      });

      addFilterValueInLocalStorage([...filterOptions, ...arr]);
    } else {
      let arr = filterOptions.filter(
        (data: any) => data?.selectedCity !== city
      );
      addFilterValueInLocalStorage(arr);
    }
  };

  const handelSelectTouchPointsForFilterOptions = (
    country: string,
    state: string,
    city: string,
    tp: string,
    option: boolean
  ) => {
    let res: FilterOptionInterface[] = filterOptions;

    let index: number = 0;
    res.forEach((data: FilterOptionInterface, i: number) => {
      if (
        data.selectedCountry === country &&
        data.selectedState === state &&
        data.selectedCity === city
      ) {
        index = i;
      }
    });

    if (res[index].selectedTouchPoints?.includes(tp)) {
      res[index].selectedTouchPoints = res[index].selectedTouchPoints.filter(
        (t: string) => t !== tp
      );
    } else {
      res[index].selectedTouchPoints = [...res[index].selectedTouchPoints, tp];
    }

    addFilterValueInLocalStorage(res);
    setTimeout(() => setToggle((pre: any) => !pre), 0);
  };

  const getStatesOfCountry = (country: string) => {
    const sts = Object.keys(data[country]);
    return sts;
  };

  const getCityFromStateAndCountry = (country: string, state: string) => {
    let x = Object.keys(data[country][state]);
    return x;
  };

  const getTagsFromCityStateCountry = (
    country: string,
    state: string,
    city: string
  ) => {
    let x = Object.keys(data[country][state][city]);
    return x;
  };

  const handleSelectedGen = (gend: string, gene: string) => {
    let gens = selectedGen;
    if (!Object.keys(gens).includes(gend)) {
      gens[gend] = [];
    }

    if (!gens[gend].includes(gene)) {
      gens[gend].push(gene);
    } else {
      gens[gend] = gens[gend].filter((g: any) => g !== gend);
    }
    setSelectedGen(gens);
  };

  const handleSelectedCountries = (cnt: string, option: boolean) => {
    let cnts = selectedCountries;
    let sts = selectedStates;

    const newStates = getStatesOfCountry(cnt);
    if (!cnts.includes(cnt)) {
      cnts.push(cnt);
    } else {
      cnts = cnts.filter((c: string) => c !== cnt);
    }

    sts = selectedStates.filter((item) => !newStates.includes(item));
    setSelectedCountries(cnts);
    setSelectedStates(sts);
  };

  const handleSelectedStates = (cnt: any, st: any, option: any) => {
    let sts = selectedStates;
    let cts = selectedCities;

    const newCities = getCityFromStateAndCountry(cnt, st);

    if (!sts.includes(st)) {
      sts.push(st);
    } else {
      sts = sts.filter((s: any) => s !== st);
    }

    cts = selectedCities.filter((item) => !newCities.includes(item));
    setSelectedStates(sts);
    setSelectedCities(cts);
  };

  const handleSelectedCities = (cnt: any, st: any, ct: any, option: any) => {
    let cts = selectedCities;
    let tps = selectedTP;

    const newTPs = getTagsFromCityStateCountry(cnt, st, ct);

    if (!cts.includes(ct)) {
      cts.push(ct);
    } else {
      cts = cts.filter((c: any) => c !== ct);
    }

    tps = selectedTP.filter((item) => !newTPs.includes(item));
    setSelectedCities(cts);
    setSelectedTP(tps);
  };

  const fillCountryData = (myData: any) => {
    const genderRatio: any = {};
    for (const country in myData) {
      genderRatio[country] = {};

      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const touchPoints in myData[country][state][city]) {
            const geneticTouchPoints: any =
              myData[country][state][city][touchPoints];

            for (const gender in geneticTouchPoints) {
              if (!genderRatio[country][gender]) {
                genderRatio[country][gender] = {};
              }
              for (const profession in geneticTouchPoints[gender]) {
                if (!genderRatio[country][gender][profession]) {
                  genderRatio[country][gender][profession] = {
                    count: 0,
                    total: 0,
                  };
                }
                genderRatio[country][gender][profession].count++;
                genderRatio[country][gender][profession].total +=
                  geneticTouchPoints[gender][profession];
              }
            }
          }
        }
      }
    }

    for (const country in genderRatio) {
      const countryData = genderRatio[country];
      for (const gender in countryData) {
        const genderData = countryData[gender];
        for (const profession in genderData) {
          genderData[profession] = (
            genderData[profession].total / genderData[profession].count
          ).toFixed(2);
          delete genderData[profession].count;
          delete genderData[profession].total;
        }
      }
    }
    return genderRatio;
  };

  const fillStateData = (myData: any) => {
    const genderRatio: any = {};
    for (const country in myData) {
      for (const state in myData[country]) {
        genderRatio[state] = {};
        for (const city in myData[country][state]) {
          for (const touchPoints in myData[country][state][city]) {
            const geneticTouchPoints: any =
              myData[country][state][city][touchPoints];

            for (const gender in geneticTouchPoints) {
              if (!genderRatio[state][gender]) {
                genderRatio[state][gender] = {};
              }

              for (const profession in geneticTouchPoints[gender]) {
                if (!genderRatio[state][gender][profession]) {
                  genderRatio[state][gender][profession] = {
                    count: 0,
                    total: 0,
                  };
                }

                genderRatio[state][gender][profession].count++;
                genderRatio[state][gender][profession].total +=
                  geneticTouchPoints[gender][profession];
              }
            }
          }
        }
      }
    }
    for (const state in genderRatio) {
      const stateData = genderRatio[state];
      for (const gender in stateData) {
        const genderData = stateData[gender];
        for (const profession in genderData) {
          genderData[profession] = (
            genderData[profession].total / genderData[profession].count
          ).toFixed(2);
          delete genderData[profession].count;
          delete genderData[profession].total;
        }
      }
    }

    return genderRatio;
  };

  const fillCityData = (myData: any) => {
    const genderRatio: any = {};
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          genderRatio[city] = {};
          for (const touchPoints in myData[country][state][city]) {
            const geneticTouchPoints: any =
              myData[country][state][city][touchPoints];

            for (const gender in geneticTouchPoints) {
              if (!genderRatio[city][gender]) {
                genderRatio[city][gender] = {};
              }

              for (const profession in geneticTouchPoints[gender]) {
                if (!genderRatio[city][gender][profession]) {
                  genderRatio[city][gender][profession] = {
                    count: 0,
                    total: 0,
                  };
                }
                genderRatio[city][gender][profession].count++;
                genderRatio[city][gender][profession].total +=
                  geneticTouchPoints[gender][profession];
              }
            }
          }
        }
      }
    }
    for (const city in genderRatio) {
      const cityData = genderRatio[city];
      for (const gender in cityData) {
        const genderData = cityData[gender];
        for (const profession in genderData) {
          genderData[profession] = (
            genderData[profession].total / genderData[profession].count
          ).toFixed(2);
          delete genderData[profession].count;
          delete genderData[profession].total;
        }
      }
    }
    return genderRatio;
  };

  const fillTpData = (myData: any) => {
    const genderRatio: any = {};
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const touchPoints in myData[country][state][city]) {
            genderRatio[touchPoints] = {};

            const geneticTouchPoints: any =
              myData[country][state][city][touchPoints];

            for (const gender in geneticTouchPoints) {
              if (!genderRatio[touchPoints][gender]) {
                genderRatio[touchPoints][gender] = {};
              }

              for (const profession in geneticTouchPoints[gender]) {
                if (!genderRatio[touchPoints][gender][profession]) {
                  genderRatio[touchPoints][gender][profession] = {
                    count: 0,
                    total: 0,
                  };
                }
                genderRatio[touchPoints][gender][profession].count++;
                genderRatio[touchPoints][gender][profession].total +=
                  geneticTouchPoints[gender][profession];
              }
            }
          }
        }
      }
    }
    for (const touchPoints in genderRatio) {
      const touchPointsData = genderRatio[touchPoints];
      for (const gender in touchPointsData) {
        const genderData = touchPointsData[gender];
        for (const profession in genderData) {
          genderData[profession] = (
            genderData[profession].total / genderData[profession].count
          ).toFixed(2);
          delete genderData[profession].count;
          delete genderData[profession].total;
        }
      }
    }
    return genderRatio;
  };

  useEffect(() => {
    if (data != undefined) {
      setAudiencesData(getDefaultAudienceData(data));
      setCountries(Object.keys(data));
      setInitialFilterOptions(data);

      setGenetics(fillGeneticsData(data));
      setCountryGenetics(fillCountryData(data));
      setStateGenetics(fillStateData(data));
      setCityGenetics(fillCityData(data));
      setTpGenetics(fillTpData(data));

      setSelectedCountries(Object.keys(fillCountryData(data)));
      setSelectedStates(Object.keys(fillStateData(data)));
      setSelectedCities(Object.keys(fillCityData(data)));
      setSelectedTP(Object.keys(fillTpData(data)));
    }
  }, [data]);

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <div className="border border-proohBorderGray">
            <h1 className="text-base font-bold p-5 truncate">
              Selected Target Audience <br />
              W.R.T Audience <br />
              Percentage
            </h1>
          </div>
          {countries?.map((cnt: string, i: number) => (
            <div key={i} className="">
              <div className="flex flex-row justify-between px-2 items-center border border-proohBorderGray h-8">
                <div className="flex flex-row gap-2" key={i}>
                  <input
                    title="vehicle4"
                    type="checkbox"
                    id="cnt"
                    name="vehicle1"
                    checked={isCountrySelectedInFilterOptions(cnt)}
                    onChange={(e: any) =>
                      handelSelectCountryForFilterOptions(cnt, e.target.checked)
                    }
                  />
                  <label className="text-sm font-black" htmlFor="cnt">
                    {cnt}
                  </label>
                </div>
                {selectedCountries?.includes(cnt) ? (
                  <FaCaretUp
                    size="20px"
                    onClick={() => handleSelectedCountries(cnt, false)}
                  />
                ) : (
                  <FaCaretDown
                    size="20px"
                    onClick={() => handleSelectedCountries(cnt, true)}
                  />
                )}
              </div>
              {selectedCountries?.includes(cnt) ? (
                <div>
                  {Object.keys(data[cnt])?.map((st: string, i: number) => (
                    <div className="" key={i}>
                      <div className="pl-4 pr-2 flex flex-row justify-between items-center border border-proohBorderGray h-8">
                        <div className="flex flex-row gap-2 truncate" key={i}>
                          <input
                            title="vehicle4"
                            type="checkbox"
                            id="st"
                            name="vehicle2"
                            onChange={(e: any) => {
                              handelSelectStateForFilterOptions(
                                cnt,
                                st,
                                e.target.checked
                              );
                              if (e.target.checked) {
                                handleSelectedStates(cnt, st, true);
                              } else {
                                handleSelectedStates(cnt, st, false);
                              }
                            }}
                            checked={isStateSelectedInFilterOptions(st)}
                          />
                          <label className="text-sm font-bold" htmlFor="st">
                            {st}
                          </label>
                        </div>
                        {selectedStates?.includes(st) ? (
                          <FaCaretUp
                            size="20px"
                            onClick={() => handleSelectedStates(cnt, st, false)}
                          />
                        ) : (
                          <FaCaretDown
                            size="20px"
                            onClick={() => handleSelectedStates(cnt, st, true)}
                          />
                        )}
                      </div>
                      {selectedStates?.includes(st) ? (
                        <div className="">
                          {Object.keys(data[cnt][st])?.map(
                            (city: string, i: number) => (
                              <div key={i}>
                                <div className="pl-8 pr-2 h-8 flex flex-row justify-between items-center border border-proohBorderGray">
                                  <div className="flex flex-row gap-2" key={i}>
                                    <input
                                      title="vehicle4"
                                      type="checkbox"
                                      id="city"
                                      name="vehicle3"
                                      checked={isCitySelectedInFilterOptions(
                                        city
                                      )}
                                      onChange={(e: any) => {
                                        handelSelectCityForFilterOptions(
                                          cnt,
                                          st,
                                          city,
                                          e.target.checked
                                        );
                                        if (e.target.checked) {
                                          handleSelectedStates(cnt, st, true);
                                        } else {
                                          handleSelectedStates(cnt, st, false);
                                        }
                                      }}
                                    />
                                    <label
                                      className="text-sm font-semibold"
                                      htmlFor="city"
                                    >
                                      {city}
                                    </label>
                                  </div>
                                  {selectedCities?.includes(city) ? (
                                    <FaCaretUp
                                      size="20px"
                                      onClick={() =>
                                        handleSelectedCities(
                                          cnt,
                                          st,
                                          city,
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <FaCaretDown
                                      size="20px"
                                      onClick={() =>
                                        handleSelectedCities(
                                          cnt,
                                          st,
                                          city,
                                          true
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                {selectedCities?.includes(city) ? (
                                  <div className="">
                                    {Object.keys(data[cnt][st][city])?.map(
                                      (tag: string, i: number) => (
                                        <div key={i}>
                                          <div className="pl-12 h-8 flex items-center border border-proohBorderGray">
                                            <div className="flex flex-row gap-2 truncate">
                                              <input
                                                title="vehicle4"
                                                type="checkbox"
                                                id="tag"
                                                name="vehicle4"
                                                checked={isTouchPointSelectedInFilterOptions(
                                                  cnt,
                                                  st,
                                                  city,
                                                  tag
                                                )}
                                                onChange={(e: any) =>
                                                  handelSelectTouchPointsForFilterOptions(
                                                    cnt,
                                                    st,
                                                    city,
                                                    tag,
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                              <label
                                                className="text-sm font-base"
                                                htmlFor="tag"
                                              >
                                                {tag}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className="col-span-9 grid grid-cols-8">
          {Object.keys(genetics) &&
            Object.keys(genetics).map((g: any, i: any) => (
              <div
                className={
                  // i === 0 ? "col-span-3" : i === 1 ? "col-span-3" : "col-span-1"
                  "col-span-4"
                }
                key={i}
              >
                <div
                  className={
                    i === 0
                      ? "border border-proohBorderGray bg-proohCyan flex justify-center items-center h-10"
                      : i === 1
                      ? "border border-proohBorderGray bg-proohPurple flex justify-center items-center h-10"
                      : "border border-proohBorderGray bg-proohYellowLight flex justify-center items-center h-10"
                  }
                >
                  <h1 className="font-semibold">{g.toUpperCase()}</h1>
                </div>
                <div className="flex overflow-scroll no-scrollbar">
                  {genetics[g] &&
                    genetics[g].map((p: any, j: any) => (
                      <div className="w-auto h-full" key={j}>
                        <div className="border border-proohBorderGray w-[5rem] flex justify-center items-center h-[4.6rem]">
                          <div className="flex gap-2 p-1 truncate">
                            <input
                              title="vehicle4"
                              type="checkbox"
                              id="audience"
                              name="vehicle4"
                              checked={isSelectedAudience(g, p)}
                              onChange={() => handleSelectAudience(g, p)}
                            />
                            <label
                              className="text-sm font-base"
                              htmlFor="audience"
                            >
                              {p}
                            </label>
                          </div>
                        </div>
                        {Object.keys(countryGenetics) &&
                          Object.keys(countryGenetics).map((c: any, k: any) => (
                            <div className="" key={k}>
                              <div className="border border-proohBorderGray h-8 flex justify-center">
                                <h1 className="font-black">
                                  {(
                                    Number(countryGenetics[c][g][p]) * 100
                                  ).toFixed(0) || 0}
                                  %
                                </h1>
                              </div>
                              {selectedCountries.includes(c) &&
                                Object.keys(data[c]).map((b: any, l: any) => (
                                  <div className="" key={l}>
                                    <div className="border border-proohBorderGray h-8 flex justify-center">
                                      <h1 className="font-bold">
                                        {(
                                          Number(stateGenetics[b][g][p] || 0) *
                                          100
                                        ).toFixed(0) || 0}
                                        %
                                      </h1>
                                    </div>
                                    {selectedStates.includes(b) &&
                                      Object.keys(data[c][b]).map(
                                        (w: any, o: any) => (
                                          <div className="" key={o}>
                                            <div className="border border-proohBorderGray h-8 flex justify-center">
                                              <h1 className="font-semibold">
                                                {(
                                                  Number(
                                                    cityGenetics[w][g][p] || 0
                                                  ) * 100
                                                ).toFixed(0) || 0}
                                                %
                                              </h1>
                                            </div>
                                            {selectedCities.includes(w) &&
                                              Object.keys(data[c][b][w]).map(
                                                (t: any, q: any) => (
                                                  <div className="" key={q}>
                                                    <div className="border border-proohBorderGray h-8 flex justify-center">
                                                      <h1>
                                                        {tpGenetics[t][g] &&
                                                        Object.keys(
                                                          tpGenetics[t][g]
                                                        ).includes(p)
                                                          ? (
                                                              Number(
                                                                tpGenetics[t][
                                                                  g
                                                                ][p]
                                                              ) * 100
                                                            ).toFixed(0)
                                                          : 0}
                                                        %
                                                      </h1>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      {showFilterLevels && !loadingFiltered && (
        // selectedCountries.length !== curCnt.length &&
        // selectedStates.length !== curSt.length &&
        // selectedCities.length !== curCt.length &&

        <div className="w-full flex justify-end py-2">
          <button
            type="button"
            title="button"
            className="flex justify-end items-center text-blue-500"
            // className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
            onClick={() => {
              // setShowAudienceTable(true);
              dispatch(
                getScreensDateFilterByScreenAudiences(
                  JSON.parse(
                    localStorage?.getItem(AUDIENCES_FILTER_OPTIONS) || "{}"
                  )
                )
              );
            }}
          >
            Click here to confirm your audiences and continue your planning{" "}
            {`>`}
          </button>
        </div>
      )}
    </div>
  );
};
