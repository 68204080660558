import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getScreenLogsWithCampaigns } from "../../actions/screenDataAction";
import {
  BackButton,
  SetCampaignsPriority,
  CreateCampaignsFromFolderModal,
  CreateCampaignsFromCreativesModel,
  ScreenLogReport,
  ScreenDetailsTabs,
} from "../../components";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_COMPLETED,
  CAMPAIGN_STATUS_PAUSE,
  CHANGE_CHAMPAIGN_STATUS_RESET,
  CREATE_CAMPAIGN_RESET,
} from "../../constants/campaignConstants";
import {
  changeCampaignStatus,
  getActiveCampaignsByScreenId,
  getCompletedCampaignsByScreenId,
  getPauseCampaignsByScreenId,
} from "../../actions/campaignAction";
import { SIGNIN } from "../../routes/routes";
import { USER_ROLE_PRIMARY } from "../../constants/userConstants";
import { confirmData } from "../../data/confirmData";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertIntoDateAndTime } from "../../utils/dateAndTimeUtils";
import { Options } from "./Options";

export const MiddleArea = (props: any) => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const screenId = location.state.screenId;
  const screen = location.state.screen;
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState<boolean>(false);

  const [openPriorityModal, setOpenPriorityModal] = useState<boolean>(false);
  const [isScreenLogReportOpen, setIsScreenLogReportOpen] =
    useState<boolean>(false);

  const [allCampaigns, setAllCampaigns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
  };

  const closeScreenLogReportModal = () => {
    setIsScreenLogReportOpen(false);
  };

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const screenOptions = location.state.screens?.map((screen: any) => {
    return {
      value: screen?._id,
      label: screen?.screenName?.toUpperCase(),
    };
  });

  const campaignCreate1 = useSelector((state: any) => state.campaignCreate1);
  const {
    loading: loadingSlotBooking,
    error: errorSlotBooking,
    success: successSlotBooking,
  } = campaignCreate1;

  const activeCampaigns = useSelector((state: any) => state.activeCampaigns);
  const {
    loading: loadingCampaigns,
    error: errorCampaigns,
    success: successCampaigns,
    data: campaigns,
  } = activeCampaigns;

  // console.log("active campaign : ", campaigns);

  const changeCampaignsStatus = useSelector(
    (state: any) => state.changeCampaignsStatus
  );
  const {
    loading,
    error: errorChangeCampaignsStatus,
    success: successChangeStatus,
  } = changeCampaignsStatus;

  const screenLogsWithCampaignsGet = useSelector(
    (state: any) => state.screenLogsWithCampaignsGet
  );
  const {
    loading: loadingScreenLogs,
    error: errorScreenLogs,
    data: screenLogs,
  } = screenLogsWithCampaignsGet;

  const handleSetOpenModel = () => {
    if (userInfo?.userRole == USER_ROLE_PRIMARY) {
      setIsPopupOpen(true);
    } else {
      openErrorToast("You have no access");
    }
  };

  const handleSetOpenModel2 = () => {
    if (userInfo?.userRole == USER_ROLE_PRIMARY) {
      setIsPopupOpen1(true);
    } else {
      openErrorToast("You have no access");
    }
  };

  const setPriorityAuto = async () => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_PROOH_SERVER}/api/v1/campaigns/setPriorityAuto`,
        {
          screenId: screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      );
      dispatch(getActiveCampaignsByScreenId(screenId, CAMPAIGN_STATUS_ACTIVE));
      setIsLoading(false);
    } catch (error) {
      console.log("Error handleSetPriorityByOneClick: ", error);
    }
  };

  const handleSetPriorityByOneClick = async () => {
    if (confirm("Do want to set automatically campaign priority?")) {
      setIsLoading(true);
      setPriorityAuto();
    }
  };

  useEffect(() => {
    if (successChangeStatus) {
      dispatch({ type: CHANGE_CHAMPAIGN_STATUS_RESET });
      dispatch(getActiveCampaignsByScreenId(screenId, CAMPAIGN_STATUS_ACTIVE));
      dispatch(getPauseCampaignsByScreenId(screenId, CAMPAIGN_STATUS_PAUSE));
      dispatch(
        getCompletedCampaignsByScreenId(screenId, CAMPAIGN_STATUS_COMPLETED)
      );

      openSuccessToast("Successfully campaign status changed!");
    }
  }, [successChangeStatus]);

  useEffect(() => {
    if (errorChangeCampaignsStatus) {
      openErrorToast(errorChangeCampaignsStatus);
      dispatch({ type: CHANGE_CHAMPAIGN_STATUS_RESET });
    }
  }, [errorChangeCampaignsStatus]);

  useEffect(() => {
    if (!userInfo) {
      navigate(SIGNIN);
    }
  }, [userInfo]);

  const getScreenLogs = () => {
    dispatch(
      getScreenLogsWithCampaigns({ screenId: screenId, start: 0, limit: 240 })
    );
  };

  useEffect(() => {
    if (errorSlotBooking) {
      openErrorToast(errorSlotBooking);
      dispatch({ type: CREATE_CAMPAIGN_RESET });
    }
    if (successSlotBooking) {
      openSuccessToast("Campaign created successfully!");
      dispatch({ type: CREATE_CAMPAIGN_RESET });
      dispatch(getActiveCampaignsByScreenId(screenId, CAMPAIGN_STATUS_ACTIVE));
    }
  }, [errorSlotBooking, successSlotBooking, dispatch]);

  const handleChangeStatus = (status: string) => {
    if (confirm(confirmData[status])) {
      dispatch(
        changeCampaignStatus({
          campaignIds: selectedCampaigns,
          status,
          screenIds: [screen.screenId],
        })
      );
    }
    setTimeout(() => setSelectedCampaigns([]), 1000);
  };

  return (
    <div className="pt-10">
      <ToastContainer />
      <ScreenLogReport
        isOpen={isScreenLogReportOpen}
        onClose={closeScreenLogReportModal}
        screenLogs={screenLogs}
        screenName={screen?.screenName}
        allCampaigns={allCampaigns}
        loading={loadingScreenLogs}
      />

      <CreateCampaignsFromFolderModal
        isOpen={isPopupOpen}
        onClose={closePopup}
        screenOptions={screenOptions}
        defaultScreen={screenId}
        screens={location.state.screens}
      />
      <CreateCampaignsFromCreativesModel
        isOpen={isPopupOpen1}
        onClose={closePopup1}
        screenOptions={screenOptions}
        defaultScreen={screenId}
        screens={location.state.screens}
      />
      <SetCampaignsPriority
        isOpen={openPriorityModal}
        onClose={() => setOpenPriorityModal(false)}
        activeCampaigns={campaigns}
        screenId={screenId}
      />
      <div className="flex gap-2 items-center">
        <BackButton />
        <h1 className="text-black-700 font-bold text-xl">Screen detail page</h1>
      </div>
      <div className="flex justify-between">
        <div className="py-2">
          <h1 className="text-xl font-bold">{screen?.screenName || ""}</h1>
          <h1 className="text-sm text-red-700">
            Last Active: {convertIntoDateAndTime(screen?.lastActive)}
          </h1>
        </div>
        <Options
          setIsScreenLogReportOpen={setIsScreenLogReportOpen}
          getScreenLogs={getScreenLogs}
          handleSetOpenModel={handleSetOpenModel}
          handleSetOpenModel2={handleSetOpenModel2}
          handleSetPriorityByOneClick={handleSetPriorityByOneClick}
          setOpenPriorityModal={setOpenPriorityModal}
        />
      </div>
      <ScreenDetailsTabs
        setSelectedCampaigns={setSelectedCampaigns}
        selectedCampaigns={selectedCampaigns}
        handleChangeStatus={handleChangeStatus}
        loading={loadingCampaigns}
        screen={screen}
        handleSetPriorityByOneClick={setPriorityAuto}
      />
    </div>
  );
};
