import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../../utils/valueValidate";
import { createUser, signup } from "../../actions/userActions";
import { useSelector } from "react-redux";
import {
  ALLY_USER_ROLE,
  MASTER_USER_ROLE,
  USER_ROLE_PRIMARY,
  USER_ROLE_SECONDARY,
  USER_SIGNUP_RESET,
} from "../../constants/userConstants";
import { SIGNIN } from "../../routes/routes";

export const AddNewUser = (props: any) => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState<string>(USER_ROLE_SECONDARY);
  const dispatch = useDispatch<any>();
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [userType, setUserType] = useState<any>(null);
  console.log("userType: ", userType);

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  // console.log("user role : ", userInfo);

  useEffect(() => {
    if (!userInfo) {
      navigate(SIGNIN);
    } else {
      if (userInfo?.userRole !== USER_ROLE_PRIMARY) {
        alert("You have no access to this page");
        navigate(-1);
      } else {
        if (userInfo.isMaster) {
          setUserType(MASTER_USER_ROLE);
        } else if (userInfo?.isBrand) {
          setUserType(ALLY_USER_ROLE);
        }
      }
    }
  }, [userInfo]);

  const validateForm = () => {
    if (email.lenght == 0) {
      alert("Enter your email");
      return false;
    } else if (!isValidEmail(email)) {
      alert("Please enter valid email");
      return false;
    } else if (password.length < 8) {
      alert("Password length must be atleast 8");
      return false;
    } else if (password != confirmPassword) {
      alert("Password amd confirm password mismatch");
      setPassword("");
      setConfirmPassword("");
      return false;
    } else if (userRole === null) {
      alert("Please select user role");
      return false;
    } else {
      return true;
    }
  };

  const handleCreateNewUser = () => {
    if (validateForm()) {
      dispatch(
        createUser({
          name,
          email,
          password,
        })
      );
    }
  };

  // auth

  const userSignup = useSelector((state: any) => state.userSignup);
  const { error, success, userInfo1 } = userSignup;

  const reset = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (error) {
      console.log("Error in signup : ", error);
      alert(error);
      dispatch({ type: USER_SIGNUP_RESET });
    }
    if (success) {
      reset();
      dispatch({ type: USER_SIGNUP_RESET });
      alert("User Created Successfully");
    }
  }, [error, success]);

  return (
    <div className="flex justify-center pt-16 ">
      <div className="border border-solid p-8 gap-2 w-full max-w-96 ">
        <div className="flex flex-col gap-2">
          <h1 className="py-2 text-b text-xxl">Create Secondary User</h1>
          <div className="flex flex-col gap-2">
            <label>Name*</label>
            <input
              type=""
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Enter name"
              className="border border-solid rounded p-2"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Email*</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter email"
              className="border border-solid rounded p-2"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Password*</label>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter password"
              className="border border-solid rounded p-2"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Confirm Password*</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              placeholder="Enter confirm password"
              className="border border-solid rounded p-2"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Select User Role*</label>
            <select
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              className="w-46 h-12 border rounded"
            >
              <option value={USER_ROLE_SECONDARY}>Secondary</option>
            </select>
          </div>
          <div className="flex justify-center pt-4">
            <button
              className="border border-solid px-4 py-2 bg-sky-500/75 rounded-md text-white w-96"
              onClick={handleCreateNewUser}
            >
              Create New User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
