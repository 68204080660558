import { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { IoBusiness, IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { LiaSaveSolid } from "react-icons/lia";
import { useDispatch } from "react-redux";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import { updateUserProfile } from "../../actions/userActions";
import { FileUploader } from "../../components/common/FileUploader";
import { getAWSUrlToUploadFile, saveFileOnAWS } from "../../utils/awsUtils";

export function MiddleArea(props: any) {
  const dispatch = useDispatch<any>();
  const [isDesiable, setIsDesiable] = useState<any>(true);
  const [name, setName] = useState<any>("");
  const [avatar, setAvatar] = useState<any>("");
  const [file, setFile] = useState<any>(null);
  const [phone, setPhone] = useState<any>("");
  const [businessName, setBusinessName] = useState<any>("");
  const [districtCity, setDistrictCity] = useState<any>("");
  const [stateUt, setStateUt] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [gst, setGst] = useState<any>("");
  const [loading1, setLoading1] = useState<any>(false);

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  function handleProfileSelect(file: any) {
    const fileThumbnail = URL.createObjectURL(file);
    setAvatar(fileThumbnail);
    setFile(file);
  }

  useEffect(() => {
    setName(userInfo?.name);
    setPhone(userInfo?.phone);
    setAddress(userInfo?.address);
    setStateUt(userInfo?.stateUt);
    setDistrictCity(userInfo?.districtCity);
    setPincode(userInfo?.pincode);
    setAvatar(userInfo?.avatar);
    setBusinessName(userInfo?.businessName);
    setGst(userInfo?.gst);
  }, [userInfo]);

  const userUpdateProfile = useSelector(
    (state: any) => state.userUpdateProfile
  );
  const { loading, error: errorUpdateProfile, success } = userUpdateProfile;

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      setLoading1(false);
      setIsDesiable(true);
    }
    if (errorUpdateProfile) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      setLoading1(false);
    }
  }, [dispatch, errorUpdateProfile, success]);

  const handelSave = async () => {
    let awsUrl = userInfo?.avatar;
    if (file) {
      const aws = await getAWSUrlToUploadFile(file.type);
      const successAWSUploadFile = await saveFileOnAWS(aws?.url, file);
      awsUrl = aws?.awsURL;
    }
    dispatch(
      updateUserProfile({
        _id: userInfo?._id,
        name,
        phone,
        businessName,
        districtCity,
        stateUt,
        address,
        pincode,
        gst,
        avatar: awsUrl,
      })
    );
  };
  return (
    <div className="bg-white w-full p-8 gap-4 border border-1 border-#E1E1E1">
      <div className="flex justify-between">
        <div className="flex gap-8 align-center">
          <img alt="avatar" src={avatar} className="h-12 w-12 rounded-full" />
          {isDesiable ? (
            <div className="flex flex-col">
              <h1>{userInfo?.name}</h1>
              <h1>{userInfo?.isMaster ? "Screen Owner" : "Advertiser"}</h1>
            </div>
          ) : (
            <FileUploader handleFile={handleProfileSelect} />
          )}
        </div>
        {isDesiable ? (
          <button onClick={() => setIsDesiable(false)}>
            <div className="flex flex-row gap-2">
              <CiEdit size="20px" />
              <h1>Edit Profile</h1>
            </div>
          </button>
        ) : (
          <button onClick={handelSave}>
            <div className="flex flex-row gap-2">
              <LiaSaveSolid size="20px" />
              <h1>Save</h1>
            </div>
          </button>
        )}
      </div>
      <div className="flex flex-col p-8 gap-4 w-full h-full">
        <div className="flex flex-row p-8 gap-4 w-full h-full">
          <div className="flex flex-row gap-4">
            <IoBusiness />
            <h1>Business Name</h1>
          </div>

          <input
            title="o"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            disabled={isDesiable}
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          ></input>
        </div>
        <div className="flex flex-row p-8 gap-4 w-full h-full">
          <div className="flex flex-row gap-4">
            <MdOutlineEmail />
            <h1>Email Id</h1>
          </div>
          <input
            title="r"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            disabled={true}
            value={userInfo?.email}
          ></input>
        </div>
        <div className="flex flex-row p-8 gap-4 w-full h-full">
          <div className="flex flex-row gap-4">
            <IoCallOutline />
            <h1>Your Contact</h1>
          </div>
          <input
            title="y"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            disabled={isDesiable}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></input>
        </div>
        <div className="flex flex-row p-8 gap-4 w-full h-full">
          <div className="flex flex-row gap-4">
            <FaRegUser />
            <h1>Your Name</h1>
          </div>
          <input
            title="e"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            disabled={isDesiable}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        {isDesiable ? (
          <div className="flex flex-row p-8 gap-4 w-full h-full">
            <div className="flex flex-row gap-4">
              <IoLocationOutline />
              <h1>Your Address</h1>
            </div>
            <input
              title="k"
              className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
              disabled={isDesiable}
              value={`${userInfo?.address}, ${userInfo?.districtCity}, ${userInfo?.stateUt}, ${userInfo?.pincode}`}
            ></input>
          </div>
        ) : (
          <>
            <div className="flex flex-row p-8 gap-4 w-full h-full">
              <div className="flex flex-row gap-4">
                <IoLocationOutline />
                <h1>Full Address</h1>
              </div>
              <input
                title="e"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                disabled={isDesiable}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-row p-8 gap-4 w-full h-full">
              <div className="flex flex-row gap-4">
                <IoLocationOutline />
                <h1>City</h1>
              </div>
              <input
                title="q"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                disabled={isDesiable}
                value={districtCity}
                onChange={(e) => setDistrictCity(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-row p-8 gap-4 w-full h-full">
              <div className="flex flex-row gap-4">
                <IoLocationOutline />
                <h1>State</h1>
              </div>
              <input
                title="t"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                disabled={isDesiable}
                value={stateUt}
                onChange={(e) => setStateUt(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-row p-8 gap-4 w-full h-full">
              <div className="flex flex-row gap-4">
                <IoLocationOutline />
                <h1>Pincode</h1>
              </div>
              <input
                title="s"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                disabled={isDesiable}
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              ></input>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
