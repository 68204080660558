import { useDispatch, useSelector } from "react-redux";
import { Mapbox } from "../map/Mapbox";
import React, { useEffect, useState } from "react";
import {
  getAllScreens,
  getScreenIdsGroupByCountry,
} from "../../../actions/screenDataAction";
import { SingleScreen } from "../SingleScreen";
import { MapWithGeometry } from "../map/MapWithGeometry";

export const MapViewForExplorScreens = (props: any) => {
  // console.log(props);
  const dispatch = useDispatch<any>();
  const geometry = { coordinates: [28, 77] };

  const [jsonData, setJsonData] = useState<any>({ features: [] });
  const [screens, setScreens] = useState<any[]>([]);
  const [screenMarkers, setScreenMarkers] = useState<any[]>([]);
  //   --------------
  const [defCnt, setDefCnt] = useState<string[]>([]);
  const [defSt, setDefSt] = useState<string[]>([]);
  const [defCt, setDefCt] = useState<string[]>([]);
  const [defTP, setDefTP] = useState<string[]>([]);

  const [countryStates, setCountryStates] = useState<any>({});
  const [stateCities, setStateCities] = useState<any>({});
  const [cityTouchpoints, setCityTouchpoints] = useState<any>({});
  const [touchpointsCities, setTouchpointsCities] = useState<any>({});


  const allScreensData = useSelector((state: any) => state.allScreensData);
  const { data: allScreens } = allScreensData;

  // const allScreensIdsByCountryGet = useSelector(
  //   (state: any) => state.allScreensIdsByCountryGet
  // );
  // const { data: mapData } = allScreensIdsByCountryGet;
  // console.log(mapData);

  const handleSetJsonData = (data: any) => {
    if (data) {
      let x = data.map((screen: any) => {
        return {
          type: "Feature",
          properties: {
            screen: screen._id,
          },
          geometry: {
            coordinates: [
              screen?.location?.geographicalLocation?.latitude,
              screen?.location?.geographicalLocation?.longitude,
            ],
            type: "Point",
          },
        };
      });
      let y = data.map((screen: any) => {
        return {
          latitude: screen.location.geographicalLocation.latitude,
          longitude: screen.location.geographicalLocation.longitude,
          orientation: screen.orientation,
          pointOfInterest: screen.location.pointOfInterest,
          screenId: screen._id,
          screenLength: screen.screenLength,
          screenName: screen.screenName,
          screenResolution: screen.screenResolution,
          screenWidth: screen.screenWidth,
          slotLengthSeconds: screen.slotLengthSeconds,
        }
      })
      setJsonData({ features: x });
      setScreenMarkers(y);
      
    }

  };

  const filterDataAccording = () => {
    let result: string[] = [];
    for (let cnt of defCnt) {
      // getting all state of this country
      for (let state of Object.keys(props?.data[cnt])) {
        // now i am checking this state belonge to defStat or not
        // console.log("def state  : ", defSt, state);
        if (defSt.includes(state)) {
          // if yes
          // find all city of this state
          for (let city in props?.data[cnt][state]) {
            // check this city belonf to defaultCity or not
            // console.log("def city  : ", defCt, city);

            if (defCt.includes(city)) {
              // find its all touchPonts of city
              for (let tp in props?.data[cnt][state][city]) {
                // console.log("def tp  : ", defTP, tp);

                // now check this tp present of not in defTP
                if (defTP.includes(tp)) {
                  result = [...result, ...props?.data[cnt][state][city][tp]];
                }
              }
            }
          }
        }
      }
    }
    let filteredScreens = [];

    if (allScreens?.length > 0) {
      for (let screen of allScreens) {
        if (result.includes(screen.screenId)) {
          filteredScreens.push(screen);
        }
      }
    }

    if (filteredScreens.length > 0) {
      setScreens(filteredScreens);
      handleSetJsonData(filteredScreens);
    }
    if (result.length == 0) {
      setScreens(allScreens || []);
      handleSetJsonData(allScreens || []);
    }
  };

  const getTotalCountryCount = () => {
    return Object.keys(props?.data)?.length || 0;
    // return 0
  };

  const getTotalStatesCounts = () => {
    let ans = 0;
    if (defCnt?.length === 0) {
      for (let cnt in props?.data) {
        let statesCount = Object.keys(props?.data[cnt])?.length || 0;
        ans += statesCount;
      }
    } else {
      for (let cnt of defCnt) {
        let statesCount = Object.keys(props?.data[cnt])?.length || 0;
        ans += statesCount;
      }
    }
    return ans;
  };

  const getTotalTouchPointsCount = () => {
    return Object.keys(touchpointsCities)?.length || 0;
  };
  const getTotalCityCount = () => {
    return Object.keys(cityTouchpoints)?.length || 0;
  };

  const fillCntData = (myData: any) => {
    const cs: any = {};
    // Extracting data for cs
    for (const country in myData) {
      cs[country] = cs[country] || {};
      for (const state in myData[country]) {
        cs[country][state] = Object.keys(myData[country][state]).length;
      }
    }
    return cs;
  };

  const fillStateData = (myData: any) => {
    const sc: any = {};
    // Extracting data for sc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          sc[state] = sc[state] || {};
          sc[state][city] = Object.keys(myData[country][state][city]).length;
        }
      }
    }
    return sc;
  };

  const fillCityData = (myData: any) => {
    const ct: any = {};
    // Extracting data for ct
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          ct[city] = ct[city] || {};
          for (const attribute in myData[country][state][city]) {
            ct[city][attribute] = myData[country][state][city][attribute];
          }
        }
      }
    }
    return ct;
  };

  const handleSetDefCity = (type: boolean, city: string) => {
    let cities = defCt;
    if (type) {
      cities.push(city);
      cities = Array.from(new Set(cities));
      setDefCt(cities);
    } else {
      cities = cities.filter((ct: string) => ct != city);
      setDefCt(cities);
    }
    filterDataAccording();
  };

  const handleSetDefTouchPoints = (type: boolean, tp: string) => {
    let tps = defTP;
    if (type) {
      tps.push(tp);
      tps = Array.from(new Set(tps));
      setDefTP(tps);
    } else {
      tps = tps.filter((ct: string) => ct != tp);
      setDefTP(tps);
    }
    filterDataAccording();
  };

  const fillTpData = (myData: any) => {
    const tc: any = {};
    // Extracting data for tc
    for (const country in myData) {
      for (const state in myData[country]) {
        for (const city in myData[country][state]) {
          for (const attribute in myData[country][state][city]) {
            tc[attribute] = tc[attribute] || {};
            tc[attribute][city] = myData[country][state][city][attribute];
          }
        }
      }
    }
    const sortedArray = Object.entries(tc).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
    const sortedObject = Object.fromEntries(sortedArray);
    return sortedObject;
  };

  const handleCntClick = (country: any) => {
    let x = defCnt;
    if (!defCnt.includes(country)) {
      x = [...defCnt, country];
      setDefCnt([...defCnt, country]);
    } else {
      // remove this contry from defCnt
      x = x.filter((value: string) => country != value);
      setDefCnt(x);
    }
    const dfc = Array.from(new Set([...x]));

    const dataToShow: any = {};

    dfc.map((d: any) => {
      dataToShow[d] = props?.data[d];
    });

    let xx = fillStateData(dataToShow);
    const yy = fillCityData(dataToShow);
    const zz = fillTpData(dataToShow);

    setStateCities(xx);
    setDefSt(Object.keys(xx));
    setCityTouchpoints(yy);
    setDefCt(Object.keys(yy));
    setTouchpointsCities(zz);
    setDefTP(Object.keys(zz));
    filterDataAccording();
  };

  const handleStClick = (state: any) => {
    let x = defSt;
    if (!defSt.includes(state)) {
      x = [...defSt, state];
      setDefSt([...defSt, state]);
    } else {
      // remove this contry from defCnt
      x = x.filter((value: string) => state != value);
      setDefSt(x);
    }
    const dfs = Array.from(new Set(x));
    const dataToUse: any = {};
    dfs.map((d: any) => {
      dataToUse[d] = stateCities[d];
    });
    const dataToShow: any = {};

    for (const state in dataToUse) {
      for (const city in dataToUse[state]) {
        dataToShow[city] = fillCityData(props?.data)[city];
      }
    }

    setCityTouchpoints(dataToShow);
    setDefCt(Object.keys(dataToShow));
    setDefTP(Object.keys(touchpointsCities));
    filterDataAccording();
  };

  useEffect(() => {
    if (allScreens?.length > 0) {
      setScreens(allScreens);
      handleSetJsonData(allScreens);
    }
  }, [allScreens]);

  useEffect(() => {
    dispatch(getAllScreens());
    // dispatch(getScreenIdsGroupByCountry());

  }, [dispatch]);

  // useEffect(() => {
  //   filterDataAccording();
  // }, [defCnt, defSt, defCt, defTP]);
  

  useEffect(() => {
    if (props?.data !== undefined) {
      setCountryStates(fillCntData(props?.data));
      setStateCities(fillStateData(props?.data));
      setCityTouchpoints(fillCityData(props?.data));
      setTouchpointsCities(fillTpData(props?.data));
    }
  }, [props?.data]);

  useEffect(() => {
    if (props?.data) {
      for (let country of Object.keys(props?.data)) {
        handleCntClick(country);
      }
    }
  
  }, []);

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-3 py-2 border border-proohBorderGray-300 rounded">
          <div className="px-2 overflow-scroll no-scrollbar">
            <div className="flex flex-col">
              <h1 className="px-2 pt-2 text-xl font-bold">Filter</h1>
              <div className="h-[40rem] overflow-scroll no-scrollbar">
                <div className="p-2">
                  <h2 className="text-lg font-bold">
                    Countries ({getTotalCountryCount()})
                  </h2>
                  <div className="flex flex-col">
                    {Object.keys(props?.data)?.map((cn: string, i: number) => (
                      <div
                        className="flex flex-row gap-2"
                        key={i}
                      
                      >
                        <input
                          title="vehiclez"
                          type="checkbox"
                          id="vehiclez"
                          name="vehiclez"
                          value={cn}
                          onChange={() => {
                            handleCntClick(cn);
                          }}
                          defaultChecked={true}
                        />
                        <label className="py-1 text-sm font-semibold">
                          {cn}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="">
                  <div className="p-2">
                    <h2 className="text-lg font-bold">
                      States ({getTotalStatesCounts()})
                    </h2>
                    <div className="flex flex-col">
                      {Object.keys(stateCities).map((st: any, i: any) => (
                        <div
                          className="flex flex-row gap-2"
                          key={i}
                          // onClick={() => {
                          //   handleStClick(st);
                          // }}
                        >
                          <input
                            title="vehicle4"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={st}
                            onChange={(e) =>
                              handleStClick(st)
                            }
                            defaultChecked={defSt.includes(st) ? true : false}
                          />
                          <label className="py-1 text-sm font-semibold">
                            {st}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-2">
                    <h2 className="text-lg font-bold">
                      Cities ({getTotalCityCount()})
                    </h2>
                    <div className="flex flex-col">
                      {Object.keys(cityTouchpoints).map(
                        (c: any, i: any) => (
                          <div className="flex flex-row gap-2" key={i}>
                            <input
                              title="vehicle4"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              value={c}
                              onChange={(e) =>
                                handleSetDefCity(e.target.checked, c)
                              }
                              defaultChecked={defCt.includes(c) ? true : false}
                            />
                            <label className="py-1 text-sm font-semibold">
                              {c}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="p-2">
                    <h2 className="text-lg font-bold">
                      Touchpoints (
                      {defSt?.length > 0 && defCt?.length > 0
                        ? getTotalTouchPointsCount()
                        : 0}
                      )
                    </h2>
                    <div className="flex flex-col">
                      {
                        Object.keys(touchpointsCities).map(
                          (tp: any, i: any) => (
                            <div className="flex flex-row gap-2" key={i}>
                              <input
                                title="vehicle4"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value={tp}
                                onChange={(e) =>
                                  handleSetDefTouchPoints(
                                    e.target.checked,
                                    tp
                                  )
                                }
                                defaultChecked={defTP.includes(tp) ? true : false}
                              />
                              <label className="py-1 text-sm font-semibold">
                                {tp}
                              </label>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props?.listView ? (
          <div className="col-span-9">
            {screens?.length > 0 && (
              <div className="px-2">
                <div className="grid grid-cols-3 gap-5 h-[43.4rem] overflow-scroll no-scrollbar justify-center">
                  {screens?.map((screen: any, index: any) => (
                    <SingleScreen screen={screen} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="col-span-9">
            {/* {jsonData.features?.length > 0 && (
              <Mapbox geometry={geometry} jsonData={jsonData} /> */}
              <MapWithGeometry 
                geometry={geometry}
                handleRouteData={[]}
                circleRadius={[]}
                screenMarkers={screenMarkers || []}
                unSelectedScreens={[]}
                routes={[]}
                data={[]}
                
              />
            {/* )} */}
          </div>
        )}
      </div>
    </div>
  );
};
