import {
  getAllPleaRequest,
  sendPleaRequest,
  sendPleaResponse,
} from "../../actions/campaignAction";
import {
  PRIMARY_CAMPAIGN_MANAGER,
  PRIMARY_SCREEN_OWNER,
  SECONDARY_CAMPAIGN_MANAGER,
  SEND_PLEA_RESET,
  SEND_PLEA_RESPONSE_RESET,
} from "../../constants/campaignConstants";
import { USER_ROLE_SECONDARY } from "../../constants/userConstants";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SIGNIN } from "../../routes/routes";
import { Loading, Message } from "../../components";
import { CampaignPleaTable } from "./CampaignPleaTable";
import { MdKeyboardBackspace } from "react-icons/md";
import { getAllScreens } from "../../actions/screenDataAction";
import { CampaignPleaDetailsTable } from "./CampaignPleaDetailsTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CampaignCart = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const [budgetPleas, setBudgetPleas] = useState<any>({
    pending: {},
    accepted: {},
    rejected: {},
  });
  const [finalPleas, setFinalPleas] = useState<any>({
    pending: {},
    accepted: {},
    rejected: {},
  });
  const [screenPleas, setScreenPleas] = useState<any>({
    pending: {},
    accepted: {},
    rejected: {},
  });
  const [campaigns, setCampaigns] = useState<any>([]);
  const [uniqueCampaigns, setUniqueCampaigns] = useState<any>({});

  const [detailsView, setDetailsView] = useState<any>(null);
  const [campaignScreens, setCampaignScreens] = useState<any>([]);

  const [budgetPleasView, setBudgetPleasView] = useState<any>(true);
  const [screenPleasView, setScreenPleasView] = useState<any>(
    userInfo?.isBrand ? false : true
  );
  const [finalPleasView, setFinalPleasView] = useState<any>(false);

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const allPleasRequest = useSelector((state: any) => state.allPleasRequest);
  const { loading, error, data } = allPleasRequest;

  const pleaRequestSend = useSelector((state: any) => state.pleaRequestSend);
  const {
    loading: loadingPleaRequestSend,
    error: errorPleaRequestSend,
    data: pleaRequestSendData,
    success: pleaRequestSendSuccess,
  } = pleaRequestSend;

  const pleaResponseSend = useSelector((state: any) => state.pleaResponseSend);
  const {
    loading: loadingPleaResponseSend,
    error: errorPleaResponseSend,
    data: pleaResponseSendData,
    success: pleaResponseSendSuccess,
  } = pleaResponseSend;

  const allScreensData = useSelector((state: any) => state.allScreensData);
  const {
    loading: loadingAllScreens,
    error: errorAllScreens,
    data: allScreens,
  } = allScreensData;

  const campaignUpdate = useSelector((state: any) => state.campaignUpdate);
  const {
    loading: loadingCampaignUpdate,
    error: errorCampaignUpdate,
    success: successCampaignUpdate,
  } = campaignUpdate;

  useEffect(() => {
    if (successCampaignUpdate) {
      openSuccessToast("Successfully updated campaign media");
    }

    if (errorPleaRequestSend) {
      openErrorToast(errorPleaRequestSend);
      dispatch({ type: SEND_PLEA_RESET });
    }
    if (pleaRequestSendSuccess) {
      openSuccessToast("Successfully plea request sent!");
      dispatch({ type: SEND_PLEA_RESET });
    }
    if (errorPleaResponseSend) {
      openErrorToast(errorPleaResponseSend);
      dispatch({ type: SEND_PLEA_RESPONSE_RESET });
    }
    if (pleaResponseSendSuccess) {
      openSuccessToast("Successfully plea response!");
      dispatch({ type: SEND_PLEA_RESPONSE_RESET });
    }

    if (!userInfo) {
      navigate(SIGNIN);
    }

    if (userInfo?.isMaster) {
      dispatch(
        getAllPleaRequest({
          email: userInfo?.email,
          userRole: PRIMARY_SCREEN_OWNER,
        })
      );
    }
    if (userInfo?.isBrand) {
      dispatch(
        getAllPleaRequest({
          email: userInfo?.email,
          userRole:
            userInfo?.userRole === USER_ROLE_SECONDARY
              ? SECONDARY_CAMPAIGN_MANAGER
              : PRIMARY_CAMPAIGN_MANAGER,
        })
      );
    }

    dispatch(getAllScreens());
  }, [
    dispatch,
    navigate,
    userInfo,
    errorPleaRequestSend,
    pleaRequestSendSuccess,
    errorPleaResponseSend,
    pleaResponseSendSuccess,
    successCampaignUpdate,
  ]);

  // console.log("allPleasRequest data : ", data);

  const handleSendPleaResponse = ({
    senderEmail,
    receiverEmail,
    response,
    type,
    campaignId,
  }: any) => {
    dispatch(
      sendPleaResponse({
        senderEmail,
        receiverEmail,
        type,
        response,
        campaignId,
      })
    );
  };

  const handleSendPleaRequest = ({
    senderEmail,
    receiverEmail,
    type,
    campaignIds,
  }: any) => {
    // console.log(senderEmail, receiverEmail, type, campaignIds);
    dispatch(
      sendPleaRequest({
        pleaRequests: [
          {
            senderEmail,
            receiverEmail,
            type,
            campaignIds,
          },
        ],
      })
    );
  };

  const campaignsFromPleas = (pleas: any) => {
    let allCampaigns: any = [];
    let multiCampaigns: any = {};
    Object.keys(pleas)?.map((p: any, i: any) => {
      Object.keys(pleas[p])?.map((q: any, j: any) => {
        if (Object.keys(pleas[p][q]).length > 0) {
          Object.keys(pleas[p][q])?.map((r: any) => {
            pleas[p][q][r].map((s: any) => {
              allCampaigns.push(s);

              if (
                !Object.keys(multiCampaigns).includes(
                  s.campaignWithMultipleMediasWithMultipleScreens
                )
              ) {
                multiCampaigns[
                  s.campaignWithMultipleMediasWithMultipleScreens
                ] = s;
              }
            });
          });
        }
      });
    });

    // console.log(multiCampaigns);
    setCampaigns(allCampaigns);
    setUniqueCampaigns(multiCampaigns);
    // console.log(allCampaigns?.filter((s: any) => {}));
  };

  useEffect(() => {
    if (data) {
      campaignsFromPleas(data);
      setBudgetPleas(data.budget);
      setFinalPleas(data.final);
      setScreenPleas(data.screen);
    }
    // if (allScreens) {
    //   setCampaignScreens(allScreens.filter((s: any) => s._id ===))
    // }
  }, [data]);

  return (
    <div className="mt-6 w-full h-full pb-5">
      <div className="pt-6">
        <ToastContainer />
      </div>

      {(loadingPleaRequestSend || loadingPleaResponseSend) && (
        <h1 className="border border-1 bg-yellow-600 text-white text-lg px-8 py-2">
          Wait for some time processing you request.....
        </h1>
      )}

      <div className="flex flex-col gap-3 w-full">
        <div className="flex justify-start gap-5 py-3">
          <h1 className="text-3xl font-bold">Campaign Requests</h1>
        </div>
        {!detailsView ? (
          <CampaignPleaTable
            campaigns={Object.values(uniqueCampaigns)}
            setDetailsView={setDetailsView}
          />
        ) : (
          <div>
            {campaigns.length > 0 && detailsView !== null && (
              <div className="">
                <div className="flex flex-row items-center gap-5 py-3">
                  <MdKeyboardBackspace onClick={() => setDetailsView(null)} />
                  <h1 className="text-xl font-semibold">
                    {
                      campaigns
                        ?.filter(
                          (ca: any) =>
                            ca.campaignWithMultipleMediasWithMultipleScreens ===
                            detailsView
                        )
                        ?.map((c: any) => c.name)[0]
                        ?.split("_")
                        ?.splice(0)[0]
                    }
                  </h1>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-3 p-2 border flex justify-center items-center bg-[#FFF099]">
                    <h1>Activation Type</h1>
                  </div>
                  <div className="col-span-9">
                    <div className="grid grid-cols-10">
                      <div className="col-span-8 border p-2 bg-[#FFF099]">
                        <h1>Standard Loop For Campaigns</h1>
                      </div>
                      <div
                        className={`col-span-2 border p-2 flex justify-center ${
                          campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.campaignType)[0] === "Cohort"
                            ? "bg-[#FFC6B9]"
                            : "bg-[#E2EFD9]"
                        }`}
                      >
                        <h1>
                          {campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.campaignType)[0] === "Cohort"
                            ? "False"
                            : "True"}
                        </h1>
                      </div>
                    </div>
                    <div className="grid grid-cols-10">
                      <div className="col-span-8 border p-2 bg-[#FFF099]">
                        <h1>Audience Cohort Time Applied For Campaigns</h1>
                      </div>
                      <div
                        className={`col-span-2 border p-2 flex justify-center ${
                          campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.campaignType)[0] === "Cohort"
                            ? "bg-[#E2EFD9]"
                            : "bg-[#FFC6B9]"
                        }`}
                      >
                        <h1>
                          {campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.campaignType)[0] === "Cohort"
                            ? "True"
                            : "False"}
                        </h1>
                      </div>
                    </div>
                    <div className="grid grid-cols-10">
                      <div className="col-span-8 border p-2 bg-[#FFF099]">
                        <h1>Fill Additional Unused Slots Using Triggers</h1>
                      </div>
                      <div
                        className={`col-span-2 border p-2 flex justify-center ${
                          campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.isTriggers)[0]
                            ? "bg-[#E2EFD9]"
                            : "bg-[#FFC6B9]"
                        }`}
                      >
                        <h1>
                          {campaigns
                            ?.filter(
                              (ca: any) =>
                                ca.campaignWithMultipleMediasWithMultipleScreens ===
                                detailsView
                            )
                            ?.map((c: any) => c.isTriggers)[0]
                            ? "True"
                            : "False"}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                {loadingAllScreens ? (
                  <Loading />
                ) : (
                  <CampaignPleaDetailsTable
                    loading={
                      loadingPleaRequestSend ||
                      loadingPleaResponseSend ||
                      loadingCampaignUpdate
                    }
                    detailsView={detailsView}
                    campaigns={campaigns}
                    allScreens={allScreens}
                    userInfo={userInfo}
                    handleSendPleaRequest={handleSendPleaRequest}
                    handleSendPleaResponse={handleSendPleaResponse}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
