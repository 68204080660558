import { configureStore } from "@reduxjs/toolkit";
// import { thunk } from "redux-thunk"
import authReducer from "./authSlice";

import {
  addOrModifyScreenCodeReducer,
  allAudienceDataByCountryGetReducer,
  allScreenDataByAudiencesGetReducer,
  allScreensDataByCountryGetReducer,
  allScreensDataByUserIdReducer,
  allScreensDataReducer,
  allScreensIdsByCountryGetReducer,
  cricketMatchesListGetReducer,
  finalSummaryDataGetReducer,
  homePageCountsGetReducer,
  pricingDetailsDataGetReducer,
  screenCampaignsByCodeGetReducer,
  screenDetailReducer,
  screenLogsWithCampaignsGetReducer,
  screensDateFilterByScreenAudiencesReducer,
} from "../reducers/screenDataReducer";
import {
  userDeleteReducer,
  userEmailVerificationReducer,
  userListReducer,
  userSendEmailToResetPasswordReducer,
  userSigninReducer,
  userSignupReducer,
  userUpdatePasswordReducer,
  userUpdateProfileReducer,
} from "../reducers/userReducers";
import {
  allCampaignsByScreenIdReducer,
  allCampaignsReducer,
  campaignsByScreenIdReducer,
  campaignCreateReducer,
  campaignListByCampaignIdsReducer,
  campaignsCreateByScreenOwnerReducer,
  campaignsPrioritySetReducer,
  changeCampaignsStatusReducer,
  getAllCampaignsByAdminReducer,
  getAllPleasRequestReducer,
  sendPleaRequestReducer,
  sendPleaResponseReducer,
  getCampaignLogReducer,
  getCampaignsTableDataByCampaignIdsReducer,
  campaignUpdateReducer,
  getActiveCampaignReducer,
  getPauseCampaignReducer,
  getPendingCampaignReducer,
  getCompletedCampaignReducer,
} from "../reducers/campaignReducer";
import {
  getCreativesReducer,
  uploadCreativesReducer,
} from "../reducers/creativeReducer";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

const store = configureStore({
  initialState,
  reducer: {
    //screen
    homePageCountsGet: homePageCountsGetReducer,
    allScreensDataByCountryGet: allScreensDataByCountryGetReducer,
    allScreenDataByAudiencesGet: allScreenDataByAudiencesGetReducer,
    pricingDetailsDataGet: pricingDetailsDataGetReducer,
    finalSummaryDataGet: finalSummaryDataGetReducer,

    allAudienceDataByCountryGet: allAudienceDataByCountryGetReducer,
    allScreensData: allScreensDataReducer,
    allScreensIdsByCountryGet: allScreensIdsByCountryGetReducer,
    screensDateFilterByScreenAudiences:
      screensDateFilterByScreenAudiencesReducer,
    screenDetail: screenDetailReducer,
    screenCampaignsByCodeGet: screenCampaignsByCodeGetReducer,
    screenLogsWithCampaignsGet: screenLogsWithCampaignsGetReducer,
    allScreensDataByUserId: allScreensDataByUserIdReducer,
    addScreenCode: addOrModifyScreenCodeReducer,
    // cricker api
    cricketMatchesListGet: cricketMatchesListGetReducer,

    // user
    userSignin: userSigninReducer,
    userSignup: userSignupReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userUpdatePassword: userUpdatePasswordReducer,
    emailVerify: userEmailVerificationReducer,
    userSendEmailToResetPassword: userSendEmailToResetPasswordReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,

    // campaigns
    campaignCreate: campaignCreateReducer,
    campaignCreate1: campaignsCreateByScreenOwnerReducer,
    campaignsByScreenId: campaignsByScreenIdReducer,
    allCampaignsByScreenId: allCampaignsByScreenIdReducer,
    allCampaigns: allCampaignsReducer,
    campaignListByCampaignIds: campaignListByCampaignIdsReducer,
    changeCampaignsStatus: changeCampaignsStatusReducer,
    allCampaignsByAdmin: getAllCampaignsByAdminReducer,
    pleaResponseSend: sendPleaResponseReducer,
    pleaRequestSend: sendPleaRequestReducer,
    allPleasRequest: getAllPleasRequestReducer,
    campaignsPrioritySet: campaignsPrioritySetReducer,
    campaignUpdate: campaignUpdateReducer,
    campaignLog: getCampaignLogReducer,
    campaignsTableData: getCampaignsTableDataByCampaignIdsReducer,
    activeCampaigns: getActiveCampaignReducer,
    pauseCampaigns: getPauseCampaignReducer,
    pendingCampaigns: getPendingCampaignReducer,
    completedCampaigns: getCompletedCampaignReducer,

    // CREATIVES
    creativesUpload: uploadCreativesReducer,
    creativesList: getCreativesReducer,

    // auth
    auth: authReducer,
  },
  // middleware: thunk
  // devTools: process.env.NODE_ENV !== 'production'
});

store.subscribe(() => {
  const state = store.getState();
  if (state.auth.isLoggedIn) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        userInfo: state.auth.userInfo,
        loginTime: state.auth.loginTime,
      })
    );
    localStorage.setItem(
      "userInfo",
      JSON.stringify({ userInfo: state.auth.userInfo })
    );
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
  }
});

export default store;
