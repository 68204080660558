export const GET_HOME_PAGE_COUNTS_ERROR = "GET_HOME_PAGE_COUNTS_ERROR";
export const GET_HOME_PAGE_COUNTS_REQUEST = "GET_HOME_PAGE_COUNTS_REQUEST";
export const GET_HOME_PAGE_COUNTS_SUCCESS = "GET_HOME_PAGE_COUNTS_SUCCESS";

export const GET_SCREEN_DATA_BY_COUNTRY_ERROR =
  "GET_SCREEN_DATA_BY_COUNTRY_ERROR";
export const GET_SCREEN_DATA_BY_COUNTRY_REQUEST =
  "GET_SCREEN_DATA_BY_COUNTRY_REQUEST";
export const GET_SCREEN_DATA_BY_COUNTRY_SUCCESS =
  "GET_SCREEN_DATA_BY_COUNTRY_SUCCESS";

export const GET_SCREEN_DATA_BY_AUDIENCES_ERROR =
  "GET_SCREEN_DATA_BY_AUDIENCES_ERROR";
export const GET_SCREEN_DATA_BY_AUDIENCES_REQUEST =
  "GET_SCREEN_DATA_BY_AUDIENCES_REQUEST";
export const GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS =
  "GET_SCREEN_DATA_BY_AUDIENCES_SUCCESS";

export const GET_PRICING_DETAILS_DATA_ERROR = "GET_PRICING_DETAILS_DATA_ERROR";
export const GET_PRICING_DETAILS_DATA_REQUEST =
  "GET_PRICING_DETAILS_DATA_REQUEST";
export const GET_PRICING_DETAILS_DATA_SUCCESS =
  "GET_PRICING_DETAILS_DATA_SUCCESS";

export const GET_FINAL_SUMMARY_DATA_ERROR = "GET_FINAL_SUMMARY_DATA_ERROR";
export const GET_FINAL_SUMMARY_DATA_REQUEST = "GET_FINAL_SUMMARY_DATA_REQUEST";
export const GET_FINAL_SUMMARY_DATA_SUCCESS = "GET_FINAL_SUMMARY_DATA_SUCCESS";

export const GET_ALL_SCREENS_DATA_REQUEST = "GET_ALL_SCREENS_DATA_REQUEST";
export const GET_ALL_SCREENS_DATA_SUCCESS = "GET_ALL_SCREENS_DATA_SUCCESS";
export const GET_ALL_SCREENS_DATA_ERROR = "GET_ALL_SCREENS_DATA_ERROR";

export const GET_ALL_SCREENS_BY_USERID_REQUEST =
  "GET_ALL_SCREENS_BY_USERID_REQUEST";
export const GET_ALL_SCREENS_BY_USERID_SUCCESS =
  "GET_ALL_SCREENS_BY_USERID_SUCCESS";
export const GET_ALL_SCREENS_BY_USERID_ERROR =
  "GET_ALL_SCREENS_BY_USERID_ERROR";
export const GET_ALL_SCREENS_BY_USERID_RESET =
  "GET_ALL_SCREENS_BY_USERID_RESET";

export const GET_SCREEN_BY_ID_REQUEST = "GET_SCREEN_BY_ID_REQUEST";
export const GET_SCREEN_BY_ID_SUCCESS = "GET_SCREEN_BY_ID_SUCCESS";
export const GET_SCREEN_BY_ID_ERROR = "GET_SCREEN_BY_ID_ERROR";

export const GET_SCREEN_IDS_BY_COUNTRY_ERROR =
  "GET_SCREEN_IDS_BY_COUNTRY_ERROR";
export const GET_SCREEN_IDS_BY_COUNTRY_REQUEST =
  "GET_SCREEN_IDS_BY_COUNTRY_REQUEST";
export const GET_SCREEN_IDS_BY_COUNTRY_SUCCESS =
  "GET_SCREEN_IDS_BY_COUNTRY_SUCCESS";

export const GET_AUDIENCE_DATA_BY_COUNTRY_ERROR =
  "GET_AUDIENCE_DATA_BY_COUNTRY_ERROR";
export const GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST =
  "GET_AUDIENCE_DATA_BY_COUNTRY_REQUEST";
export const GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS =
  "GET_AUDIENCE_DATA_BY_COUNTRY_SUCCESS";

export const GET_CRICKET_MATCHES_ERROR = "GET_CRICKET_MATCHES_ERROR";
export const GET_CRICKET_MATCHES_REQUEST = "GET_CRICKET_MATCHES_REQUEST";
export const GET_CRICKET_MATCHES_SUCCESS = "GET_CRICKET_MATCHES_SUCCESS";

export const GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST =
  "GET_SCREENS_DATA_FILTER_BY_AUDIENCES_REQUEST";
export const GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS =
  "GET_SCREENS_DATA_FILTER_BY_AUDIENCES_SUCCESS";
export const GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR =
  "GET_SCREENS_DATA_FILTER_BY_AUDIENCES_ERROR";
export const GET_SCREENS_DATA_FILTER_BY_AUDIENCES_RESET =
  "GET_SCREENS_DATA_FILTER_BY_AUDIENCES_RESET";

export const GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR =
  "GET_SCREEN_CAMPAIGNS_BY_CODE_ERROR";
export const GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST =
  "GET_SCREEN_CAMPAIGNS_BY_CODE_REQUEST";
export const GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS =
  "GET_SCREEN_CAMPAIGNS_BY_CODE_SUCCESS";

export const GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR =
  "GET_SCREEN_LOGS_WITH_CAMPAIGNS_ERROR";
export const GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST =
  "GET_SCREEN_LOGS_WITH_CAMPAIGNS_REQUEST";
export const GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS =
  "GET_SCREEN_LOGS_WITH_CAMPAIGNS_SUCCESS";

export const ADD_SCREEN_CODE_REQUEST = "ADD_SCREEN_CODE_REQUEST";
export const ADD_SCREEN_CODE_SUCCESS = "ADD_SCREEN_CODE_SUCCESS";
export const ADD_SCREEN_CODE_ERROR = "ADD_SCREEN_CODE_ERROR";
export const ADD_SCREEN_CODE_RESET = "ADD_SCREEN_CODE_RESET";
