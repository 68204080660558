import React, { useEffect, useRef, useState } from "react";
import { HomeTable, HomeText, Loading, Message } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomePageCounts,
  getScreenDataByCountry,
  getScreenIdsGroupByCountry,
} from "../../actions/screenDataAction";
import { MapViewForExplorScreens } from "../../components/common/View/MapViewForExplorScreens";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../utils";
import { CAMPAIGNS } from "../../routes/routes";
import { CiViewTable } from "react-icons/ci";
import { RiRoadMapLine } from "react-icons/ri";
import { removeAllKeyFromLocalStorage } from "../../utils/localStorageUtils";

export const MiddleArea: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const targetDivRef = useRef<HTMLDivElement>(null);

  const [showMap, setShowMap] = useState<any>(false);
  const [listView, setListView] = useState<Boolean>(false);

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const allScreensDataByCountryGet = useSelector(
    (state: any) => state.allScreensDataByCountryGet
  );
  const { loading, error, data } = allScreensDataByCountryGet;

  const homePageCountsGet = useSelector(
    (state: any) => state.homePageCountsGet
  );
  const {
    loading: loadingCounts,
    error: errorCounts,
    data: dataCounts,
  } = homePageCountsGet;

  const allScreensIdsByCountryGet = useSelector(
    (state: any) => state.allScreensIdsByCountryGet
  );
  const {
    loading: loadingMapData,
    error: errorMapData,
    data: mapData,
  } = allScreensIdsByCountryGet;

  useEffect(() => {
    dispatch(getScreenDataByCountry());
    dispatch(getHomePageCounts());
    dispatch(getScreenIdsGroupByCountry());
  }, [dispatch]);

  const handleClick = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleListView = () => {
    setListView(!listView);
  };

  const handleGoToPlanCampaign = () => {
    removeAllKeyFromLocalStorage();
    navigate("/create-campaign");
  };

  return (
    <div className="mt-6 w-full h-full pb-5">
      <div className="flex flex-col justify-center items-center pt-10">
        <h3 className="text-sm">Best Campaign Planning Tool</h3>
        <h1 className="text-[5rem] font-bold text-center px-[6rem]">
          We Sell DOOH on Impressions Only. No Fixed Rental.
        </h1>
        <h2 className="text-md text-center px-[6rem] py-10">
          Pay only for impressions that your campaign receives, get data and
          authenticated log reports without paying any fixed rental cost
        </h2>
        <div className="flex justify-center py-4">
          <button
            type="button"
            title="button"
            className="m-2 w-60 h-12 bg-proohYellow focus:outline-none hover:bg-gray-300 rounded-md"
            onClick={handleGoToPlanCampaign}
          >
            <h1 className="text-base text-black font-semibold antialiased">
              Plan Campaign
            </h1>
          </button>
          <button
            type="button"
            title="button"
            className="m-2 w-60 h-12 bg-transparent border border-1 border-solid border-black focus:outline-none hover:bg-gray-300 hover:border-transparent rounded-md"
            onClick={() => {
              // setShowMap(true);
              handleClick();
            }}
          >
            <h1 className="text-base text-black font-semibold antialiased">
              Explore Screens
            </h1>
          </button>
        </div>
      </div>

      {/* <Message message={"ERROR MESSAGE"} content={""} height={200} width={200} /> */}
      <div className="" ref={targetDivRef}>
        {loadingCounts ? (
          <Loading height={200} width={200} />
        ) : errorCounts ? (
          <></>
        ) : (
          <div className="flex flex-row justify-center gap-[10rem]">
            <div
              className="flex flex-col py-10 items-center"
              onClick={() => navigate(CAMPAIGNS)}
            >
              <h1 className="text-2xl font-bold">
                {dataCounts?.campaign_count}
              </h1>
              <h1 className="font-regular">Campaigns Uploaded</h1>
            </div>
            <div className="flex flex-col py-10 items-center">
              <h1 className="text-2xl font-bold">
                {formatNumber(dataCounts?.impression_count || 0)}
              </h1>
              <h1 className="font-regular">Impressions Delivered</h1>
            </div>
            <div
              className="flex flex-col py-10 items-center"
              onClick={() => navigate("/screens")}
            >
              <h1 className="text-2xl font-bold">{dataCounts?.screen_count}</h1>
              <h1 className="font-regular">Screens Onboarded</h1>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loading height={200} width={200} />
      ) : error ? (
        <Message
          message={"ERROR MESSAGE"}
          content={error}
          height={200}
          width={200}
        />
      ) : (
        <div className="py-4">
          <div className="flex justify-between">
            <div className="p-4 flex gap-5 justify-center">
              <div
                className="flex gap-1 items-center"
                onClick={() => setShowMap(false)}
              >
                <CiViewTable color={showMap ? "gray" : "black"} />
                <h1 className={showMap ? "text-gray-400" : "text-black"}>
                  Table View
                </h1>
              </div>
              <div
                className="flex gap-1 items-center"
                onClick={() => setShowMap(true)}
              >
                <RiRoadMapLine color={showMap ? "black" : "gray"} />
                <h1 className={showMap ? "text-black" : "text-gray-400"}>
                  Map View
                </h1>
              </div>
            </div>
            <div className="p-4">
              {showMap ? (
                <div className="flex gap-2 items-center">
                  {/* <CiViewTable color={showMap ? "text-gray-400" : "text-black"}/> */}
                  <h1
                    className={
                      listView ? "text-black" : "text-gray-400 text-sm"
                    }
                  >
                    List View
                  </h1>
                  <div
                    onClick={handleListView}
                    className={`w-8 h-4 flex items-center rounded-full p-1 cursor-pointer ${
                      !listView ? "bg-greenLight" : "bg-gray-300 "
                    }`}
                  >
                    <div
                      className={`bg-white w-3 h-3 rounded-full shadow-md transform duration-300 ease-in-out ${
                        !listView ? "translate-x-3" : ""
                      }`}
                    ></div>
                  </div>
                  <h1
                    className={
                      !listView ? "text-black" : "text-gray-400 text-sm"
                    }
                  >
                    Map View
                  </h1>
                  {/* <RiRoadMapLine color={showMap ? "black" : "gray"} /> */}
                </div>
              ) : null}
            </div>
          </div>
          {!showMap && data != undefined ? (
            <HomeTable data={data} />
          ) : (
            <div>
              {loadingMapData ? (
                <Loading />
              ) : errorMapData ? (
                <Message
                  message={"ERROR MESSAGE"}
                  content={errorMapData}
                  height={200}
                  width={200}
                />
              ) : (
                mapData && (
                  <MapViewForExplorScreens listView={listView} data={mapData} />
                )
              )}
            </div>
          )}
          <br />
        </div>
      )}
      <div className="pt-10">
        <HomeText />
      </div>
    </div>
  );
};
