import { useState } from "react";
import { FaRegCalendarDays } from "react-icons/fa6";

export const EnterCampaignDetails = (props: any) => {
  const {
    campaignName,
    setCampaignName,
    brandName,
    setBrandName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    duration,
    handleSetDuration,
    handleSetNewDuration,
  } = props;

  const [enterDuration, setEnterDuration] = useState<any>(false);

  return (
    <div>
      <div className="flex gap-8 py-2">
        <div className="w-1/2">
          <h1 className="text-sm text-gray-800 py-1">Campaign Name</h1>
          <input
            type="text"
            placeholder="Enter Campaign Name"
            title="campaign-name"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            onChange={(e) => setCampaignName(e.target.value)}
            value={campaignName}
          />
        </div>
        <div className="w-1/2">
          <h1 className="text-sm text-gray-800 py-1">Brand Name</h1>
          <input
            type="text"
            placeholder="Enter Brand Name"
            title="Brand name"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            onChange={(e) => setBrandName(e.target.value)}
            value={brandName}
          />
        </div>
      </div>
      <div className="flex gap-8 py-2">
        <div className="w-1/2">
          <h1 className="text-sm text-gray-800 py-1">Start Date</h1>
          <input
            type="datetime-local"
            title="Start date"
            className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            min={
              new Date(new Date().setDate(new Date().getDate() + 1))
                .toISOString()
                .split("T")[0]
            }
          />
        </div>
        <div className="w-1/2">
          <div className="flex justify-between items-center">
            <h1 className="text-sm text-gray-800 py-1">
              {enterDuration ? `Duration (Days)` : `End Date`}
            </h1>
            <input
              className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
              type="checkbox"
              role="switch"
              title="dffs"
              id="flexSwitchCheckDefault"
              onChange={() => {
                handleSetNewDuration();
                setEnterDuration(!enterDuration);
              }}
            />
          </div>

          {enterDuration ? (
            <input
              title="Duration of campaign"
              placeholder="Enter campaign duration in days"
              type={"number"}
              className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
              onChange={(e) => handleSetDuration(e.target.value)}
              value={duration}
              min="1"
            />
          ) : (
            <div className="flex gap-2 items-center">
              <input
                type="datetime-local"
                title="End date"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                min={new Date().toISOString().slice(0, -8)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
