import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getScreenDetailById } from "../../../actions/screenDataAction";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";

interface PopupProps {
  isOpen: any;
  onClose: () => void;
  props: any;
  screen: any;
}
export const SingleScreenDataModal: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  props,
  screen,
}) => {
  const dispatch = useDispatch<any>();

  const screenDetail = useSelector((state: any) => state.screenDetail);
  const { loading, error, success, data: screenData } = screenDetail;

  useEffect(() => {
    if (screen) {
      dispatch(getScreenDetailById(screen?.screenId));
    }
  }, [screen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-9/12 max-w-full relative overflow-auto max-h-auto no-scrollbar"
        style={{ height: "90vh", width: "50vw" }}
      >
        <div className="flex flex-row justify-between items-center">
          <h1 className="text-sm px-1">Screen</h1>
          <button
            className="text-gray-500 hover:text-gray-700 absolute top-3 right-3"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {loading ? (
          <Skeleton active />
        ) : (
          <div>
            <div className="flex flex-row px-1">
              <h1 className="text-xl font-bold">{screenData?.screenName}</h1>
            </div>
            <div className="py-3 px-1">
              <img
                className="h-[360px] w-full"
                // src={`https://cf-ipfs.com/ipfs/${screenData?.images[0]}`}
                src={screenData?.images[0]}
                alt={screenData?.screenName}
              />
            </div>
            <div className="px-1">
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Placement Area</h1>
                <h1 className="text-sm">{screenData?.location?.address}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">
                  Integration Status
                </h1>
                <h1 className="text-sm">
                  {screenData?.integrationStatus ? "Yes" : "No"}
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Screen Pitch</h1>
                <h1 className="text-sm">
                  {screenData?.hardwarePitch
                    ? screenData?.hardwarePitch
                    : "Unavailable"}
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Geo Location</h1>
                <h1 className="text-sm">{`${screenData?.location?.geographicalLocation?.latitude}, ${screenData?.location?.geographicalLocation?.longitude}`}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Pin Code</h1>
                <h1 className="text-sm">
                  {screenData?.location?.pincode
                    ? screenData?.location?.pincode
                    : "Unavailable"}
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">
                  Operational Hours
                </h1>
                <h1 className="text-sm">{`${screenData?.operationalDuration?.onTime} - ${screenData?.operationalDuration?.offTime}`}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Operational Time</h1>
                <h1 className="text-sm">
                  {screenData?.operationalDuration?.totalDuration} hrs
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Resolution</h1>
                <h1 className="text-sm">{`${
                  screenData?.screenResolution?.split("x")[0]
                } X ${screenData?.screenResolution?.split("x")[1]}`}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Size</h1>
                <h1 className="text-sm">{`${screenData?.screenLength} L X ${screenData?.screenWidth} W `}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Network Type</h1>
                <h1 className="text-sm">
                  {screenData?.networkType
                    ? screenData?.networkType
                    : "Unavailable"}
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">
                  Total Loop length
                </h1>
                <h1 className="text-sm">
                  {screenData?.loopLengthSeconds} seconds
                </h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Total campaigns</h1>
                <h1 className="text-sm">{screenData?.campaigns?.length}</h1>
              </div>
              <div className="flex flex-row gap-40 items-center">
                <h1 className="w-40 text-sm font-semibold">Slot length</h1>
                <h1 className="text-sm">
                  {screenData?.slotLengthSeconds} seconds
                </h1>
              </div>
            </div>
            <div className="px-1 py-5">
              <h1>
                Cohort of users visiting hours as selected by the planning tool
              </h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
