import { MdCheck, MdClose, MdOutlineFileUpload, MdOutlineSlowMotionVideo } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import { Loading } from "../../components/index";

export const PleaButtons = (props: any) => {
  const {userInfo, status, campaign, loading } = props;
  // console.log(props);
  return (
    <div>

      {loading ? (
        <div>
          <Loading width="50px" height="50px" />
        </div>
      ) : (
        <div className="flex gap-2">
          {status === "PleaRequestBudgetSent" && userInfo?.isBrand && userInfo?.userRole === "primary" && userInfo?._id === campaign.primaryCampaignManger && (
            <div className="flex gap-2">
              <button
                type="button"
                title="accept"
                onClick={() => {
                  console.log("Accept");
                  props?.handleSendPleaResponse({
                    type: "BudgetPlea",
                    response: "accepted",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryCampaignMangerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdCheck color="green" size="20px" />
              </button>
              <button
                title="reject"
                type="button"
                onClick={() => {
                  console.log("Reject");
                  props?.handleSendPleaResponse({
                    type: "BudgetPlea",
                    response: "rejected",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryCampaignMangerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdClose color="red" size="20px" />
              </button>
            </div>
          )}
          {status === "PleaRequestBudgetAccepted" && userInfo?.isBrand && userInfo?.userRole === "secondary" && userInfo?._id === campaign.secondaryCampaignManager && (
            <div className="flex gap-2">
              <button
                type="button"
                title="accept"
                onClick={() => {
                  console.log("request sent");
                  props?.handleSendPleaRequest({
                    type: "ScreenPlea",
                    campaignIds: [campaign._id],
                    senderEmail: campaign.secondaryCampaignManagerEmail,
                    receiverEmail: campaign.primaryScreenOwnerEmail,
                  })
                }}
              >
                <BiMailSend color="blue" size="20px" />
              </button>
            </div>
          )}
          {status === "PleaRequestScreenApprovalSent" && userInfo?.isMaster && userInfo?.userRole === "primary" && userInfo?._id === campaign.primaryScreenOwner && (
            <div className="flex gap-2">
              <button
                type="button"
                title="accept"
                onClick={() => {
                  console.log("Accept"); 
                  props?.handleSendPleaResponse({
                    type: "ScreenPlea",
                    response: "accepted",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryScreenOwnerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdCheck color="green" size="20px" />
              </button>
              <button
                title="reject"
                type="button"
                onClick={() => {
                  console.log("Reject");
                  props?.handleSendPleaResponse({
                    type: "ScreenPlea",
                    response: "rejected",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryScreenOwnerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdClose color="red" size="20px" />
              </button>
            </div>
          )}
          {status === "PleaRequestScreenApprovalAccepted" && userInfo?.isBrand && userInfo?.userRole === "secondary" && userInfo?._id === campaign.secondaryCampaignManager && (
            <div className="flex gap-2">
              {campaign?.historyForMediaChange.length > 0 && (
                <button
                  type="button"
                  title="view"
                  onClick={() => {
                    console.log("View creatives");
                    window.open(campaign?.videoURL, '_blank', 'noopener,noreferrer');
                  }}
                >
                  <MdOutlineSlowMotionVideo color="green" size="20px" />
                </button>
              )}
              
              <button
                type="button"
                title="accept"
                onClick={() => {
                  console.log("Uplaod creatives");
                  props?.handleCampaignSelection({
                    id: campaign._id,
                    trueFalse: true,
                  });
                }}
              >
                <MdOutlineFileUpload color="blue" size="20px" />
              </button>
              {campaign?.historyForMediaChange.length > 0 && (
                <button
                  type="button"
                  title="accept"
                  onClick={() => {
                    console.log("request sent");
                    props?.handleSendPleaRequest({
                      type: "FinalPlea",
                      campaignIds: [campaign._id],
                      senderEmail: campaign.secondaryCampaignManagerEmail,
                      receiverEmail: campaign.primaryScreenOwnerEmail,
                    })
                  }}
                >
                  <BiMailSend color="blue" size="20px" />
                </button>
              )}
            </div>
          )}
          {status === "PleaRequestFinalApprovalSent" && (
            <div className="flex gap-2">
              {campaign?.historyForMediaChange.length > 0 && (
                <button
                  type="button"
                  title="view"
                  onClick={() => {
                    console.log("View creatives");
                    window.open(campaign?.videoURL, '_blank', 'noopener,noreferrer');
                  }}
                >
                  <MdOutlineSlowMotionVideo color="green" size="20px" />
                </button>
              )}
            </div>
          )}
          {status === "PleaRequestFinalApprovalSent" && userInfo?.isMaster && userInfo?.userRole === "primary" && userInfo?._id === campaign.primaryScreenOwner && (
            <div className="flex gap-2">
              <button
                type="button"
                title="accept"
                onClick={() => {
                  console.log("Accept");
                  props?.handleSendPleaResponse({
                    type: "FinalPlea",
                    response: "accepted",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryScreenOwnerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdCheck color="green" size="20px" />
              </button>
              <button
                title="reject"
                type="button"
                onClick={() => {
                  console.log("Reject");
                  props?.handleSendPleaResponse({
                    type: "FinalPlea",
                    response: "rejected",
                    campaignId: campaign._id,
                    senderEmail: campaign.primaryScreenOwnerEmail,
                    receiverEmail: campaign.secondaryCampaignManagerEmail,
                  })
                }}
              >
                <MdClose color="red" size="20px" />
              </button>
            </div>
          )}

        </div>
      )}
    </div>
  )
}