export const HOME = '/';
export const SHOWSCREENPICTURES = '/create-campaign/show-pictures';
export const UPLOADCREATIVES = '/create-campaign/upload-creatives';
export const CREATECAMPAIGN = '/create-campaign';
export const FINALCAMPAIGNSUMMARY = '/create-campaign/final-summary';
export const LOGIN = '/login';
export const USER_PROFILE = "/profile";
export const VERIFY_EMAIL = "/verify";
export const FORGET_PASSWORD = "/forget-password";
export const UPDATE_PASSWORD = "/update-password";
export const CAMPAIGNS = "/campaigns";
export const CAMPAIGNS_LIST = "/campaigns/campaign-details";
export const SIGNIN = "/signin";
export const USERS = "/users";
export const ADD_NEW_USER = "/users/add-new";
export const CART = "/cart";
export const MY_SCREENS = "/screens";
export const CREATIVES = "/creatives";
export const SCREEN_DETAILS = "/screens/details";
export const MY_CREATIVES = "/my/creatives";
export const MYPLAYLIST = "/my-play-list";
