import { useEffect, useState } from "react";
import { MapSearchInput, MapWithGeometry, Mapbox } from "../../components";
import { validateGioData } from "../../utils/excelUtils";
import { ExcelImport } from "../../components/common/ExcelImport";
import { RiCloseLine } from "react-icons/ri";
import { defaultPoi } from "../../constants/poiConstants";
import { getDistance } from "geolib";
import * as turf from "@turf/turf";
import { ExcelExport } from "../../components/common/ExcelExport";
import { filterScreensByInterests, filterScreensByLatLong } from "../../utils/screenRanking";
import { AUDIENCES_FILTER_OPTIONS } from "../../constants/localStorageConstants";

type Coordinate = [number, number];

export const FiveLevelFilter = (props: any) => {
  const [selectedPOI, setSelectedPOI] = useState<any>([]);
  const [dataBrand, setDataBrand] = useState<any[]>([]);
  const [dataCompt, setDataCompt] = useState<any[]>([]);
  const [circleData, setCircleData] = useState<any>({});

  const [filteredScreens, setFilteredScreens] = useState<any>([]);
  const [unSelectedScreens, setUnSelectedScreens] = useState<any>([]);

  const [filter1, setFilter1] = useState<any>([]);
  const [filter2, setFilter2] = useState<any>([]);
  const [filter3, setFilter3] = useState<any>([]);
  const [filter4, setFilter4] = useState<any>([]);
  const [coordinatesWithScreens, setCoordinatesWithScreens] = useState<any>([]);
  const [coordinatesWithScreens1, setCoordinatesWithScreens1] = useState<any>(
    []
  );

  const [newFilterForFilter1, setNewFilterForFilter1] = useState<any>([]);


  const [routeOrigin, setRouteOrigin] = useState<any>([]);
  const [routeDestination, setRouteDestination] = useState<any>([]);
  const [routes, setRoutes] = useState<any[]>([]);
  const [circleRadius, setCircleRadius] = useState<any>(1);

  useEffect(() => {
    let data1: any = {};
    data1["brand"] = dataBrand;
    data1["compt"] = dataCompt;
    setCircleData(data1);
  }, [dataBrand, dataCompt]);

  const getUniqueScreens = (data: any) => {
    console.log(data)
    if (data?.length === 0) return;
    let screenIds = data?.map((screen: any) => screen?.screenId);
    screenIds = Array.from(new Set(screenIds));
    let result = [];
    for (let id of screenIds) {
      let ss = data?.find((screen: any) => screen?.screenId == id);
      result.push(ss);
    }
    return result;
  };

  const handleSelectOrRemove = (checked: boolean, screens: string[], level: any) => {
    if (checked) {
      // add screens in filter1
      let data = level === 1 ? filter1 : level === 2 ? filter2 : level === 3 ? filter3 : level === 4 ? filter4 : filter1;
      let filterData = screens.map((screenId: string) => {
        return data.find((data: any) => data?.screenId === screenId);
      });
      let filterData23 = screens.map((screen: any) => {
        return data.find((data: any) => data?.screenId === screen?.screenId);
      });
      if (level === 1) {
        setNewFilterForFilter1((pre: any) =>
          getUniqueScreens([...pre, ...filterData])
        );
      }
      if (level === 2) {
        setFilter2((pre: any) =>
          getUniqueScreens([...pre, ...filterData23])
        );
      }
      if (level === 3) {
        setFilter3((pre: any) =>
          getUniqueScreens([...pre, ...filterData23])
        );
      }

    } else {
      // remove these screens from filter1
      if (level === 1) {
        let data = newFilterForFilter1?.filter(
          (screen: any) => !screens.includes(screen?.screenId)
        );
        setNewFilterForFilter1(getUniqueScreens(data));
      }
      if (level === 2) {
        let data = filter2?.filter(
          (screen: any) => !screens.includes(screen)
        );
        setFilter2(getUniqueScreens(data) || []);
      }
      if (level === 3) {
        let data = filter3?.filter(
          (screen: any) => !screens.includes(screen)
        );
        setFilter3(getUniqueScreens(data) || []);
      }
      
    }
  };

  const handleUnSelectedScreens = (selectedScreens: any) => {
    let screens = props?.allScreens?.filter((screen: any) => {
      if (
        selectedScreens?.find(
          (data: any) => data?.screenId === screen?.screenId
        ) === undefined
      ) {
        return screen;
      }
    });

    setUnSelectedScreens(screens);
  };

  useEffect(() => {
    let arr: any = [];
    if (newFilterForFilter1?.length > 0) {
      arr = [...filter2, ...filter3, ...filter4, ...newFilterForFilter1]; 
    } else {
      arr = [...filter2, ...filter3, ...filter4];
    }
    arr = getUniqueScreens(arr);
    handleUnSelectedScreens(arr);
    setFilteredScreens(arr);
    props.setFilteredScreens(arr);
  }, [filter2, filter3, filter4, newFilterForFilter1]);

  const withinRadius = (center: any, point: any, radius: any) => {
    const distance = getDistance(
      {
        latitude: center[1],
        longitude: center[0],
      },
      {
        latitude: point[1],
        longitude: point[0],
      }
    );
    return distance <= radius;
  };

  const filterScreenByPoi = (pois: any) => {
    // console.log("filterScreenByPoi : ", pois);
    const screens: any = props?.filteredOptions;
    if (pois?.length == 0) {
      setFilter1([]);
      setNewFilterForFilter1([]);
    } else {
      const chosenScreens = [];
      for (const poi of pois) {
        for (const cnt in screens) {
          for (const st in screens[cnt]) {
            for (const ct in screens[cnt][st]) {
              for (const screen of screens[cnt][st][ct]) {
                if (screen.pointOfInterest.includes(poi)) {
                  chosenScreens.push(screen);
                }
              }
            }
          }
        }
      }
      // console.log("filterScreenByPoi1 : ", getUniqueScreens(chosenScreens));
      setFilter1(getUniqueScreens(chosenScreens));
      setNewFilterForFilter1(getUniqueScreens(chosenScreens));
    }
  };

  const handleRemovePOI = (value: string) => {
    let arr = selectedPOI?.filter((poi: any) => poi !== value);
    setSelectedPOI(arr);
    filterScreenByPoi(arr);
  };

  const handleSetSelectedPoi = (value: string) => {
    if (!selectedPOI?.includes(value)) {
      setSelectedPOI((pre: any) => [...pre, value]);
      filterScreenByPoi([...selectedPOI, value]);
      // console.log([...selectedPOI, value]);
    }
  };

  const handleResetFileUpload = () => {
    setDataBrand([]);
    let data1: any = circleData;
    data1["brand"] = [];
    setCircleData(data1);
    setFilter2([]);
    setCoordinatesWithScreens([]);
  };
  const handleResetFileUpload1 = () => {
    setDataCompt([]);
    let data1: any = circleData;
    data1["compt"] = [];
    setCircleData(data1);
    setFilter3([]);
    setCoordinatesWithScreens1([]);
  };

  const handleGetExcelDataBrand = (data: any) => {
    const coveredScreens: any = [];

    const coordinates = data
      .map((x: any) => x.filter((y: any) => /^[+-]?\d+(\.\d+)?$/.test(y)))
      .filter((d: any) => d.length === 2);
    if (validateGioData(data)) {
      setDataBrand(coordinates);

      for (const coordinate of coordinates) {
        const center = coordinate;
        props?.allScreens
          .filter((l: any) =>
            withinRadius(center, [l.latitude, l.longitude], 1000)
          )
          ?.map((s: any) => {
            if (!coveredScreens.includes(s)) {
              coveredScreens.push(s);
            }
          });
      }
      const coordinatesWithScreensData: any = [];
      for (const coordinate of coordinates) {
        const center = coordinate;
        let x = props?.allScreens.filter((l: any) =>
          withinRadius(center, [l.latitude, l.longitude], 1000)
        );
        coordinatesWithScreensData.push({ screens: x, coordinate: coordinate });
      }
      setCoordinatesWithScreens(coordinatesWithScreensData);
      if (circleData["brand"]?.length > 0) {
        // console.log(circleData["compt"]);
      } else {
        setFilter2(getUniqueScreens(coveredScreens));

      }
    } else alert("Something went wrong, please send us correct data");
  };

  const handleGetExcelDataCompt = (data: any) => {
    const coveredScreens: any = [];

    const coordinates = data
      .map((x: any) => x.filter((y: any) => /^[+-]?\d+(\.\d+)?$/.test(y)))
      .filter((d: any) => d.length === 2);
    if (validateGioData(data)) {
      setDataCompt(coordinates);

      for (const coordinate of coordinates) {
        const center = coordinate;
        props?.allScreens
          .filter((l: any) =>
            withinRadius(center, [l.latitude, l.longitude], 1000)
          )
          ?.map((s: any) => {
            if (!coveredScreens.includes(s)) {
              coveredScreens.push(s);
            }
          });
      }
      const coordinatesWithScreensData: any = [];
      for (const coordinate of coordinates) {
        const center = coordinate;
        let x = props?.allScreens.filter((l: any) =>
          withinRadius(center, [l.latitude, l.longitude], 1000)
        );
        coordinatesWithScreensData.push({ screens: x, coordinate: coordinate });
      }
      setCoordinatesWithScreens1(coordinatesWithScreensData);
      if (circleData["compt"]?.length > 0) {
        // console.log(circleData["compt"]);
      } else {
        setFilter3(getUniqueScreens(coveredScreens));

      }
    } else alert("Something went wrong, please send us correct data");
  };

  const handleRemoveRoute = (id: any) => {
    let arr = routes;
    arr = arr.filter((data: any) => data?.id != id);
    setRoutes(arr);
  };

  const handleRouteSetup = async (originData: any, destinationData: any) => {
    let route: any = {};

    route["origin"] = originData[0];
    route["destination"] = destinationData[0];
    route["selectedScreens"] = [];
    route["id"] = routes?.length + 1;

    if (routes.includes(route)) {
    } else {
      routes.push(route);
    }

    setRoutes([...routes]);
  };

  const handleSetFIlter4 = (data: any) => {
    let result: any = [];
    data.forEach((route: any) => result.push(...route?.selectedScreens));
    setFilter4(result);
  };

  const handleRouteData = (routeData: any, id: any) => {
    const radiusInMeters = 100; // 100 meters radius

    const filteredRecords = props?.allScreens?.filter((point: any) => {
      let x: Coordinate = [point.longitude, point.latitude];
      return routeData?.coordinates.some((coord: Coordinate) => {
        const from = turf.point([Number(coord[0]), Number(coord[1])]);
        const to = turf.point(x);
        const distance = turf.distance(from, to, { units: "meters" });
        return distance <= radiusInMeters; // Convert meters to kilometers
      });
    });

    let arr = routes;
    for (let data of arr) {
      if (data?.id == id) {
        data.selectedScreens = filteredRecords;
      }
    }
    setRoutes(arr);
    handleSetFIlter4(arr);
  };

  useEffect(() => {
    // console.log("props?.allScreens changed!");
    setFilteredScreens(props?.allScreens);
    setUnSelectedScreens([]);
  }, [props?.allScreens]);

  return (
    <div className="">
      <div className="py-3">
        <h1 className="text-3xl font-bold">
          The Next 4 Level Filter Will Fine Tune Your Media Plan:
        </h1>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-span-5 h-[32rem] overflow-scroll p-4">
          <div className="p-1">
            <div className="py-2">
              <h1 className="text-xl font-bold py-1">
                1. POI Locations In Proximity
              </h1>
              <p className="text-sm font-semibold">
                To your selected Touch points or/and sites should be located:
              </p>
              <div className="w-full py-2">
                <select
                  className="border rounded-md text-sm text-gray-400 w-full h-10 p-1"
                  onChange={(event) => handleSetSelectedPoi(event.target.value)}
                >
                  <option>---Search Interest----</option>
                  {defaultPoi.map((p: any, i: any) => (
                    <option key={i} value={p.value}>
                      {p.name}
                    </option>
                  ))}
                </select>
                <div className="my-1 p-3 border h-24 grid grid-cols-12 gap-2">
                  {selectedPOI?.length > 0 ? (
                    selectedPOI?.map((p: any, i: any) => (
                      <div
                        key={i}
                        className="border border-transparent rounded-2xl bg-[#E6F3FF] col-span-3 flex gap-2 justify-between items-center px-1 h-8"
                      >
                        <h1 className="text-xs font-semibold basis-7/8 truncate">
                          {defaultPoi.filter((q: any) => q.value === p)[0].name}
                        </h1>
                        <div className="basis-1/8">
                          <RiCloseLine
                            size="12"
                            onClick={() => handleRemovePOI(p)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <h1 className="text-xs col-span-4">Selected interests</h1>
                  )}
                </div>
                <h1 className="text-sm text-[#1C9EDA] font-semibold">
                  Note:- The Selected {newFilterForFilter1?.length} Locations
                  are shown in the map
                </h1>
                {filterScreensByInterests(filter1, selectedPOI)
                  .interestCounts.map((z: any, i: any) => (
                    <div className="grid grid-cols-12 gap-2" key={i}>
                      <div className="col-span-8">
                        <div className="flex flex-row items-center gap-2">
                          <h1 className="text-sm">{i + 1} Matches</h1>
                          <div className="border rounded flex items-center w-[100px] h-2 bg-[#E1CAFF] mt-1">
                            <div
                              className="border border-transparent rounded bg-[#5534B5] h-full"
                              style={{
                                width: `${
                                  ((i + 1) /
                                    filterScreensByInterests(
                                      filter1,
                                      selectedPOI
                                    ).interestCounts?.length) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 flex justify-end gap-2">
                        <h1 className="text-sm font-semibold">
                          {z.count} locations found
                        </h1>
                        <input
                          title="poi"
                          type="checkbox"
                          defaultChecked
                          onChange={(e: any) =>
                            handleSelectOrRemove(e.target.checked, z.screens, 1)
                          }
                        />
                      </div>
                    </div>
                  ))
                  .reverse()}
              </div>
            </div>
            <div className="py-2">
              <h1 className="text-xl font-bold py-1">
                2. Select Geo-Location Of Your Establishments:
              </h1>
              <div className="flex items-center">
                <p className="text-sm font-semibold">
                  Download the excel format
                </p>
                <ExcelExport
                  excelData={[{ Latitude: "", Longitude: "" }]}
                  fileName="temp1"
                />
              </div>
              <div>
                <ExcelImport
                  handleGetExcelData={handleGetExcelDataBrand}
                  handleResetFileUpload={handleResetFileUpload}
                />
                <h1 className="text-sm text-[#1C9EDA] font-semibold">
                  Note: {filter2?.length || 0} screens selected as per your
                  choice
                </h1>
                <div className="flex flex-col ">
                  {Object.keys(filterScreensByLatLong(coordinatesWithScreens))?.map((c: any, i: any) => (
                    <div key={i} className="flex gap-2 justify-between">
                      <h1 className="text-sky-600 text-sm flex">
                        In{" "}
                        <strong className="text-red-700 px-1">
                          {filterScreensByLatLong(coordinatesWithScreens)[c].count}
                        </strong>{" "}
                        locations {filterScreensByLatLong(coordinatesWithScreens)[c].screens[0].length} screens found
                      </h1>
                      <input
                        title="poi"
                        type="checkbox"
                        defaultChecked
                        onClick={(e: any) => {
                          const screens: any = [];
                          filterScreensByLatLong(coordinatesWithScreens)[c].screens.map((s: any) => {
                            s.map((c: any) => {
                              if (!screens.includes(c)) {
                                screens.push(c);
                              }
                            })
                          })
                          handleSelectOrRemove(e.target.checked, screens, 2)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="py-2">
              <h1 className="text-xl font-bold py-1">
                3. Select Geo Location Of Competition Establishments:
              </h1>
              <p className="text-sm font-semibold">Download the excel format</p>
              <div>
                <ExcelImport
                  handleGetExcelData={handleGetExcelDataCompt}
                  handleResetFileUpload={handleResetFileUpload1}
                />
                <h1 className="text-sm text-[#1C9EDA] font-semibold">
                  Note: {filter3?.length || 0} screens selected as per your
                  choice
                </h1>
                <div className="flex flex-col ">
                  {Object.keys(filterScreensByLatLong(coordinatesWithScreens1))?.map((c: any, i: any) => (
                    <div key={i} className="flex gap-2 justify-between">
                      <h1 className="text-sky-600 text-sm flex">
                        In{" "}
                        <strong className="text-red-700 px-1">
                          {filterScreensByLatLong(coordinatesWithScreens1)[c].count}
                        </strong>{" "}
                        locations {filterScreensByLatLong(coordinatesWithScreens1)[c].screens[0].length} screens found
                      </h1>
                      <input
                        title="poi"
                        type="checkbox"
                        defaultChecked
                        onClick={(e: any) => {
                          const screens: any = [];
                          filterScreensByLatLong(coordinatesWithScreens1)[c].screens.map((s: any) => {
                            s.map((c: any) => {
                              if (!screens.includes(c)) {
                                screens.push(c);
                              }
                            })
                          })
                          handleSelectOrRemove(e.target.checked, screens, 3)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="py-2">
              <h1 className="text-xl font-bold py-1">
                4. Any Specific Route You Want To Cover In This Campaign:
              </h1>
              <div className="border rounded-lg">
                <div className="">
                  <MapSearchInput
                    placeholder="Select Origin"
                    handleClick={(e: any) => {
                      setRouteOrigin(e);
                    }}
                    // value={routeOrigin.length > 0 ? false : true}
                  />
                </div>
                <hr className="h-px mx-1 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="">
                  <MapSearchInput
                    placeholder="Select Destination"
                    handleClick={(e: any) => {
                      setRouteDestination(e);
                    }}
                    // value={routeDestination.length > 0 ? false : true}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="submit"
                  className="my-3 w-full h-12 rounded-lg bg-gray-200 text-sm font-bold"
                  onClick={() => {
                    handleRouteSetup(routeOrigin, routeDestination);
                  }}
                >
                  Find Route
                </button>
                <button
                  onClick={() => setRoutes([])}
                  className="my-3 w-full h-12 rounded-lg bg-gray-200 text-sm font-bold"
                >
                  Reset Route
                </button>
              </div>

              <div className="overflow-scroll no-scrollbar">
                <table className="border border-collapse">
                  <thead>
                    <tr>
                      <th className="text-sm p-2 border border-gray-200 w-32">
                        ORIGIN
                      </th>
                      <th className="text-sm p-2 border border-gray-200">
                        DESTINATION
                      </th>
                      <th className="text-sm p-1 border border-gray-200">
                        SCREENS
                      </th>
                      <th className="text-sm border border-gray-200">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routes?.map((r: any, i: any) => (
                      <tr key={i}>
                        <td className="p-2 border border-gray-200">
                          <p className="text-sm truncate">
                            {r.origin.place_name}
                          </p>
                        </td>
                        <td className="p-2 border border-gray-200">
                          <p className="text-sm truncate">
                            {r.destination.place_name}
                          </p>
                        </td>
                        <td className="p-2 border border-gray-200">
                          <p className="text-sm truncate">
                            {r.selectedScreens?.length}
                          </p>
                        </td>
                        <td className="p-2 border border-gray-200">
                          <button
                            onClick={() => handleRemoveRoute(r?.id)}
                            className="my-3 w-full h-12 rounded-lg bg-red-200 text-sm font-bold test-white px-4"
                          >
                            Remove{" "}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <h1 className="text-sm text-[#1C9EDA] font-semibold pb-2">
                Note:- The Selected {filter4?.length} Locations are shown in the
                map
              </h1>
            </div>
          </div>
        </div>
        <div className="col-start-7 col-span-12">
          <MapWithGeometry
            handleRouteData={handleRouteData}
            circleRadius={circleRadius}
            screenMarkers={filteredScreens}
            unSelectedScreens={unSelectedScreens}
            routes={routes}
            data={circleData}
          />
        </div>
      </div>
    </div>
  );
};
