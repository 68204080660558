import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePageLayout, PlaylistMediaLayout } from "../components";

import {
  CreateCampaignPage,
  FinalSummaryPage,
  HomePage,
  PageNotFound,
  ScreenDetails,
  ShowScreenPicturesPage,
  SignUp,
  UploadCreativesPage,
  ScreenList,
  UserProfile,
  VerifyEmail,
  ForgetPassword,
  UpdatePassword,
  Campaigns,
  CampaignDetails,
  AddNewUser,
  MyUsers,
  Testing,
  Cart,
  MyCreatives,
  MyPlayList,
} from "../pages";

import { PrivateRoute } from "./PrivateRoute";
import {
  ADD_NEW_USER,
  CAMPAIGNS,
  CAMPAIGNS_LIST,
  CART,
  CREATECAMPAIGN,
  CREATIVES,
  FINALCAMPAIGNSUMMARY,
  FORGET_PASSWORD,
  HOME,
  MY_CREATIVES,
  MY_SCREENS,
  MYPLAYLIST,
  SCREEN_DETAILS,
  SHOWSCREENPICTURES,
  UPDATE_PASSWORD,
  UPLOADCREATIVES,
  USER_PROFILE,
  USERS,
  VERIFY_EMAIL,
} from "./routes";
import CircleAroundCoordinates from "../components/common/map/CircleAroundCoordinates";

const Routers: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={HOME}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path={CREATECAMPAIGN}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <CreateCampaignPage />
            </PrivateRoute>
          }
        />
        <Route
          path={FINALCAMPAIGNSUMMARY}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <FinalSummaryPage />
            </PrivateRoute>
          }
        />
        <Route
          path={SHOWSCREENPICTURES}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <ShowScreenPicturesPage />
            </PrivateRoute>
          }
        />
        <Route
          path={UPLOADCREATIVES}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <UploadCreativesPage />
            </PrivateRoute>
          }
        />
        <Route
          path={VERIFY_EMAIL}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <VerifyEmail />
            </PrivateRoute>
          }
        />
        <Route
          path={FORGET_PASSWORD}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <ForgetPassword />
            </PrivateRoute>
          }
        />
        <Route
          path={UPDATE_PASSWORD}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <UpdatePassword />
            </PrivateRoute>
          }
        />
        <Route
          path={MY_SCREENS}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <ScreenList />
            </PrivateRoute>
          }
        />

        <Route
          path={SCREEN_DETAILS}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <ScreenDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/signin"
          element={
            <PrivateRoute layout={HomePageLayout}>
              <SignUp />
            </PrivateRoute>
          }
        />
        <Route
          path={MYPLAYLIST}
          element={
            <PrivateRoute layout={PlaylistMediaLayout}>
              <MyPlayList />
            </PrivateRoute>
          }
        />
        <Route
          path={USER_PROFILE}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={CAMPAIGNS}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <Campaigns />
            </PrivateRoute>
          }
        />
        <Route
          path={CAMPAIGNS_LIST}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <CampaignDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={ADD_NEW_USER}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <AddNewUser />
            </PrivateRoute>
          }
        />
        <Route
          path={USERS}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <MyUsers />
            </PrivateRoute>
          }
        />
        <Route
          path={MY_CREATIVES}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <MyCreatives />
            </PrivateRoute>
          }
        />
        <Route
          path={CART}
          element={
            <PrivateRoute layout={HomePageLayout}>
              <Cart />
            </PrivateRoute>
          }
        />
        <Route
          path="/testing1"
          element={
            <PrivateRoute layout={HomePageLayout}>
              <CircleAroundCoordinates />
              {/* <CheckPolyGon /> */}
            </PrivateRoute>
          }
        />
        <Route
          path="/testing"
          element={
            <PrivateRoute layout={HomePageLayout}>
              <Testing />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
