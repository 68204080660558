import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeCampaignStatus,
  getCampaignListByCampaignIds,
  getCampaignsTableDataByCampaignIds,
} from "../../actions/campaignAction";
import { useSelector } from "react-redux";
import { UpdateCampaignModel } from "../../components/common/popup/UpdateCampaignModel";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_PAUSE,
  CHANGE_CHAMPAIGN_STATUS_RESET,
} from "../../constants/campaignConstants";
import { confirmData } from "../../data/confirmData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NewCampaignsTable } from "../../components/common/tables/NewCampaignsTable";
import { MyList } from "./MyList";
import { CampaignOperation } from "./CampaignOperation";
import { BackButton, ReloadButton } from "../../components/index";

export const CampaignDetails = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const campaign = location.state.campaign;
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [isUpdateCampaignOpen, setIsUpdateCampaignOpen] = useState<any>(null);

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const campaignListByCampaignIds = useSelector(
    (state: any) => state.campaignListByCampaignIds
  );
  const { loading, error, data: campaigns } = campaignListByCampaignIds;

  const changeCampaignsStatus = useSelector(
    (state: any) => state.changeCampaignsStatus
  );
  const {
    loading: loadingCampaignStatus,
    error: errorChangeCampaignsStatus,
    success: successChangeStatus,
  } = changeCampaignsStatus;

  // campaignsTableData
  const campaignsTableData = useSelector(
    (state: any) => state.campaignsTableData
  );
  const {
    loading: loadingCampaignsTableData,
    error: errorCampaignsTableData,
    data: myCampaignsTableData,
  } = campaignsTableData;

  const getCampaignIdsToChangeStatus = (status: string) => {
    let campaignIds = [];
    let screenIds = [];
    // console.log("campaigns :", campaigns);
    switch (status) {
      case CAMPAIGN_STATUS_ACTIVE:
        campaignIds = campaigns
          .filter((campaign: any) => campaign.status === CAMPAIGN_STATUS_PAUSE)
          .map((campaign: any) => campaign._id);
        screenIds = campaigns
          .filter((campaign: any) => campaign.status === CAMPAIGN_STATUS_PAUSE)
          .map((campaign: any) => campaign.screenId);
        break;
      case CAMPAIGN_STATUS_PAUSE:
        campaignIds = campaigns
          .filter((campaign: any) => campaign.status === CAMPAIGN_STATUS_ACTIVE)
          .map((campaign: any) => campaign._id);
        screenIds = campaigns
          .filter((campaign: any) => campaign.status === CAMPAIGN_STATUS_ACTIVE)
          .map((campaign: any) => campaign.screenId);
        break;
      case CAMPAIGN_STATUS_DELETED:
        campaignIds = campaigns
          .filter(
            (campaign: any) =>
              campaign.status === CAMPAIGN_STATUS_ACTIVE ||
              campaign.status === CAMPAIGN_STATUS_PAUSE
          )
          .map((campaign: any) => campaign._id);
        screenIds = campaigns
          .filter(
            (campaign: any) =>
              campaign.status === CAMPAIGN_STATUS_ACTIVE ||
              campaign.status === CAMPAIGN_STATUS_PAUSE
          )
          .map((campaign: any) => campaign.screenId);
        break;
      default:
        console.log("Default!");
    }
    console.log("getCampaignIdsToChangeStatus ", campaignIds, status);
    return { campaignIds, screenIds: Array.from(new Set(screenIds)), status };
  };

  const handleChangeStatusAll = (status: string) => {
    if (confirm(confirmData[status])) {
      let data = getCampaignIdsToChangeStatus(status);
      if (data?.campaignIds?.length > 0) dispatch(changeCampaignStatus(data));
      else openErrorToast(`You have 0 campaign to ${status}`);
    }
  };

  const closeUpdateCampaignModel = () => {
    setIsUpdateCampaignOpen(false);
  };
  const handleOpenUpdateCampaignModel = () => {
    setIsUpdateCampaignOpen(true);
  };

  useEffect(() => {
    dispatch(getCampaignListByCampaignIds(campaign.campaigns));
    dispatch(getCampaignsTableDataByCampaignIds(campaign.campaigns));
  }, [campaign]);

  useEffect(() => {
    if (successChangeStatus) {
      openSuccessToast("Successfully campaign status changed!");
      dispatch({ type: CHANGE_CHAMPAIGN_STATUS_RESET });
      dispatch(getCampaignListByCampaignIds(campaign.campaigns));
    }
    if (errorChangeCampaignsStatus) {
      openErrorToast(errorChangeCampaignsStatus);
      dispatch({ type: CHANGE_CHAMPAIGN_STATUS_RESET });
    }
  }, [dispatch, successChangeStatus, errorChangeCampaignsStatus]);

  return (
    <div className="gap-2 px-12  mt-10">
      <ToastContainer />
      <div className="flex gap-4">
        <BackButton />
        <ReloadButton
          onClick={() =>
            dispatch(getCampaignListByCampaignIds(campaign.campaigns))
          }
        />
      </div>

      <UpdateCampaignModel
        isOpen={isUpdateCampaignOpen}
        onClose={closeUpdateCampaignModel}
        campaign={selectedCampaign}
        campaignIds={campaign.campaigns}
      />
      <CampaignOperation
        campaign={campaign}
        handleChangeStatusAll={handleChangeStatusAll}
        loading={loading}
      />

      <MyList
        campaignsTableData={myCampaignsTableData}
        loading={loadingCampaignsTableData}
      />

      <NewCampaignsTable
        loading={loading}
        campaigns={campaigns}
        onClick={handleOpenUpdateCampaignModel}
        setSelectedCampaign={setSelectedCampaign}
      />
    </div>
  );
};
