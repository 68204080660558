import {
  convertIntoDateAndTime,
  getTimeDifferenceInMin,
} from "../../utils/dateAndTimeUtils";

export const SingleScreen = ({ screen, handleClick }: any) => {
  const getScreenClassName = (screen: any) => {
    if (screen?.screenCode) {
      if (getTimeDifferenceInMin(screen?.lastActive) < 15)
        return "border w-5 h-5 bg-green-500 rounded-full justify-end";
      else return "border w-5 h-5 bg-yellow-500 rounded-full justify-end";
    } else return "border w-5 h-5 bg-red-500 rounded-full justify-end";
  };

  return (
    <div
      className="flex flex-col border border-1 rounded p-2  hover:shadow-lg"
      key={screen?.screenId}
      title="Click to go screen details"
      onClick={() => handleClick(screen)}
    >
      <div className="flex gap-4">
        <img
          src={screen?.images[0]}
          alt={screen?.screenName}
          className="h-32 w-32"
        />

        <div>
          <div className="flex flex-row gap-2">
            <h1 className="text-md font-bold w-48">{screen?.screenName}</h1>
            <div className={getScreenClassName(screen)} />
          </div>
          <h1 className="text-sm text-blue-700">
            Last Active: {convertIntoDateAndTime(screen?.lastActive)}
          </h1>
        </div>
      </div>
    </div>
  );
};
