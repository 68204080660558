export const SingleScreenDetails = (props: any) => {
  const { screen } = props;
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-8 py-2">
        <div className="py-2 grid grid-cols-12 gap-2">
          <div className="col-span-6 grid grid-cols-8 gap-2">
            <div className="col-span-6 flex items-center justify-center px-2">
              <img
                src={screen?.images?.[0]}
                alt={screen?.screenName || ""}
                className="rounded h-60 w-60"
              />
            </div>
            <div className="col-span-2 flex flex-col justify-center gap-2">
              <img
                src={screen?.images?.[0]}
                alt={screen?.screenName || ""}
                className="rounded h-16 w-20"
              />
              <img
                src={screen?.images?.[0]}
                alt={screen?.screenName || ""}
                className="rounded h-16 w-20"
              />
              <img
                src={screen?.images?.[0]}
                alt={screen?.screenName || ""}
                className="rounded h-16 w-20"
              />
            </div>
          </div>
          <div className="">
            <h1>{screen?.location?.city || ""}</h1>
            <h1>{screen?.location?.state || ""}</h1>
            <h1>{screen?.location?.country || ""}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
