import { useMedia } from "../../../hooks/useMedia";

export const MediaContainer = ({ mediaType, url, height, width, className }: any) => {
  const { data: media, isLoading } = useMedia({
    url: url,
    mediaType: mediaType,
  });
  let contentType = mediaType;
  let ipfsUrl: any = media?.url;

  const IframeContainer = () => (
    <iframe
      className={className}
      src={url}
      title="url"
      height={height} width={width}
    ></iframe>
  );
  const YouTubeContainer = () => (
    <iframe title="youtube" className={className} width={width} height={height} src={ipfsUrl}></iframe>
  );
  const ImageContainer = () => (
    <img src={url} alt="Campaign Image" className={className} height={height} width={width}/>
  );
  const VideoContainer = () => (
    <video className={className} controls height={height} width={width}>
      <source src={url}></source>
    </video>
  );

  const renderContainer = () => {
    switch (contentType) {
      case "image":
        return <ImageContainer />;
      case "video":
        return <VideoContainer />;
      case "iframe":
        return <IframeContainer />;
      default:
        return <YouTubeContainer />;
    }
  };
  return (
    <div>{isLoading ? <h1>Loading..........</h1> : renderContainer()}</div>
  );
};
