import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "./Menu";
import {
  CreateCampaignsFromCreativesModel,
  SaveCreativeModel,
  AddOrModifyScreenCodePopup,
  AddNewButton,
} from "../../../components";
import { getAllScreensByUserId } from "../../../actions/screenDataAction";
import { CREATE_CAMPAIGN_RESET } from "../../../constants/campaignConstants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAllKeyFromLocalStorage } from "../../../utils/localStorageUtils";
import { ADD_SCREEN_CODE_RESET } from "../../../constants/screenDataConstant";
import logo from "../../../assets/logo.png";
import { getCreatives } from "../../../actions/creativeAction";
import { USER_ROLE_PRIMARY } from "../../../constants/userConstants";

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const [isPopupOpen1, setIsPopupOpen1] = useState<boolean>(false);
  const [isPopupOpenScreenCode, setIsPopupOpenScreenCode] =
    useState<boolean>(false);

  const [isOpenSaveCreatives, setIsOpenSaveCreatives] =
    useState<boolean>(false);
  const [screenOptions, setScreenOptions] = useState<any>([]);

  const handleCloseModel = () => {
    setIsOpenSaveCreatives(false);
  };

  const handleCloseModelScreenCode = () => {
    setIsPopupOpenScreenCode(false);
  };

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
  };

  const addScreenCode = useSelector((state: any) => state.addScreenCode);
  const {
    loading: loadingAddScreenCode,
    error: errorAddScreenCode,
    success: successAddScreenCode,
  } = addScreenCode;

  const campaignCreate1 = useSelector((state: any) => state.campaignCreate1);
  const {
    loading: loadingSlotBooking,
    error: errorSlotBooking,
    success: successSlotBooking,
  } = campaignCreate1;

  const allScreensDataByUserId = useSelector(
    (state: any) => state.allScreensDataByUserId
  );
  const { data: screens, loading, error } = allScreensDataByUserId;
  // console.log("screens : ", screens);

  const handleGoToPlanCampaign = () => {
    removeAllKeyFromLocalStorage();
    navigate("/create-campaign");
  };

  const handleSetOpenModel = () => {
    if (userInfo?.userRole == USER_ROLE_PRIMARY) {
      setIsPopupOpen1(true);
    } else {
      openErrorToast("You have no access");
    }
  };

  const handleSetOpenScreenCodeModel = () => {
    if (userInfo?.userRole == USER_ROLE_PRIMARY) {
      setIsPopupOpenScreenCode(true);
    } else {
      openErrorToast("You have no access");
    }
  };

  useEffect(() => {
    console.log("useEffect render");
    if (userInfo) dispatch(getCreatives());
  }, [userInfo]);

  useEffect(() => {
    if (errorAddScreenCode) {
      openErrorToast(errorAddScreenCode);
      dispatch({ type: ADD_SCREEN_CODE_RESET });
    }
    if (successAddScreenCode) {
      openSuccessToast("Successfully updated screen code!");
      dispatch({ type: ADD_SCREEN_CODE_RESET });
    }
  }, [errorAddScreenCode, successAddScreenCode]);

  useEffect(() => {
    if (screens?.length > 0) {
      const screenOptions = screens?.map((screen: any) => {
        return {
          value: screen?._id,
          label: screen?.screenName?.toUpperCase(),
        };
      });
      setScreenOptions(screenOptions);
    }
  }, [screens]);

  useEffect(() => {
    if (errorSlotBooking) {
      openErrorToast(errorSlotBooking);
      dispatch({ type: CREATE_CAMPAIGN_RESET });
    }
    if (successSlotBooking) {
      openSuccessToast("Campaign created successfully!");
      dispatch({ type: CREATE_CAMPAIGN_RESET });
    }
  }, [errorSlotBooking, successSlotBooking, dispatch]);

  useEffect(() => {
    if (userInfo && userInfo.isMaster) {
      dispatch(getAllScreensByUserId());
    }
  }, [dispatch, userInfo]);

  return (
    <div className="w-full h-16 bg-proohYellow flex items-center justify-between fixed z-50">
      <div className="col-span-2 flex items-center mx-20">
        <ToastContainer />
        <CreateCampaignsFromCreativesModel
          isOpen={isPopupOpen1}
          onClose={closePopup1}
          screenOptions={screenOptions}
          screens={screens}
        />
        <SaveCreativeModel
          isOpen={isOpenSaveCreatives}
          onClose={handleCloseModel}
        />
        <AddOrModifyScreenCodePopup
          screens={screens}
          loading={loading}
          isOpen={isPopupOpenScreenCode}
          onClose={handleCloseModelScreenCode}
        />
        <div
          className="flex flex-col mb-2 -space-y-1 pt-2"
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="prooh.ai" className="h-12 w-full" />
          {/* <h1 className="text-3xl font-black -my-0.5">PROOH.AI</h1>
          <h1 className="text-2xs font-bold -my-1">AUDIENCE DRIVEN</h1>
          <h1 className="text-2xs font-bold mb-1">OUTDOOR MEDIA BUYING</h1> */}
        </div>
      </div>
      <div className="col-span-2 flex items-center justify-end">
        <div className="h-10 w-auto flex items-center space-x-2 pr-10">
          {!userInfo ? (
            <button
              type="button"
              title="sign up or sign in"
              className="w-32 h-9 border border-solid px-2 bg-sky-500 rounded-md text-white hover:bg-sky-700"
              onClick={() => navigate("/signin")}
            >
              <h3 className="text-sm text-white antialiased">Sign In</h3>
            </button>
          ) : (
            <div className="flex flex-row gap-2 flex-start mr-32">
              {userInfo?.isBrand ? (
                <button
                  type="button"
                  title="button"
                  className="w-40 h-9 border border-solid px-2 bg-sky-500 rounded-md text-white hover:bg-sky-700"
                  onClick={handleGoToPlanCampaign}
                >
                  <h3 className="text-md text-white antialiased font-bold truncate">
                    Plan Campaign
                  </h3>
                </button>
              ) : (
                screens?.length > 0 &&
                loading === false && (
                  <>
                    <AddNewButton
                      onClick={handleSetOpenModel}
                      label="Campaign"
                    />
                    <AddNewButton
                      onClick={handleSetOpenScreenCodeModel}
                      label="Screen Code"
                    />
                  </>
                )
              )}
              <AddNewButton
                onClick={() => setIsOpenSaveCreatives(true)}
                label="Creative"
              />
              <Menu userInfo={userInfo} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
