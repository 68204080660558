import { Button, Space, Dropdown, Flex, Input, Table } from "antd";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { useEffect, useRef, useState } from "react";
import { MediaContainer } from "../MediaContainer";
import { IoSearchOutline } from "react-icons/io5";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignLog,
  getCompletedCampaignsByScreenId,
} from "../../../actions/campaignAction";
import { SingleCampaignLogReportModel } from "../LogReport";
import { convertIntoDateAndTime } from "../../../utils/dateAndTimeUtils";
import { CAMPAIGN_STATUS_COMPLETED } from "../../../constants/campaignConstants";

interface DataType {
  key: string;
  name: string;
  brandName: number;
  atIndex: [number];
  videoURL: string;
}

type DataIndex = keyof DataType;

export const CompletedCampaignsTale = (props: any) => {
  const { screenName, status, screen } = props;
  const dispatch = useDispatch<any>();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [logReportModalOpen, setLogReportModalOpen] = useState<boolean>(false);
  const [campaignName, setCampaignName] = useState<any>("");

  const campaignLog = useSelector((state: any) => state.campaignLog);
  const {
    loading: loadingCampaignLog,
    error: errorCampaignLog,
    success: successCampaignLog,
    data: campaignLogData,
  } = campaignLog;

  const handleOpenLogReport = (campaignId: any) => {
    dispatch(getCampaignLog(campaignId));
    setLogReportModalOpen(true);
  };

  const handleSelectCampaign = (camp: any) => {
    setCampaignName(camp.name);
    handleOpenLogReport(camp._id);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const completedCampaigns = useSelector(
    (state: any) => state.completedCampaigns
  );
  const {
    loading: loading,
    error: errorCampaigns,
    success: successCampaigns,
    data: campaigns,
  } = completedCampaigns;

  useEffect(() => {
    dispatch(
      getCompletedCampaignsByScreenId(
        screen?.screenId,
        CAMPAIGN_STATUS_COMPLETED
      )
    );
  }, [status, screen]);

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<IoSearchOutline />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <IoSearchOutline style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "Creative",
      dataIndex: "videoURL",
      render: (_: string, camp: any) => (
        <MediaContainer
          className="rounded object-fill h-10 w-20"
          url={camp.videoURL}
          mediaType={camp.fileType?.split("/")[0]}
        />
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "name",
      render: (name: any) => (
        <h1 className="text-blue-400 font-bold text-xl">{name}</h1>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Brand",
      dataIndex: "brandName",
      ...getColumnSearchProps("brandName"),
      sorter: (a: any, b: any) => a.brandName.length - b.brandName.length,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      sorter: (a: any, b: any) => a.brandName.length - b.brandName.length,
      render: (value) => (
        <h1>{value ? convertIntoDateAndTime(value) : "Default"}</h1>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      sorter: (a: any, b: any) => a.brandName.length - b.brandName.length,
      render: (value) => (
        <h1>{value ? convertIntoDateAndTime(value) : "Default"}</h1>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (_, camp) => (
        <div className="flex gap-2">
          <button
            className="p-2 mr-[-0.5rem] text-sm border border-1 hover:bg-sky-700 hover:text-white "
            onClick={() => {
              handleSelectCampaign(camp);
            }}
          >
            Campaign logs
          </button>
        </div>
      ),
    },
  ];

  return (
    <Flex gap="middle" vertical>
      <SingleCampaignLogReportModel
        isOpen={logReportModalOpen}
        onClose={() => setLogReportModalOpen(false)}
        campaignName={campaignName}
        screenName={screenName}
        loading={loadingCampaignLog}
        campaignLogs={campaignLogData?.mediaPlaybackDetails}
        // reloadCampaignLogData={handleOpenLogReport}
      />

      <Table
        columns={columns}
        loading={loading}
        dataSource={campaigns?.map((camp: any) => {
          return { ...camp, key: camp._id, status: props?.status };
        })}
      />
    </Flex>
  );
};
