import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getActiveCampaignsByScreenId } from "../../../actions/campaignAction";
import axios from "axios";
import { MediaContainer } from "../MediaContainer";
import { isNumber } from "../../../utils/valueValidate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SET_CAMPAIGNS_PRIORITY_RESET } from "../../../constants/campaignConstants";
import { SaveButton } from "../Button";

export function SetCampaignsPriority(props: any) {
  const { activeCampaigns, screenId } = props;
  const [widgets, setWidgets] = useState<any>([]);
  const [mapData, setMapData] = useState<any>(new Map());
  const [isLoading, setIsLoading] = useState<any>(false);
  const dispatch = useDispatch<any>();
  const [isManually, setIsManually] = useState<boolean>(false);
  const [campaignWithAtIndex, setCampaignWithAtIndex] = useState<any>({});

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  const campaignsPrioritySet = useSelector(
    (state: any) => state.campaignsPrioritySet
  );
  const {
    loading: loadingPriority,
    error: errorPriority,
    data: successPriority,
  } = campaignsPrioritySet;

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };
  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const closeModel = () => {
    setTimeout(() => {
      props?.onClose();
    }, 100);
  };

  const handleReset = () => {
    openSuccessToast("Success fully updated!");
    // dispatch({ type: SET_CAMPAIGNS_PRIORITY_RESET });
    dispatch(getActiveCampaignsByScreenId(screenId));
    setIsLoading(false);
    setCampaignWithAtIndex(null);
    dispatch(getActiveCampaignsByScreenId(screenId));
    closeModel();
  };
  useEffect(() => {
    if (activeCampaigns?.length > 0) {
      const data: any = {};
      for (let camp of activeCampaigns) {
        data[camp?._id] = camp?.atIndex?.join(",");
      }
      setCampaignWithAtIndex(data);
    }
    setIsManually(false);
  }, [props]);

  const getCampaignDetails = (campId: string) => {
    return activeCampaigns?.find((camp: any) => camp._id == campId);
  };

  const createMapDataValue = (widgets: any) => {
    const data = new Map();
    for (let i = 0; i < widgets.length; i++) {
      let camp: any = JSON.parse(widgets[i]);
      let id = String(camp?._id);
      if (data.has(id)) {
        data.set(id, [...data.get(id), i + 1]);
      } else {
        data.set(id, [i + 1]);
      }
    }
    setMapData(data);
    // console.log([...data.entries()]);
  };

  const addValueinMapData = (camp: any, index: number) => {
    camp = JSON.parse(camp);
    let id = String(camp?._id);
    let data = mapData;
    if (data.has(id)) {
      data.set(id, [...data.get(id), index + 1]);
    } else {
      data.set(id, [index + 1]);
    }
    setMapData(data);
  };

  const validate = () => {
    for (let campId in campaignWithAtIndex) {
      if (campaignWithAtIndex[campId]?.length == 0) {
        return false;
      } else {
        for (let value of campaignWithAtIndex[campId]?.split(",")) {
          if (!isNumber(value)) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const getAtIndexValue = (campaignId: string) => {
    return campaignWithAtIndex[campaignId];
  };

  const saveAtIndeValue = async (data1: any) => {
    // dispatch(setCampaignPriority({ campaignWithAtIndex: data1 }));
    for (let campaignId in data1) {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_PROOH_SERVER}/api/v1/campaigns/setPriority`,
          {
            campaignId: campaignId,
            index: data1[campaignId],
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo?.token}`,
            },
          }
        );
      } catch (error) {
        console.log("Error : ", error);
      }
    }
  };

  const handleSetPriorityManually = async () => {
    setIsLoading(true);
    if (validate()) {
      await saveAtIndeValue(campaignWithAtIndex);
      handleReset();
    } else {
      openErrorToast("Something went wrong, Please check manualy");
      setIsLoading(false);
    }
  };

  // const removeValueFromMapdata = (camp: any, index: number) => {
  //   camp = JSON.parse(camp);
  //   let ppp = mapData;
  //   let dd = ppp.get(String(camp?._id));
  //   dd = dd.filter((value: number) => Number(value) != index + 1);
  //   if (dd.length === 0) {
  //     ppp.delete(String(camp?._id));
  //     // console.log("remove  item in mapdata");
  //   } else {
  //     // console.log("datat item in mapdata");
  //     ppp.set(String(camp?._id), dd);
  //   }
  //   setMapData(ppp);
  // };

  const handleOnDrop = (e: React.DragEvent) => {
    const widgetType = e.dataTransfer.getData("widgetType") as any;
    // console.log("widgetType", widgetType);
    addValueinMapData(widgetType, widgets?.length);
    setWidgets([...widgets, widgetType]);
  };

  const handleRemoveDataFromPlayList = (index: number) => {
    const data = widgets.filter((data: any, i: number) => i != index);
    // const filterData = widgets.filter((data: any, i: number) => i == index);
    // removeValueFromMapdata(filterData[0], index);
    createMapDataValue(data);
    setWidgets(data);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const validateData = () => {
    return mapData.size === activeCampaigns.length;
  };

  const handelAddAtIndex = (atIndex: string, campId: string) => {
    let data = campaignWithAtIndex;
    data[campId] = atIndex;
    setCampaignWithAtIndex(data);
  };

  const handleSetPriority = async () => {
    if (validateData()) {
      setIsLoading(true);
      let x = [...mapData.entries()];
      const data: any = {};
      for (let camp of x) {
        data[camp[0]] = camp[1].join(",");
      }
      await saveAtIndeValue(data);
      handleReset();
    } else {
      openErrorToast("all campaign present atlear one");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errorPriority) {
      openErrorToast(errorPriority);
      dispatch({ type: SET_CAMPAIGNS_PRIORITY_RESET });
      setIsLoading(false);
    }
    if (successPriority) {
      openSuccessToast(successPriority);
      dispatch({ type: SET_CAMPAIGNS_PRIORITY_RESET });
      dispatch(getActiveCampaignsByScreenId(screenId));
      setIsLoading(false);
      setCampaignWithAtIndex(null);
      dispatch(getActiveCampaignsByScreenId(screenId));
      closeModel();
    }
  }, [errorPriority, successPriority]);

  useEffect(() => {
    if (props?.isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [props?.isOpen]);

  useEffect(() => {
    if (activeCampaigns) {
      var activeCampaignsHere: any = [];
      activeCampaigns?.map((c: any) => {
        return c?.atIndex?.map((a: any) =>
          activeCampaignsHere.push({ [a]: c })
        )[0];
        // return c;
      });
      var camp: any = activeCampaignsHere
        .sort((a: any, b: any) => {
          const keyA: any = Object.keys(a)[0]; // Get the key of the first object
          const keyB: any = Object.keys(b)[0]; // Get the key of the second object
          return keyA - keyB; // Compare keys numerically
        })
        ?.map((c: any) => Object.values(c)[0]);

      let myWidgets: any = [];
      myWidgets = camp.map((value: any) => JSON.stringify(value));

      setWidgets(myWidgets);
      createMapDataValue(myWidgets);
    }
  }, [activeCampaigns, props]);

  if (!props?.isOpen) {
    return null;
  }

  const handleOnDrag = (e: React.DragEvent, widgetType: any) => {
    e.dataTransfer.setData("widgetType", JSON.stringify(widgetType));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 ">
      <ToastContainer />
      <div className="bg-white p-4 rounded-lg shadow-lg w-9/12 relative">
        {isLoading && (
          <div className="text-#FFFFFF bg-yellow-500 text-lg justify-center align-center p-2 mr-4">
            <h1>Saving...., wait for some time</h1>
          </div>
        )}

        {isManually ? (
          <div className="flex flex-col gap-4 py-4 h-[20rem] overflow-scroll no-scrollbar">
            {Object.keys(campaignWithAtIndex)?.map(
              (campaignId: any, index: any) => (
                <div className="grid grid-cols-8 gap-4" key={index}>
                  <div className="col-span-2 flex flex-col">
                    <div>
                      <MediaContainer
                        url={getCampaignDetails(campaignId)?.videoURL}
                        mediaType={
                          getCampaignDetails(campaignId)?.fileType?.split(
                            "/"
                          )[0]
                        }
                        className="rounded object-fill h-10 w-20"
                      />
                    </div>
                    <div className="px-2 flex justify-between items-center">
                      <h1 className="text font-semibold">
                        {getCampaignDetails(campaignId)?.name}
                      </h1>
                      {/* <h1 className="text-sm">{campaign.brandName}</h1> */}
                    </div>
                  </div>
                  <div className="col-span-6">
                    <input
                      title="s"
                      type="text"
                      placeholder="Ex : 1,2,3,4 with out white space"
                      defaultValue={getAtIndexValue(campaignId)}
                      onChange={(e) =>
                        handelAddAtIndex(e.target.value, campaignId)
                      }
                      className="border border-gray-300 rounded-sm w-96 h-12 text text-lg text-black-600 p-2"
                    />
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="flex flex-row gap-4">
            <div className="h-[20rem] overflow-scroll no-scrollbar">
              {activeCampaigns?.map((campaign: any, index: any) => (
                <div
                  key={index + 1}
                  className="m-1 border border-1 border-gray gap-2 bg"
                  draggable
                  // bgColor={mapData.has(String(campaign?._id)) ? "" : "red"}
                  onDragStart={(e: any) => handleOnDrag(e, campaign)}
                >
                  <div className="grid p-1">
                    <div>
                      <MediaContainer
                        url={campaign.videoURL}
                        mediaType={campaign.fileType?.split("/")[0]}
                        className="rounded object-fill h-10 w-20"
                      />
                    </div>
                    <div className="px-2 flex justify-between items-center">
                      <h1 className="text font-semibold">{campaign.name}</h1>
                      <h1 className="text-sm">{campaign.brandName}</h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="border border-1 solid red p-2 w-full h-[20rem] overflow-scroll no-scrollbar"
              onDragOver={handleDragOver}
              onDrop={handleOnDrop}
            >
              <div className="grid grid-cols-9 gap-4">
                {widgets?.map((widget: any, index: any) => (
                  <div
                    key={index}
                    className="col-span-3 bg-[#5BBCFF] borderRadius-8 w-full border rounded static"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row gap-2 p-1">
                        <h1 className="text-red">{index + 1}</h1>
                        <h1>{JSON.parse(widget)?.name}</h1>
                      </div>
                      <IoIosCloseCircleOutline
                        size="20px"
                        color="#ffffff"
                        onClick={() => handleRemoveDataFromPlayList(index)}
                      />
                    </div>

                    <MediaContainer
                      url={JSON.parse(widget).videoURL}
                      mediaType={widget.fileType?.split("/")[0]}
                      className="rounded object-fill h-10 w-20"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row gap-4 justify-end pt-4">
          <button
            type="submit"
            title="close"
            className="border rounded p-2 hover:text-gray-700"
            onClick={() => props.onClose()}
          >
            Reset and Close
          </button>
          {isManually ? (
            <button
              type="submit"
              title="Save"
              className="border rounded p-2 hover:text-gray-700"
              onClick={handleSetPriorityManually}
            >
              Save manually
            </button>
          ) : (
            <SaveButton onClick={handleSetPriority} />
          )}
          <button
            type="submit"
            title="switch UI"
            className="border rounded p-2 hover:text-gray-700"
            onClick={() => setIsManually((pre) => !pre)}
          >
            Set priority manually
          </button>
        </div>
      </div>
    </div>
  );
}
