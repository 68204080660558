import React from 'react';
import { CampaignCart } from './CampaignCart';

export const MiddleArea: React.FC = () => {
  const pathName = window.location.pathname.split('/').splice(-1)[0];
  return (
    <div className="mt-6 w-full h-full pb-5">
      <CampaignCart />
    </div>
  );
};
