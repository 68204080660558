import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TbReload } from "react-icons/tb";

export const BackButton = () => {
  console.log("BackButton called!");
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      type="button"
      title="Go Back"
      className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      <div className="flex gap-0 items-center">
        <MdArrowBackIos />
        Back
      </div>
    </button>
  );
};

export const SaveButton = ({ isLoading, onClick, label = "Save" }: any) => {
  return (
    <button
      className="border border-solid rounded p-2 w-32 hover:bg-green-400 hover:text-white"
      onClick={onClick}
      disabled={isLoading}
    >
      {label}
    </button>
  );
};

export const CloseButton = ({ onClick }: any) => {
  return (
    <button
      className="border border-solid rounded p-2 w-32 hover:bg-red-400 hover:text-white"
      onClick={onClick}
    >
      Close
    </button>
  );
};

export const ReloadButton = ({ onClick }: any) => {
  return (
    <button
      title="Reload page"
      className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      onClick={onClick}
    >
      <div className="flex gap-2 items-center">
        <TbReload size="20px" />
        Reload
      </div>
    </button>
  );
};

export const AddNewButton = ({ onClick, label }: any) => {
  return (
    <button
      type="button"
      title="Add New"
      className="w-32 h-9 border border-solid px-2 bg-sky-500 rounded-md text-white hover:bg-sky-700"
      onClick={onClick}
    >
      <h3 className="text-md text-white antialiased font-bold truncate">
        + {label}
      </h3>
    </button>
  );
};
