import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllScreens,
  getScreenIdsGroupByCountry,
} from "../../actions/screenDataAction";
import { MdKeyboardBackspace } from "react-icons/md";
import { FILTERED_SCREENS } from "../../constants/localStorageConstants";

export const MiddleArea: React.FC = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [screens, setScreens] = useState<any[]>([]);
  //   --------------
  const [defCnt, setDefCnt] = useState<string[]>([]);
  const [defSt, setDefSt] = useState<string[]>([]);
  const [defCt, setDefCt] = useState<string[]>([]);
  const [defTP, setDefTP] = useState<string[]>([]);

  const [countryStates, setCountryStates] = useState<any>({});
  const [stateCities, setStateCities] = useState<any>({});
  const [cityTouchpoints, setCityTouchpoints] = useState<any>({});
  const [touchpointsCities, setTouchpointsCities] = useState<any>({});

  const allScreensIdsByCountryGet = useSelector(
    (state: any) => state.allScreensIdsByCountryGet
  );
  const { loading, error, data } = allScreensIdsByCountryGet;

  console.log(data);
  const allScreens = JSON.parse(
    window.localStorage.getItem(FILTERED_SCREENS) || "[]"
  );

  const getTotalCountryCount = () => {
    return Object.keys(data)?.length || 0;
  };

  const getTotalStatesCounts = () => {
    let ans = 0;
    if (defCnt?.length === 0) {
      for (let cnt in data) {
        let statesCount = Object.keys(data[cnt])?.length || 0;
        ans += statesCount;
      }
    } else {
      for (let cnt of defCnt) {
        let statesCount = Object.keys(data[cnt])?.length || 0;
        ans += statesCount;
      }
    }
    return ans;
  };

  const getTotalTouchPointsCount = () => {
    return Object.keys(touchpointsCities)?.length || 0;
  };
  const getTotalCityCount = () => {
    return Object.keys(cityTouchpoints)?.length || 0;
  };



  const handleSetDefCity = (type: boolean, city: string) => {
    let cities = defCt;
    if (type) {
      cities.push(city);
      cities = Array.from(new Set(cities));
      setDefCt(cities);
    } else {
      cities = cities.filter((ct: string) => ct != city);
      setDefCt(cities);
    }
  };

  const handleSetDefTouchPoints = (type: boolean, tp: string) => {
    let tps = defTP;
    if (type) {
      tps.push(tp);
      tps = Array.from(new Set(tps));
      setDefTP(tps);
    } else {
      tps = tps.filter((ct: string) => ct != tp);
      setDefTP(tps);
    }
  };


  const filterDataAccording = () => {
    let result: string[] = [];
    for (let cnt of defCnt) {
      // getting all state of this country
      for (let state of Object.keys(data[cnt])) {
        // now i am checking this state belonge to defStat or not
        // console.log("def state  : ", defSt, state);
        if (defSt.includes(state)) {
          // if yes
          // find all city of this state
          for (let city in data[cnt][state]) {
            // check this city belonf to defaultCity or not
            // console.log("def city  : ", defCt, city);

            if (defCt.includes(city)) {
              // find its all touchPonts of city
              for (let tp in data[cnt][state][city]) {
                // console.log("def tp  : ", defTP, tp);

                // now check this tp present of not in defTP
                if (defTP.includes(tp)) {
                  result = [...result, ...data[cnt][state][city][tp]];
                }
              }
            }
          }
        }
      }
    }
    let filteredScreens = [];

    if (allScreens?.length > 0) {
      for (let screen of allScreens) {
        if (result.includes(screen.screenId)) {
          filteredScreens.push(screen);
        }
      }
    }

    if (filteredScreens.length > 0) {
      setScreens(filteredScreens);
    }
    if (result.length == 0) {
      setScreens(allScreens || []);
    }
  };

  const handleCntClick = (country: any) => {
    let x = defCnt;
    if (!defCnt.includes(country)) {
      x = [...defCnt, country];
      setDefCnt([...defCnt, country]);
    } else {
      // remove this contry from defCnt
      x = x.filter((value: string) => country != value);
      setDefCnt(x);
    }
    const dfc = Array.from(new Set([...x]));

    const dataToShow: any = {};

    dfc.map((d: any) => {
      dataToShow[d] = data[d];
    });

    let xx = ((myData: any) => {
      const cs: any = {};
      const sc: any = {};
      const ct: any = {};
      const tc: any = {};

      // Extracting data for cs
      for (const country in myData) {
        cs[country] = cs[country] || {};
        for (const state in myData[country]) {
          sc[state] = sc[state] || {};
          cs[country][state] = Object.keys(myData[country][state]).length;
          for (const city in myData[country][state]) {
            ct[city] = ct[city] || {};
            sc[state][city] = Object.keys(myData[country][state][city]).length;
            for (const attribute in myData[country][state][city]) {
              tc[attribute] = tc[attribute] || {};
              ct[city][attribute] = myData[country][state][city][attribute];
              tc[attribute][city] = myData[country][state][city][attribute];
            }
          }
        }
      }
      const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
      );
      const sortedObject = Object.fromEntries(sortedArray);
      console.log(cs);
      return { cs, sc, ct, sortedObject };
    })(dataToShow).sc;
    const yy = ((myData: any) => {
      const cs: any = {};
      const sc: any = {};
      const ct: any = {};
      const tc: any = {};

      // Extracting data for cs
      for (const country in myData) {
        cs[country] = cs[country] || {};
        for (const state in myData[country]) {
          sc[state] = sc[state] || {};
          cs[country][state] = Object.keys(myData[country][state]).length;
          for (const city in myData[country][state]) {
            ct[city] = ct[city] || {};
            sc[state][city] = Object.keys(myData[country][state][city]).length;
            for (const attribute in myData[country][state][city]) {
              tc[attribute] = tc[attribute] || {};
              ct[city][attribute] = myData[country][state][city][attribute];
              tc[attribute][city] = myData[country][state][city][attribute];
            }
          }
        }
      }
      const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
      );
      const sortedObject = Object.fromEntries(sortedArray);
      console.log(cs);
      return { cs, sc, ct, sortedObject };
    })(dataToShow).ct;
    const zz = ((myData: any) => {
      const cs: any = {};
      const sc: any = {};
      const ct: any = {};
      const tc: any = {};

      // Extracting data for cs
      for (const country in myData) {
        cs[country] = cs[country] || {};
        for (const state in myData[country]) {
          sc[state] = sc[state] || {};
          cs[country][state] = Object.keys(myData[country][state]).length;
          for (const city in myData[country][state]) {
            ct[city] = ct[city] || {};
            sc[state][city] = Object.keys(myData[country][state][city]).length;
            for (const attribute in myData[country][state][city]) {
              tc[attribute] = tc[attribute] || {};
              ct[city][attribute] = myData[country][state][city][attribute];
              tc[attribute][city] = myData[country][state][city][attribute];
            }
          }
        }
      }
      const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
      );
      const sortedObject = Object.fromEntries(sortedArray);
      console.log(cs);
      return { cs, sc, ct, sortedObject };
    })(dataToShow).sortedObject;

    setStateCities(xx);
    setDefSt(Object.keys(xx));
    setCityTouchpoints(yy);
    setDefCt(Object.keys(yy));
    setTouchpointsCities(zz);
    setDefTP(Object.keys(zz));
  };

  const handleStClick = (state: any) => {
    let x = defSt;
    if (!defSt.includes(state)) {
      x = [...defSt, state];
      setDefSt([...defSt, state]);
    } else {
      // remove this contry from defCnt
      x = x.filter((value: string) => state != value);
      setDefSt(x);
    }
    const dfs = Array.from(new Set(x));
    const dataToUse: any = {};
    dfs.map((d: any) => {
      dataToUse[d] = stateCities[d];
    });
    const dataToShow: any = {};

    for (const state in dataToUse) {
      for (const city in dataToUse[state]) {
        dataToShow[city] = ((myData: any) => {
          const cs: any = {};
          const sc: any = {};
          const ct: any = {};
          const tc: any = {};

          // Extracting data for cs
          for (const country in myData) {
            cs[country] = cs[country] || {};
            for (const state in myData[country]) {
              sc[state] = sc[state] || {};
              cs[country][state] = Object.keys(myData[country][state]).length;
              for (const city in myData[country][state]) {
                ct[city] = ct[city] || {};
                sc[state][city] = Object.keys(myData[country][state][city]).length;
                for (const attribute in myData[country][state][city]) {
                  tc[attribute] = tc[attribute] || {};
                  ct[city][attribute] = myData[country][state][city][attribute];
                  tc[attribute][city] = myData[country][state][city][attribute];
                }
              }
            }
          }
          const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
          );
          const sortedObject = Object.fromEntries(sortedArray);
          console.log(cs);
          return { cs, sc, ct, sortedObject };
        })(data).cs[city];
      }
    }

    setCityTouchpoints(dataToShow);
    setDefCt(Object.keys(dataToShow));
    setDefTP(Object.keys(touchpointsCities));
  };

  useEffect(() => {
    filterDataAccording();

    dispatch(getAllScreens());
    dispatch(getScreenIdsGroupByCountry());
  }, [dispatch]);

  useEffect(() => {
    if (data !== undefined) {
      setCountryStates(((myData: any) => {
          const cs: any = {};
          const sc: any = {};
          const ct: any = {};
          const tc: any = {};

          // Extracting data for cs
          for (const country in myData) {
            cs[country] = cs[country] || {};
            for (const state in myData[country]) {
              sc[state] = sc[state] || {};
              cs[country][state] = Object.keys(myData[country][state]).length;
              for (const city in myData[country][state]) {
                ct[city] = ct[city] || {};
                sc[state][city] = Object.keys(myData[country][state][city]).length;
                for (const attribute in myData[country][state][city]) {
                  tc[attribute] = tc[attribute] || {};
                  ct[city][attribute] = myData[country][state][city][attribute];
                  tc[attribute][city] = myData[country][state][city][attribute];
                }
              }
            }
          }
          const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
          );
          const sortedObject = Object.fromEntries(sortedArray);
          console.log(cs);
          return { cs, sc, ct, sortedObject };
        })(data).cs);
      setStateCities(((myData: any) => {
          const cs: any = {};
          const sc: any = {};
          const ct: any = {};
          const tc: any = {};

          // Extracting data for cs
          for (const country in myData) {
            cs[country] = cs[country] || {};
            for (const state in myData[country]) {
              sc[state] = sc[state] || {};
              cs[country][state] = Object.keys(myData[country][state]).length;
              for (const city in myData[country][state]) {
                ct[city] = ct[city] || {};
                sc[state][city] = Object.keys(myData[country][state][city]).length;
                for (const attribute in myData[country][state][city]) {
                  tc[attribute] = tc[attribute] || {};
                  ct[city][attribute] = myData[country][state][city][attribute];
                  tc[attribute][city] = myData[country][state][city][attribute];
                }
              }
            }
          }
          const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
          );
          const sortedObject = Object.fromEntries(sortedArray);
          console.log(cs);
          return { cs, sc, ct, sortedObject };
        })(data).sc);
      setCityTouchpoints(((myData: any) => {
          const cs: any = {};
          const sc: any = {};
          const ct: any = {};
          const tc: any = {};

          // Extracting data for cs
          for (const country in myData) {
            cs[country] = cs[country] || {};
            for (const state in myData[country]) {
              sc[state] = sc[state] || {};
              cs[country][state] = Object.keys(myData[country][state]).length;
              for (const city in myData[country][state]) {
                ct[city] = ct[city] || {};
                sc[state][city] = Object.keys(myData[country][state][city]).length;
                for (const attribute in myData[country][state][city]) {
                  tc[attribute] = tc[attribute] || {};
                  ct[city][attribute] = myData[country][state][city][attribute];
                  tc[attribute][city] = myData[country][state][city][attribute];
                }
              }
            }
          }
          const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
          );
          const sortedObject = Object.fromEntries(sortedArray);
          console.log(cs);
          return { cs, sc, ct, sortedObject };
        })(data).ct);
      setTouchpointsCities(((myData: any) => {
          const cs: any = {};
          const sc: any = {};
          const ct: any = {};
          const tc: any = {};

          // Extracting data for cs
          for (const country in myData) {
            cs[country] = cs[country] || {};
            for (const state in myData[country]) {
              sc[state] = sc[state] || {};
              cs[country][state] = Object.keys(myData[country][state]).length;
              for (const city in myData[country][state]) {
                ct[city] = ct[city] || {};
                sc[state][city] = Object.keys(myData[country][state][city]).length;
                for (const attribute in myData[country][state][city]) {
                  tc[attribute] = tc[attribute] || {};
                  ct[city][attribute] = myData[country][state][city][attribute];
                  tc[attribute][city] = myData[country][state][city][attribute];
                }
              }
            }
          }
          const sortedArray = Object.entries(tc).sort((a, b) => a[0].localeCompare(b[0])
          );
          const sortedObject = Object.fromEntries(sortedArray);
          console.log(cs);
          return { cs, sc, ct, sortedObject };
        })(data).sortedObject);
    }
  }, [data]);

  console.log(screens);
  return (
    <div className="mt-6">
      <div className="flex flex-row gap-5 items-center pt-5">
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdKeyboardBackspace size="24px" />
        </div>
        <h1 className="text-3xl font-semibold">
          We are showing you the images of selected screens
        </h1>
      </div>
      <div>
        <h1 className="text-base py-3">
          This shall help you decide cohort spacio-temporal of human mobility
          and preferences/urban geo-social connectivity.
        </h1>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-3 divide-x py-2 border border-proohBorderGray-300 rounded">
          {data != undefined && (
            <div className="col-span-3 px-2 overflow-scroll no-scrollbar">
              <div className="flex flex-col">
                <h1 className="px-2 pt-2 text-xl font-bold">Filter</h1>
                <h2 className="px-2 text-sm">showing {screens.length} screens</h2>
                <div className="h-[50rem] overflow-scroll no-scrollbar">
                  <div className="p-2">
                    <h2 className="text-lg font-bold">
                      Countries ({getTotalCountryCount()})
                    </h2>
                    <div className="flex flex-col">
                      {Object.keys(data)?.map((cn: string, i: number) => (
                        <div
                          className="flex flex-row gap-2"
                          key={i}
                          onClick={() => {
                            handleCntClick(cn);
                          }}
                        >
                          <input
                            // onChange={() => {}}
                            title="vehicle4"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={cn}
                            checked={defCnt.includes(cn) ? true : false}
                          />
                          <label className="py-1 text-sm font-semibold">
                            {cn}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {defCnt?.length > 0 && (
                    <div className="">
                      <div className="p-2">
                        <h2 className="text-lg font-bold">
                          States ({getTotalStatesCounts()})
                        </h2>
                        <div className="flex flex-col">
                          {Object.keys(stateCities).map((st: any, i: any) => (
                            <div
                              className="flex flex-row gap-2"
                              key={i}
                              onClick={() => {
                                handleStClick(st);
                              }}
                            >
                              <input
                                title="vehicle4"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value={st}
                                checked={defSt.includes(st) ? true : false}
                              />
                              <label className="py-1 text-sm font-semibold">
                                {st}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="p-2">
                        <h2 className="text-lg font-bold">
                          Cities ({getTotalCityCount()})
                        </h2>
                        <div className="flex flex-col">
                          {Object.keys(cityTouchpoints).map(
                            (c: any, i: any) => (
                              <div className="flex flex-row gap-2" key={i}>
                                <input
                                  title="vehicle4"
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value={c}
                                  onChange={(e) =>
                                    handleSetDefCity(e.target.checked, c)
                                  }
                                  checked={defCt.includes(c) ? true : false}
                                />
                                <label className="py-1 text-sm font-semibold">
                                  {c}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="p-2">
                        <h2 className="text-lg font-bold">
                          Touchpoints ({getTotalTouchPointsCount()})
                        </h2>
                        <div className="flex flex-col">
                          {Object.keys(touchpointsCities).map(
                            (tp: any, i: any) => (
                              <div className="flex flex-row gap-2" key={i}>
                                <input
                                  title="vehicle4"
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value={tp}
                                  onChange={(e) =>
                                    handleSetDefTouchPoints(
                                      e.target.checked,
                                      tp
                                    )
                                  }
                                  checked={defTP.includes(tp) ? true : false}
                                />
                                <label className="py-1 text-sm font-semibold">
                                  {tp}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-9 px-2">
          {screens?.length > 0 && (
            <div className="grid grid-cols-3 gap-4 overflow-scroll no-scrollbar">
              {screens.map((screen: any, i: any) => (
                <div key={i} className="w-full flex items-center">
                  {screen.images.map((image: any, j: any) => (
                    <div key={j} className="">
                      <img
                        className="rounded-lg"
                        src={image}
                        alt={screen.screenName}
                      />
                      <p className="text-sm font-semibold pt-1 truncate">
                        {screen.screenName}
                      </p>
                      <p className="text-sm pb-1 truncate">{screen.highlights}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
