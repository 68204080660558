import "./index.css";
import { IoMenu } from "react-icons/io5";

export const Options = (props: any) => {
  const {
    setIsScreenLogReportOpen,
    getScreenLogs,
    handleSetOpenModel,
    handleSetOpenModel2,
    handleSetPriorityByOneClick,
    setOpenPriorityModal,
  } = props;

  return (
    <div className="dropdown">
      <button className="w-full h-9 border border-solid px-2  bg-sky-500 rounded-md text-white hover:bg-sky-700">
        Options
      </button>
      <div className="dropdown-content">
        <div
          onClick={() => {
            setIsScreenLogReportOpen(true);
            getScreenLogs();
          }}
          className="flex flex-row gap-4 border border-1 items-center py-2 px-2 hover:bg-sky-600 hover:text-white"
        >
          <h1 className="text-black-1000">Screen Logs</h1>
        </div>
        <div
          onClick={handleSetOpenModel}
          className="flex flex-row gap-4 border border-1 items-center py-2 px-2 hover:bg-sky-600 hover:text-white"
        >
          <h1 className="text-black-1000">Create Campaign</h1>
        </div>
        <div
          onClick={handleSetOpenModel2}
          className="flex flex-row gap-4 border border-1 items-center py-2 px-2 hover:bg-sky-600 hover:text-white"
        >
          <h1 className="text-black-1000">Create Campaign By Creatives</h1>
        </div>
        <div
          onClick={handleSetPriorityByOneClick}
          className="flex flex-row gap-4 border border-1 items-center py-2 px-2 hover:bg-sky-600 hover:text-white"
        >
          <h1 className="text-black-1000">Campaign Priority In One Click</h1>
        </div>
        <div
          onClick={() => setOpenPriorityModal(true)}
          className="flex flex-row gap-4 border border-1 items-center py-2 px-2 hover:bg-sky-600 hover:text-white"
        >
          <h1 className="text-black-1000">Open Priority Model</h1>
        </div>
      </div>
    </div>
  );
};
