import { useRef, ChangeEvent, useState } from "react";
import { readExcelFile } from "../../../utils/excelUtils";
import { MdOutlineUploadFile, MdArrowDropDown } from "react-icons/md";

export function ExcelImport(props: any) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleResetFileUpload = () => {
    setFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ""; // Clear the file input value
    }

    props.handleResetFileUpload();
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(event.target.files?.[0]);
    if (file) {
      try {
        const data = await readExcelFile(file);
        props?.handleGetExcelData(data);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };
  return (
    <div className="flex flex-row gap-4 w-full py-2">
      <div
        className="border w-full rounded-md flex justify-end items-center p-1"
        onClick={handleClick}
      >
        <div className="w-full">
          {file ? (
            <h1 className="text-sm text-gray-400 px-2">{file?.name}</h1>
          ) : (
            <h1 className="text-sm text-gray-400 px-2">Click to upload</h1>
          )}
        </div>
        <div className="border bg-gray-100 w-28 px-1 rounded-3xl flex flex-row justify-center items-center">
          <MdOutlineUploadFile />
          <button
            type="submit"
            title="upload"
            className="p-2 mr-[-0.5rem] text-sm"
          >
            Upload
          </button>
          <MdArrowDropDown />
        </div>

        <input
          title="file"
          type="file"
          accept=".xlsx, .xls"
          ref={hiddenFileInput}
          style={{ display: "none" }} // Make the file input element invisible
          multiple={false}
          onChange={handleFileUpload}
        />
      </div>
      {file !== null && (
        <button
          className="my-3 w-48 h-8 rounded-lg bg-red-200 text-sm font-bold test-white px-2"
          onClick={() => handleResetFileUpload()}
        >
          Reset filter
        </button>
      )}
    </div>
  );
}
