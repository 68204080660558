import { Button, Space, Dropdown, Flex, Input, Table } from "antd";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { useRef, useState } from "react";
import { MediaContainer } from "../MediaContainer";
import { IoSearchOutline } from "react-icons/io5";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignLog } from "../../../actions/campaignAction";
import { SingleCampaignLogReportModel } from "../LogReport";
import { FaEdit } from "react-icons/fa";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_HOLD,
  CAMPAIGN_STATUS_PAUSE,
} from "../../../constants/campaignConstants";

interface DataType {
  key: string;
  name: string;
  brandName: number;
  atIndex: [number];
  videoURL: string;
  screenLength: string;
  screenWidth: string;
  screenResolution: string;
}

type DataIndex = keyof DataType;

export const NewCampaignsTable = (props: any) => {
  const { items, setSelectedCampaigns, selectedCampaigns } = props;
  const dispatch = useDispatch<any>();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [logReportModalOpen, setLogReportModalOpen] = useState<boolean>(false);
  const [campaignName, setCampaignName] = useState<any>("");
  const [screenName, setScreenName] = useState<any>("");

  const campaignLog = useSelector((state: any) => state.campaignLog);
  const {
    loading: loadingCampaignLog,
    error: errorCampaignLog,
    success: successCampaignLog,
    data: campaignLogData,
  } = campaignLog;

  const handleOpenLogReport = (campaignId: any) => {
    dispatch(getCampaignLog(campaignId));
    setLogReportModalOpen(true);
  };

  const handleSelectCampaign = (camp: any) => {
    setCampaignName(camp.name);
    setScreenName(camp.screenName);
    handleOpenLogReport(camp._id);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedCampaigns(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedCampaigns,
    onChange: onSelectChange,
  };

  const handleClick = (camp: any) => {
    props.setSelectedCampaign(camp);
    props.onClick();
  };

  const isValidToUpdateCampaign = (campaign: any) => {
    return [
      CAMPAIGN_STATUS_ACTIVE,
      CAMPAIGN_STATUS_PAUSE,
      CAMPAIGN_STATUS_HOLD,
    ].includes(campaign?.status);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<IoSearchOutline />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <IoSearchOutline style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "Creative",
      dataIndex: "videoURL",
      render: (_: string, camp: any) => (
        <MediaContainer
          className="rounded object-fill h-10 w-20"
          url={camp.videoURL}
          mediaType={camp.fileType?.split("/")[0]}
        />
      ),
    },
    {
      title: "Screen Name",
      dataIndex: "screenName",
      render: (name: any) => (
        <h1 className="text-blue-400 font-bold text-lg">{name}</h1>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (_, record) => (
        <h1>
          {record?.screenLength} X {record?.screenWidth}{" "}
        </h1>
      ),
    },
    {
      title: "Resolution",
      dataIndex: "screenResolution",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: any) => (
        <h1
          className={
            status === "PleaRequestBudgetSent"
              ? "text-purple-400"
              : status === "PleaRequestBudgetAccepted"
              ? "text-blue-400"
              : status === "PleaRequestBudgetRejected"
              ? "text-red-400"
              : status === "PleaRequestScreenApprovalSent"
              ? "text-purple-800"
              : status === "PleaRequestScreenApprovalAccepted"
              ? "text-blue-800"
              : status === "PleaRequestScreenApprovalRejected"
              ? "text-red-800"
              : status == "Active"
              ? "text-green-400 font-bold text-lg"
              : status == "Pause"
              ? "text-yellow-700 font-bold text-lg"
              : "text-red-600 font-bold text-lg"
          }
        >
          {status === "PleaRequestBudgetSent"
            ? "Budget Approval Pending"
            : status === "PleaRequestBudgetAccepted"
            ? "Budget Approved"
            : status === "PleaRequestBudgetRejected"
            ? "Budget Rejected"
            : status === "PleaRequestScreenApprovalSent"
            ? "Screen Approval Pending"
            : status === "PleaRequestScreenApprovalAccepted"
            ? "Screen Approved"
            : status === "PleaRequestScreenApprovalRejected"
            ? "Screen Rejected"
            : status}
        </h1>
      ),
    },

    {
      title: "Action",
      dataIndex: "",
      render: (_, camp) => (
        <div className="flex items-center gap-4">
          <button
            className="p-2 mr-[-0.5rem] text-sm border border-1 hover:bg-sky-700 hover:text-white "
            onClick={() => {
              handleSelectCampaign(camp);
            }}
          >
            Campaign logs
          </button>
          {isValidToUpdateCampaign(camp) && (
            <FaEdit
              onClick={() => {
                handleClick(camp);
              }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="overflow-scroll">
      <SingleCampaignLogReportModel
        isOpen={logReportModalOpen}
        onClose={() => setLogReportModalOpen(false)}
        campaignName={campaignName}
        screenName={screenName}
        loading={loadingCampaignLog}
        campaignLogs={campaignLogData?.mediaPlaybackDetails}
        // reloadCampaignLogData={handleOpenLogReport}
      />
      <Table
        columns={columns}
        loading={props?.loading}
        dataSource={props?.campaigns?.map((item: any) => ({
          ...item,
          key: item._id || item.key, // Ensure each item has a unique key
        }))}
      />
    </div>
  );
};
