import { useEffect } from "react";
import { getAllScreensByUserId } from "../../actions/screenDataAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, Loading, ReloadButton } from "../../components";
import { SIGNIN } from "../../routes/routes";
import { SingleScreen } from "./SingleScreen";

export const MiddleArea = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const allScreensDataByUserId = useSelector(
    (state: any) => state.allScreensDataByUserId
  );
  const { data: screens, loading, error } = allScreensDataByUserId;

  const auth = useSelector((state: any) => state.auth);
  const { userInfo } = auth;

  useEffect(() => {
    if (error) console.log("Error in finding all screens");

    if (!userInfo) {
      navigate(SIGNIN);
    } else if (userInfo.isMaster === false) {
      alert("you have no access");
      navigate("/");
    }
  }, [error, userInfo]);

  useEffect(() => {
    if (!screens) dispatch(getAllScreensByUserId());
  }, [dispatch]);

  const handleClick = (screen: any) => {
    navigate(`/screens/details?screen=${screen.screenId}`, {
      state: {
        screen: screen,
        screenId: screen?.screenId,
        screens: screens,
      },
    });
  };

  const reLoad = () => {
    dispatch(getAllScreensByUserId());
  };

  return (
    <div className="">
      <div className="mt-10 flex gap-4 items-center">
        <h1 className="text-lg font-bold">Screen List ({screens?.length})</h1>
        <BackButton />
        <ReloadButton onClick={reLoad} />
      </div>

      <div className="flex gap-4 py-4">
        <div className="flex flex-row gap-2 items-center">
          <div className="border w-5 h-5 bg-green-500 rounded-full justify-end"></div>
          <h1>Sync and active</h1>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="border w-5 h-5 bg-yellow-500 rounded-full justify-end"></div>
          <h1>Sync and not active currently</h1>
        </div>{" "}
        <div className="flex flex-row gap-2 items-center">
          <div className="border w-5 h-5 bg-red-500 rounded-full justify-end"></div>
          <h1>Not sync yet</h1>
        </div>
      </div>
      {loading && <Loading />}
      <div className="flex flex-wrap gap-4">
        {screens?.length > 0 &&
          screens?.map((screen: any, index: any) => (
            <SingleScreen
              handleClick={handleClick}
              screen={screen}
              key={index}
            />
          ))}
      </div>
    </div>
  );
};
