import React, { useState, useEffect, useRef } from "react";
import "./index.css";

// Mocked data object
const data = {
  screenId: "6666d84ebb091c97e0922979",
  Active: [
    {
      screenId: "6666d84ebb091c97e0922979",
      campaignId: "668f9fb60891af7c7e41adb9",
      mediaId: "668f9faf0891af7c7e41ad99",
      url: "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/668f9faf0891af7c7e41ad99.mp4",
      name: "668f9faf0891af7c7e41ad99.mp4",
      duration: "15.015",
      fileSize: 8272356,
      fileType: "video",
      atIndex: [5],
    },
    {
      screenId: "6666s84ebb091c97e0922979",
      campaignId: "668f9fc10891af7c7e41adc2",
      mediaId: "668f9fb60891af7c7e41ad9a",
      url: "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/668f9fb60891af7c7e41ad9a.jpeg",
      name: "668f9fb60891af7c7e41ad9a.jpeg",
      duration: "12",
      fileSize: 10593,
      fileType: "image",
      atIndex: [7],
    },
    {
      screenId: "6656d84ebb091c97e0922979",
      campaignId: "668f9fb60891af7c7e41adb9",
      mediaId: "668f9faf0891af7c7e41ad99",
      // url: "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/668f9faf0891af7c7e41ad99.mp4",
      url: "https://store-files-in-s3.s3.ap-south-1.amazonaws.com/66c44138a8937cdfda5992bc.mp4",
      name: "668f9faf0891af7c7e41ad99.mp4",
      duration: "15.015",
      fileSize: 8272356,
      fileType: "video",
      atIndex: [3, 4],
    },
    {
      screenId: "6666d84ebb091c97e0922979",
      campaignId: "668f9fc10891af7c7e41adc2",
      mediaId: "668f9fb60891af7c7e41ad9a",
      url: "https://pub.movingwalls.com/sdk/lmx-deal.html?refId=MYS-MOV-D-00000-10358&filler=https://cdn.movingwalls.com/filler_1686678872215_glxy_10sec.mp4&deal=GD-00000-02004&lineItemId=64ed95e46157530b14ce8222",
      name: "668f9fb60891af7c7e41ad9a.jpeg",
      duration: "15",
      fileSize: 0,
      fileType: "url",
      atIndex: [1],
    },
  ],
  Pause: [],
  Hold: [],
};

export function MyPlayList() {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mediaQueue, setMediaQueue] = useState([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  useEffect(() => {
    if (data.Active.length > 0) {
      // Create a sorted media queue based on atIndex
      const sortedMedia: any = data.Active.flatMap((media) =>
        media.atIndex.map((index) => ({ ...media, index }))
      ).sort((a, b) => a.index - b.index);
      console.log("sortedMedia : ", sortedMedia);
      setMediaQueue(sortedMedia);
    }
  }, []);

  useEffect(() => {
    if (mediaQueue.length > 0) {
      const currentMedia: any = mediaQueue[currentMediaIndex];
      if (currentMedia.fileType === "video" && videoRef.current) {
        videoRef.current.play();
      }
    }
  }, [currentMediaIndex, mediaQueue]);

  const handleMediaEnd = () => {
    const nextIndex = (currentMediaIndex + 1) % mediaQueue.length;
    setCurrentMediaIndex(nextIndex);
  };

  const renderMedia = () => {
    if (mediaQueue.length === 0) return null;
    const currentMedia: any = mediaQueue[currentMediaIndex];

    switch (currentMedia?.fileType) {
      case "video":
        return (
          <video
            className="media-fullscreen"
            key={currentMedia?.mediaId}
            ref={videoRef}
            src={currentMedia?.url}
            autoPlay
            muted
            onEnded={handleMediaEnd}
          />
        );
      case "image":
        return (
          <img
            className="media-fullscreen"
            key={currentMedia?.mediaId}
            src={currentMedia?.url}
            alt={currentMedia?.name}
            onLoad={() => {
              setTimeout(() => {
                handleMediaEnd();
              }, currentMedia?.duration * 1000);
            }}
          />
        );
      case "url":
        return (
          <iframe
            className="media-fullscreen"
            key={currentMedia.mediaId}
            src={currentMedia.url}
            title="iframe-content"
            onLoad={() => {
              setTimeout(() => {
                handleMediaEnd();
              }, currentMedia.duration * 1000);
            }}
          />
        );
      default:
        return null;
    }
  };

  return <div className="main-container">{renderMedia()}</div>;
}
