import { addOrModifyScreenCode } from "../../../actions/screenDataAction";
import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function AddOrModifyScreenCodePopup(props: any) {
  const { screens, isOpen, onClose, loading } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  useEffect(() => {}, [props?.isOpen]);

  if (!props?.isOpen) {
    return null;
  }

  const handelDiscard = () => {
    props.onClose();
  };

  const onFinishFailed = (error: any) => {
    console.log(error);
  };

  const onFinish = (values: any) => {
    dispatch(addOrModifyScreenCode(values));
    handelDiscard();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={props.onClose}
      title="Add Screen Code"
      footer={[]}
    >
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        fields={[
          {
            name: ["screenId"],
            value: "",
          },
          {
            name: ["screenCode"],
            value: "",
          },
        ]}
      >
        <Form.Item
          label="Select Screen"
          name="screenId"
          rules={[
            {
              required: true,
              message: "Please select screen name!",
            },
          ]}
        >
          <Select
            size="middle"
            style={{ width: "100%", height: "" }}
            options={screens?.map((screen: any) => {
              return {
                value: screen?.screenId,
                label: screen?.screenName,
              };
            })}
            loading={loading}
            showSearch
            placeholder="Search screen by name to select"
            optionFilterProp="children"
            // maxTagCount="responsive"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .includes((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Screen Code"
          name="screenCode"
          rules={[
            {
              required: true,
              message: "Please input screen code!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-4">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={handelDiscard}>Close</Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
