import { useQuery } from "react-query";
// import axios from "../services/axios";

interface Props {
  url: string;
  mediaType: string;
}

const fetchMedia = async (url: string, mediaType: string) => {
  try {
    if (!url) return undefined;
    if (mediaType === "video" || mediaType === "image") {
      const videoResponse = await fetch(url);
      if (!videoResponse.ok) {
        throw new Error("Failed to fetch IPFS video data");
      }
      const videoData = await videoResponse.blob();
      return {
        url: URL.createObjectURL(videoData),
        fileType: mediaType,
      };
    } else {
      let regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      let match = url.match(regExp);
      if (match && match[2].length === 11) {
        // console.log("match[2] : ", match[2]);
        let s = match[2];
        return {
          url: `//www.youtube.com/embed/${s}`,
          fileType: "youtube",
        };
      }
      var tarea_regex = /^(http|https)/;
      if (tarea_regex.test(String(url).toLowerCase()) === true) {
        return {
          url: url,
          fileType: "iframe",
        };
      }

    }
  } catch (error) {
    return undefined;
  }
};

export function useMedia({ url, mediaType }: Props) {
  return useQuery([`nft-${url}`], () => fetchMedia(url, mediaType), {
    staleTime: 60 * 1000 * 60, // 1hr cache, since the nft details does not change.
    refetchOnWindowFocus: undefined,
  });
}
