import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ShowMediaFile } from "../ShowMediaFile";
import { getVideoDurationFromVideoURL } from "../../../utils/fileUtils";
import { MdOutlineTimer } from "react-icons/md";
import { isNumber } from "@turf/turf";
import { isValidUrl } from "../../../utils/valueValidate";
import { getAWSUrlToUploadFile, saveFileOnAWS } from "../../../utils/awsUtils";
import { campaignsCreateByScreenOwner } from "../../../actions/campaignAction";
import { getNumberOfDaysBetweenTwoDates } from "../../../utils/dateAndTimeUtils";
import { MultipleFileUploader } from "../MultipleFileUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
import { SaveButton, CloseButton } from "../Button";

interface MediaFile {
  url: string;
  type: string;
  file: any;
  awsURL: string;
  fileSize: number;
  fileType: string;
  videoDuration: number;
}

export function CreateCampaignsFromFolderModal(props: any) {
  // console.log("CreateCampaignsFromFolderModal called!");
  let { screenOptions } = props;
  const dispatch = useDispatch<any>();
  const [campaignOption, setCampaignOption] = useState("Image/Video");
  const [url, setUrl] = useState<any>("");
  const [campaignName, setCampaignName] = useState<any>("");
  const [campaignDuration, setCampaignDuration] = useState<any>("");
  const [brandName, setBrandName] = useState<any>("");
  const [takeDateRange, setTakeDateRange] = useState<any>(false);
  const [selectedScreens, setSelectedScreens] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const validateSelectedFile = (file: any) => {
    // const MIN_FILE_SIZE = 1024; // 1MB
    let mb = 1000; // mb
    const MAX_FILE_SIZE = mb * 1000 * 1024; // 5MB
    const fileExtension = file.type.split("/")[1];
    const fileSizeKiloBytes = file.size; // convert to kb
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      openErrorToast(
        "File size is greater than maximum limit. File size must be less then 50 MB "
      );
      return false;
    }
    if (
      !(
        fileExtension === "mp4" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      )
    ) {
      return false;
    }
    return true;
  };

  const handleFilesUploader = async (files: FileList) => {
    // const files = event.target.files;
    // console.log("files : ", files);
    if (files) {
      const mediaFilesArray: MediaFile[] = [];
      for (let file of Array.from(files)) {
        if (
          file.type.startsWith("image/") ||
          file.type.startsWith("video/") ||
          file.type.startsWith("audio/")
        ) {
          const url = URL.createObjectURL(file);
          let duration: any = 20;
          if (file.type.split("/")[0] != "image") {
            duration = await getVideoDurationFromVideoURL(url);
          }
          if (validateSelectedFile(file))
            mediaFilesArray.push({
              file: file,
              url,
              type: file.type,
              awsURL: "",
              fileSize: file.size,
              fileType: file.type,
              videoDuration: duration,
            });
        }
      }
      // console.log("mediaFilesArray : ", mediaFilesArray);
      setMediaFiles(mediaFilesArray);
    }
  };

  const getMinDate = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 5); // Add 5 minutes to the current time
    return now.toISOString().slice(0, -8); // Format as required by the input field
    // return new Date(now).toISOString().slice(0, -8);
  };

  const validateDate = (selectedDate: any) => {
    const now = new Date();
    const selected = new Date(selectedDate);

    // Add 5 minutes to the current time for comparison
    now.setMinutes(now.getMinutes() + 5);

    if (selected <= now) {
      openErrorToast(
        "The selected date and time must be at least 5 minutes ahead of the current time."
      );
      return false;
    }
    return true;
  };

  const validateEndDate = (selectedDate: any) => {
    const now = new Date(selectedDate);

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getDate()).padStart(2, "0");

    const localDateTime = `${year}-${month}-${day}T${23}:${59}`;
    if (new Date(localDateTime) <= new Date(startDate)) {
      openErrorToast("End time must be greater then start date.");
      setEndDate(null);
    } else setEndDate(localDateTime);
  };

  const handleDateChange = (e: any) => {
    const selectedDate = e.target.value;
    if (validateDate(selectedDate)) setStartDate(selectedDate);
  };

  const handelEndDateChange = (e: any) => {
    const selectedDate = e.target.value;
    validateEndDate(selectedDate);
  };

  useEffect(() => {
    if (props?.isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [props?.isOpen]);

  if (!props?.isOpen) {
    return null;
  }
  const handleAddCampaignOption = (checked: boolean) => {
    if (checked) {
      setCampaignOption("URL");
    } else {
      setCampaignOption("Image/Video");
    }
  };

  const handelDiscard = () => {
    setMediaFiles([]);
    setStartDate(null);
    setEndDate(null);
    setTakeDateRange(false);
    setSelectedScreens([]);
    setCampaignName("");
    setBrandName("");
    setUrl("");
    setCampaignDuration("");
    setIsLoading(false);
    props.onClose();
  };

  const createCampaignFromMedia = async () => {
    setIsLoading(true);
    let myData = mediaFiles;
    // console.log("myData : ", myData);

    for (let data of myData) {
      const aws = await getAWSUrlToUploadFile(data.fileType);
      const successAWSUploadFile = await saveFileOnAWS(aws?.url, data.file);
      data.awsURL = aws?.awsURL;
    }

    // console.log("myData 1: ", myData);

    dispatch(
      campaignsCreateByScreenOwner({
        screenIds: selectedScreens,
        campaignName,
        brandName,
        isDefaultCampaign: !takeDateRange,
        startDate: !takeDateRange ? null : new Date(startDate).toISOString(),
        endDate: !takeDateRange ? null : new Date(endDate).toISOString(),
        campaignBookedForDays: getNumberOfDaysBetweenTwoDates(
          startDate,
          endDate
        ),
        mediaData: myData.map((data: MediaFile) => {
          return {
            awsURL: data?.awsURL,
            fileType: data.fileType,
            videoDuration:
              data.fileType.split("/")[0] === "image"
                ? Number(campaignDuration)
                : Number(data.videoDuration),
            fileSize: data?.fileSize,
          };
        }),
      })
    );

    setTimeout(() => {
      handelDiscard();
    }, 0);
  };

  const createCampaignFromURL = () => {
    setIsLoading(true);
    dispatch(
      campaignsCreateByScreenOwner({
        screenIds: selectedScreens,
        campaignName,
        brandName,
        isDefaultCampaign: !takeDateRange,
        startDate: !takeDateRange ? null : new Date(startDate).toISOString(),
        endDate: !takeDateRange ? null : new Date(endDate).toISOString(),
        campaignBookedForDays: getNumberOfDaysBetweenTwoDates(
          startDate,
          endDate
        ),
        mediaData: [
          {
            awsURL: url,
            fileType: "url",
            videoDuration: Number(campaignDuration),
            fileSize: 0,
          },
        ],
      })
    );
    setTimeout(() => {
      handelDiscard();
    }, 0);
  };

  const isImagePresent = () => {
    return mediaFiles.some((data: any) => data.fileType.split("/") == "image");
  };

  const validateForm = () => {
    if (campaignOption === "Image/Video" && mediaFiles?.length == 0) {
      openErrorToast("Please Select Media First!");
      return false;
    } else if (campaignOption === "URL" && !isValidUrl(url)) {
      openErrorToast("Please enter valid url");
      setUrl("");
      return false;
    } else if (
      mediaFiles.some((data: any) => data.fileType.split("/")[0] == "image") &&
      !campaignDuration
    ) {
      openErrorToast("Please enter duration for image campaign");
      return false;
    } else if (
      mediaFiles.some((data: any) => data.fileType.split("/")[0] == "image") &&
      Number(campaignDuration) <= 0
    ) {
      openErrorToast("Please enter duration for image campaign > 0");
      return false;
    } else if (!campaignName) {
      openErrorToast("Please enter campaign Name");
      return false;
    } else if (campaignOption === "URL" && !campaignDuration) {
      openErrorToast("Please set campaign duration in sec");
      return false;
    } else if (campaignOption === "URL" && !isNumber(campaignDuration)) {
      openErrorToast("Please enter only number for campaign duration");
      setCampaignDuration("");
      return false;
    } else if (campaignOption === "URL" && Number(campaignDuration) <= 0) {
      openErrorToast("Please enter duration > 0 ");
      setCampaignDuration("");
      return false;
    } else if (!brandName) {
      openErrorToast("Please enter brand Name");
      return false;
    } else if (selectedScreens?.length === 0) {
      openErrorToast("Please Select at least one screen");
      return false;
    } else if (takeDateRange && (!startDate || !endDate)) {
      openErrorToast("Please Select Date Range");
      return false;
    } else {
      return true;
    }
  };

  const handleNext = (e: any) => {
    if (validateForm()) {
      // console.log("form validated");
      if (url?.length > 0 && campaignOption === "URL") {
        createCampaignFromURL();
      } else {
        createCampaignFromMedia();
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 ">
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-9/12 max-w-full relative overflow-auto max-h-auto no-scrollbar"
        style={{ height: "70vh", width: "50vw" }}
      >
        <div>
          <ToastContainer position="top-center" />
          <div className="flex flex-col justify-center">
            <h1 className="text-">Create campaign Page</h1>
            {isLoading && (
              <h1 className="border border-1 bg-yellow-600 text-white text-lg px-8 py-2">
                Wait for some time file is saving....
              </h1>
            )}
          </div>
          <div>
            <div className="flex flex-row gap-2">
              <input
                placeholder="a"
                type="checkbox"
                id="url"
                checked={campaignOption === "URL" ? true : false}
                onChange={(e) => {
                  handleAddCampaignOption(e.target.checked);
                  setMediaFiles([]);
                }}
              />
              <label className="text-sm font-black" htmlFor="url">
                url
              </label>
            </div>
          </div>
          {campaignOption === "URL" ? (
            <div className="flex flex-col">
              <div className="flex flex-col">
                <h1>Media URL:</h1>
                <input
                  placeholder="Enter media url"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                />
              </div>
            </div>
          ) : mediaFiles?.length > 0 ? (
            <div>
              <div className="flex fle-row justify-between">
                <p className="py-1">Uploaded media</p>
                <button
                  className=""
                  type="submit"
                  onClick={() => {
                    setMediaFiles([]);
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {mediaFiles.map((media, index) => (
                  <ShowMediaFile
                    url={media.url || url}
                    mediaType={media?.fileType?.split("/")[0] || "url"}
                    key={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="py-2">
              <h1 className="">Upload content</h1>
              <MultipleFileUploader handleFilesUploader={handleFilesUploader} />
              <h1 className="text-sm font-semibold text-red-700">{`Max file size less then 50 MB`}</h1>
            </div>
          )}
          <div className="flex flex-col gap-2 py-2">
            <h1>Campaign Name </h1>
            <input
              placeholder="Enter campaign name"
              type="text"
              className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </div>
          {(campaignOption === "URL" ||
            mediaFiles.some(
              (data: any) => data.fileType.split("/")[0] == "image"
            )) && (
            <div className="flex flex-col">
              {/* <h1 fontSize="md" color="#131D30" fontWeight="400" m="0">
                Duration{" "}
              </h1> */}
              <div className="py-2 flex items-center gap-2">
                <MdOutlineTimer fontSize="20px" />
                <h1 className="">(in seconds)</h1>
              </div>
              <input
                placeholder="Enter duration in sec."
                type="number"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                value={campaignDuration}
                onChange={(e) => setCampaignDuration(e.target.value)}
              />
            </div>
          )}
          <div className="flex flex-col gap-2 py-2">
            <h1>Brand Name </h1>
            <input
              placeholder="Enter brand name"
              type="text"
              className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value?.toUpperCase())}
            />
          </div>
          <div className="flex flex-col gap-2 py-2">
            <h1>Select Screens ({selectedScreens?.length})</h1>
            <Select
              size="middle"
              mode="multiple"
              value={selectedScreens}
              onChange={(value: any) => setSelectedScreens(value)}
              style={{ width: "100%", height: "" }}
              options={screenOptions}
              showSearch
              placeholder="Search screen by name to select"
              optionFilterProp="children"
              // maxTagCount="responsive"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").includes(input?.toUpperCase())
              }
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .includes((optionB?.label ?? "").toLowerCase())
              }
            />
          </div>
          <div className="flex flex-row gap-2 py-2">
            <input
              placeholder="f"
              type="checkbox"
              id="vehicle1"
              checked={takeDateRange ? takeDateRange : false}
              onChange={(e) => setTakeDateRange(e.target.checked)}
            />

            <label htmlFor="vehicle1"> Do You Want To Add Date Range?</label>
            <br></br>
          </div>
          <div className="flex gap-4 py-2">
            {takeDateRange ? (
              <div>
                <h1>Start Date & Time</h1>
                <input
                  className="border p-2 rounded"
                  placeholder="g"
                  type="datetime-local"
                  value={startDate}
                  onChange={handleDateChange}
                  min={getMinDate()} // Set the min value to 5 minutes after the current time
                />
              </div>
            ) : null}
            {takeDateRange ? (
              <div>
                <h1>End Date & Time</h1>
                <input
                  placeholder="h"
                  className="border p-2 rounded"
                  type="datetime-local"
                  value={endDate}
                  disabled={!startDate}
                  onChange={handelEndDateChange}
                  min={new Date().toISOString().slice(0, -8)}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-row justify-end pt-2 gap-2">
          <SaveButton onClick={handleNext} isLoading={isLoading} />
          <CloseButton onClick={handelDiscard} />
        </div>
      </div>
    </div>
  );
}
