import { Button, Modal } from "antd";
import { FileExporter } from "../FileExpoter";
import { convertDataTimeToLocale } from "../../../utils/dateAndTimeUtils";

export const SingleCampaignLogReportModel = (props: any) => {
  const {
    loading,
    isOpen,
    onClose,
    campaignName,
    campaignLogs,
    screenName,
    reloadCampaignLogData,
  } = props;
  return (
    <Modal
      title={<p>Campaign logs Report for : {campaignName}</p>}
      loading={loading}
      open={isOpen}
      onCancel={onClose}
      width={700}
    >
      <div className="">
        <div className="flex justify-between items-center py-2">
          <FileExporter
            excelData={campaignLogs?.map((c: any, i: any) => {
              return {
                "SN.": i + 1,
                "Time of playback": convertDataTimeToLocale(c.time),
                "Device Status": c.screenStatus,
                "Screen Name": screenName,
              };
            })}
            fileName={`${campaignName}`}
          />
        </div>
        <div className="overflow-scroll h-[30rem]">
          <table className="auto overflow-scroll  h-[20rem] ">
            <thead>
              <tr className="gap-4">
                <th className="border p-2 ">Sl. No</th>
                <th className="border p-2">Playback Time</th>
                <th className="border p-2">ScreenName</th>
                <th className="border p-2">Device Status</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {campaignLogs?.map((c: any, i: any) => (
                <tr className="" key={i}>
                  <td className="border p-2">{i + 1}</td>
                  <td className="border p-2">
                    {convertDataTimeToLocale(c.time)}
                  </td>
                  <td className="border p-2">{screenName}</td>
                  <td
                    className={
                      c.screenStatus === "online"
                        ? "border p-2 bg-green-500 text-white font-bold"
                        : "border p-2 bg-red-500 text-white font-bold"
                    }
                  >
                    {c.screenStatus}
                  </td>
                </tr>
              ))}
              {campaignLogs?.length === 0 && <td>No Data</td>}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};
